import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { api } from 'common';
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography
}from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AppMenu from "../views/AppMenu";
import {colors} from '../components/Theme/WebTheme';
import { useSelector,useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 240;
const barhight=65;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    
  
  },
  menuButtonRight: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuButtonLeft: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width:drawerWidth,
    marginTop:barhight,
    backgroundColor:colors.rigziedrawer
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  account:{
    position:'absolute',
    flexDirection:'row',
    right:15
  },
  search: {
    position: 'relative',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  profiles:{
    flexDirection:'row',
    alignItems:'center'
  }
}));

function ResponsiveDrawer(props) {

  const {
    signOut
  } = api
  const LogOut = () => {
    dispatch(signOut());
  };
  const { container } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  const drawerWidth = 240;
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  

  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    profile_image: '',
    loginType:null,
    usertype:''
  });

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setData({
        firstName: !auth.info.profile.firstName || auth.info.profile.firstName === ' '? '' : auth.info.profile.firstName,
        lastName: !auth.info.profile.lastName || auth.info.profile.lastName === ' '? '' : auth.info.profile.lastName,
        email: !auth.info.profile.email || auth.info.profile.email === ' '? '' : auth.info.profile.email,
        mobile: !auth.info.profile.mobile || auth.info.profile.mobile === ' '? '' : auth.info.profile.mobile,
        profile_image: !auth.info.profile.profile_image || auth.info.profile.profile_image === ' '? '' : auth.info.profile.profile_image,
        loginType:auth.info.profile.loginType?'social':'email',
        usertype:auth.info.profile.usertype,
        uid:auth.info.uid
      });
    }
  }, [auth.info]);

  
    const [anchorElNav, setAnchorElNav] = React.useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = () => {
      console.log('goto_profile');
    };
  

  return (
    <div className={classes.root} style={{direction:isRTL === 'rtl'? 'rtl':'ltr'}}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={isRTL=== 'rtl'? {marginRight:drawerWidth}:{marginLeft:drawerWidth}}>
        <Toolbar style={{backgroundColor: '#27254c'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={isRTL==='rtl'? classes.menuButtonLeft:classes.menuButtonRight}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={require("../assets/img/r.png").default}
            style={{hight:120,width:120,marginLeft:10}}
            alt={'hi'}
          />
          <div className={classes.account}>
    
            <div className={classes.profiles}>
              {data && data.profile_image  ?
                <img alt='Profile' src={data.profile_image} style={{hight:45,width:45,borderRadius:'50%',position:'absolute',right:45,bottom:0, marginRight: 10, marginTop: 10}}/>
                :
                <AccountCircleOutlinedIcon style={{hight:50,width:50,color:'white', marginTop: 10}}/>
              }

              <Typography onClick={handleOpenNavMenu} style={{marginBottom:10}}>
                {data.firstName}
              </Typography>
            </div>

            <div>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem onClick={handleCloseUserMenu} component={Link} to="/profile">
                  <Typography >
                    {t('profile')}
                  </Typography>
                </MenuItem>
                  
                <MenuItem  onClick={LogOut}>
                  <Typography >
                    {t('logout')}
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
          </div> 
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={isRTL === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <AppMenu/>
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor={isRTL === 'rtl' ? 'right' : 'left'}
            open
          >
            <AppMenu/>
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content} style={{overflow:'auto', overflowX:'hidden'}}>
        <div className={classes.toolbar}/>
        {props.children}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default ResponsiveDrawer;
