import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography
} from '@material-ui/core';
import { colors } from '../components/Theme/WebTheme';
//import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  },
  submit: {
    backgroundColor: colors.Rigzie1,
    color: 'white'
  },
}));

export default function FleetEarning() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  //const history = useHistory();
    const columns =  [
        {title: t('no'), render:(rowData)=>rowData.tableData.id+1},
        {title: t('name'), field: 'fleetAdminName'},
        {title: t('year'),field: 'year'},
        {title: t('months'), field: 'monthsName'},
        {title: t('total_rides'), field: 'total_rides'},
        {title: t('earning_amount'), field: 'fleetAdminShare'}
    ];

  const [data, setData] = useState([]);
  const fleetadminearningrdata = useSelector(state => state.fleetadminearningrdata);

  useEffect(()=>{
        if(fleetadminearningrdata.fleetadminearnings){
            setData(fleetadminearningrdata.fleetadminearnings);
        }
  },[fleetadminearningrdata.fleetadminearnings]);

  console.log(data);

  return (
    // earningreportsdata.loading? <CircularLoading/>:
    <div>
    <div className={classes.heading}>
    <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
    {t('earning_reports')}
          </Typography>
          </div>
    <MaterialTable
      title={t('reports')}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr',border:'1px solid #808080'}}
      columns={columns}
      data={data}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        actionsColumnIndex: -1,
        pageSize:20,
        pageSizeOptions:[20,50,100],
        headerStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          border:'2px solid #808080',
        },
        cellStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          borderRight:'1px solid #808080',
          backgroundColor:'#f5f5f5'
        },
      }}
      localization={{
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        pagination: {
          labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
          labelRowsSelect: (t('rows')),
          firstTooltip: (t('first_page_tooltip')),
          previousTooltip: (t('previous_page_tooltip')),
          nextTooltip: (t('next_page_tooltip')),
          lastTooltip: (t('last_page_tooltip'))
        },
      }}
    />
    </div>
  );
}
