import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Select,
  MenuItem,
  Grid,
  Typography,
  TextField,
  Modal
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { colors } from '../components/Theme/WebTheme';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import { makeStyles } from '@material-ui/core/styles';
import { api, FirebaseContext } from 'common';
import Button from "components/CustomButtons/Button.js";
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import BookingMap from '../components/BookingMap';
import Switch from '@material-ui/core/Switch';
import CountrySelect from '../components/CountrySelect';
import Polyline from '@mapbox/polyline';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justify: 'center',
    marginLeft: 200
  },
  paper: {
    width: 480,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  // container: {
  //   zIndex: "12",
  //   color: "#FFFFFF",
  //   alignContent: 'center'
  // },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: 'center'
  },
  items: {
    width: '100%',

  },
  input: {
    fontSize: 18,
    color: "#000"
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373"
  },
  carphoto: {
    height: '18px',
    marginRight: '10px'
  },
  carphotoRtl: {
    height: '16px',
    marginLeft: '10px'
  },
  buttonStyle: {
    backgroundColor: colors.Rigzie1,
    borderRadius: 20,
    width: '100%'
  },
  inputRtl: {
    "& label": {
      right: 25,
      left: "auto"
    },
    "& legend": {
      textAlign: "right",
      marginRight: 18
    }
  },
  rightRty: {
    "& legend": {
      marginRight: 30
    }
  },
  heading: {
    marginBottom: 20
  },
  box: {
    width: '100%',
    height: '100%',
  },
  box1: {
    marginBottom: 20
  },
  box2: {
    position: 'absolute', height: '100%', width: '100%', top: 65, left: 240
  },
  typeBox: {
    marginTop: 10
  }
}));

export default function AddBookings(props) {
  const { config } = useContext(FirebaseContext);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    getEstimate,
    clearEstimate,
    clearBooking,
    MinutesPassed,
    GetDateString,
    GetDistance,
    countries
  } = api;
  const dispatch = useDispatch();
  const classes = useStyles();
  const cartypes = useSelector(state => state.cartypes.cars);
  const estimatedata = useSelector(state => state.estimatedata);
  const bookingdata = useSelector(state => state.bookingdata);
  const userdata = useSelector(state => state.usersdata);
  const settings = useSelector(state => state.settingsdata.settings);
  const [carType, setCarType] = useState(t('select_car'));
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropAddress, setDropAddress] = useState(null);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [selectedCarDetails, setSelectedCarDetails] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [estimateRequested, setEstimateRequested] = useState(false);
  const [bookingType, setBookingType] = useState('Book Now');
  const rootRef = useRef(null);
  const [drivers, setDrivers] = useState([]);
  const [mylocation, setMylocation] = useState(null);
  const [pickUpLocations, setPickUpLocations] = useState(null);
  const [dropLocations, setDropLocations] = useState(null);
  const [offloadPaymentType, setOffloadPaymentType] = useState('normal');
  const [comissionType, setComissionType] = useState('percent');

  const auth = useSelector(state => state.auth);
  useEffect(() => {
    if (mylocation == null) {
      setMylocation({
        lat: 22.66368,
        lng: 88.42728
      });
    }
  }, [mylocation]);

  useEffect(() => {
    if (pickupAddress) {
      setPickUpLocations({
        lat: pickupAddress.coords.lat,
        lng: pickupAddress.coords.lng,
        description: pickupAddress.description
      });
    }
    if (dropAddress) {
      setDropLocations({
        lat: dropAddress.coords.lat,
        lng: dropAddress.coords.lng,
        description: dropAddress.description
      });
    }
  }, [pickupAddress, dropAddress]);

  const [instructionData, setInstructionData] = useState({
    deliveryPerson: "",
    deliveryPersonPhone: "",
    pickUpInstructions: "",
    deliveryInstructions: "",
    parcelTypeIndex: 0,
    optionIndex: 0,
    parcelTypeSelected: null,
    optionSelected: null
  });

  const [selectedDate, setSelectedDate] = useState(GetDateString());

  const handleCarSelect = (event) => {
    setCarType(event.target.value);
    let carDetails = null;
    for (let i = 0; i < cartypes.length; i++) {
      if (cartypes[i].name === event.target.value) {
        carDetails = cartypes[i];
        let instObj = { ...instructionData };
        if (Array.isArray(cartypes[i].parcelTypes)) {
          instObj.parcelTypeSelected = cartypes[i].parcelTypes[0];
          instObj.parcelTypeIndex = 0;
        }
        if (Array.isArray(cartypes[i].options)) {
          instObj.optionSelected = cartypes[i].options[0];
          instObj.optionIndex = 0;
        }
        setInstructionData(instObj);
      }
    }
    setSelectedCarDetails(carDetails);
  };

  const handleBookTypeSelect = (event) => {
    setBookingType(event.target.value);
    if (bookingType === 'Book Later') {
      setSelectedDate(GetDateString());
    }
  };

  const offloadPaymentTypeSelect = (event) => {
    setOffloadPaymentType(event.target.value);
  };

  const comissionTypeSelect = (event) => {
    setComissionType(event.target.value);
  };

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (estimatedata.estimate && estimateRequested) {
      setEstimateRequested(false);
      setEstimateModalStatus(true);
    }
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.usertype === 'rider' && user.corporateAdminId === auth.info.uid && auth.info.profile.usertype === 'corporateAdmin') {
          arr.push({
            'firstName': user.firstName,
            'lastName': user.lastName,
            'mobile': user.mobile,
            'email': user.email,
            'uid': user.id,
            'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
            'pushToken': user.pushToken
          });
        }
      }
      //setUsers(arr);
      let arrDrivers = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if ((user.usertype) && (user.usertype === 'driver') && (user.approved === true) && (user.queue === false) && (user.driverActiveStatus === true) && (user.location)) {
          arrDrivers.push({
            'uid': user.id,
            'location': user.location
          });
        }
      }
      setDrivers(arrDrivers);
    }
  }, [estimatedata.estimate, userdata.users, estimateRequested, settings.AllowCriticalEditsAdmin,auth ]);


  const initData = {
    name: '',
    driver_instruction: '',
    amount: '',
    mobile: '',
    country: null,
    admin_commission: '',
    paid: false
  };

  const [state, setState] = useState(initData);

  useEffect(() => {
    if (settings) {
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].label === settings.country) {
          setState({
            country: "+" + countries[i].phone,
            selectedcountry: countries[i],
          });
        }
      }
    }
  }, [settings, countries]);

  const handleTextChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  }

  const onCountryChange = (object, value) => {
    if (value && value.phone) {
      setState({ ...state, country: "+" + value.phone, selectedcountry: value });
    }
  };

  const handleSwitchChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };

  const confirmBooking = async(e) => {
    e.preventDefault();
    //let found = false;
    if (pickupAddress && dropAddress){
      if (bookingType === 'Book Now' ) {
        for (let i = 0; i < drivers.length; i++) {
          const driver = drivers[i];
          let distance = GetDistance(pickupAddress.coords.lat, pickupAddress.coords.lng, driver.location.lat, driver.location.lng);
          if (settings.convert_to_mile) {
            distance = distance / 1.609344;
          }
          if (distance < ((settings && settings.driverRadius) ? settings.driverRadius : 10)) {
            //found = true;
            break;
          }
        }
      }
    }else{
      setCommonAlert({ open: true, msg: t('no_details_error')});
    }

    if ((bookingType === 'Book Now') || bookingType === 'Book Later') {
      if (auth && auth.info && auth.info.profile.usertype === 'corporateAdmin') {
        if (pickupAddress && dropAddress && selectedCarDetails){
          if (state.country) {
            if ( state.name !== '') {
              if(state.mobile ) {
                if(state.driver_instruction) {
                  let formattedNum = state.mobile.replace(/ /g, '');
                  let s = formattedNum.toString();
                  let first_digit = parseInt(s.charAt(0));
                  if(first_digit === 0){
                    formattedNum =  parseInt(s.substring(1));
                  }
                  formattedNum = state.country + formattedNum;
                  const directionService = new window.google.maps.DirectionsService();
                  directionService.route(
                    {
                      origin: new window.google.maps.LatLng(pickupAddress.coords.lat, pickupAddress.coords.lng),
                      destination: new window.google.maps.LatLng(dropAddress.coords.lat, dropAddress.coords.lng),
                      travelMode: window.google.maps.TravelMode.DRIVING
                    },
                    async (result, status) => {
                      if (status === window.google.maps.DirectionsStatus.OK) {
                        const route = {
                          distance_in_km: (result.routes[0].legs[0].distance.value / 1000),
                          time_in_secs: result.routes[0].legs[0].duration.value,
                          polylinePoints: result.routes[0].overview_polyline
                        };
                      let estimateRequest = {
                        pickup: pickupAddress,
                        drop: dropAddress,
                        carDetails: selectedCarDetails,
                        instructionData: instructionData,
                        routeDetails: route
                      };
                      await dispatch(getEstimate(estimateRequest));

                      if (route) {
                        let points = Polyline.decode(route.polylinePoints);

                        let waypoints = points.map((point) => {
                          return {
                            latitude: point[0],
                            longitude: point[1]
                          }
                        });

                      // if (!pickupAddress || !dropAddress || state.name === '' || state.driver_instruction === '' || selectedCarDetails === null || state.admin_commission === '') {
                      //   setCommonAlert({ open: true, msg: t('no_details_error') });
                      // } else {
                      //   if(estimatedata && estimatedata.estimate){
                      //   let fullAmount = parseFloat(estimatedata.estimate.estimateFare).toFixed(2);
                      //   let corporateShare = comissionType === 'percent' ? parseFloat(parseFloat(estimatedata.estimate.estimateFare) * (parseFloat(state.admin_commission)/100)).toFixed(2) : parseFloat(state.admin_commission).toFixed(2);
                      //   let withAdminShare = parseFloat(fullAmount - corporateShare);
                      //   let driverShare = parseFloat(withAdminShare - parseFloat(estimatedata.estimate.convenience_fees)).toFixed(2);

                      if (!pickupAddress || !dropAddress || state.name === '' || state.driver_instruction === '' || (offloadPaymentType === 'normal' && state.amount === '') || selectedCarDetails === null || state.admin_commission === '') {
                        setCommonAlert({ open: true, msg: t('no_details_error') });
                      } else {
                      
                        let withOutLevy = offloadPaymentType === 'normal' ?  parseFloat(parseFloat(parseFloat(state.amount) - 1.1)) : 0 ;
                        let corporateAdminCommission = comissionType === 'percent' ? parseFloat(parseFloat(withOutLevy) * (parseFloat(state.admin_commission)/100)).toFixed(2) : parseFloat(state.admin_commission).toFixed(2);
                        
                        let withOutcorporateAdminCommission = parseFloat(withOutLevy - corporateAdminCommission).toFixed(2);

                        let adminCommissionWithOutlevy = selectedCarDetails.convenience_fee_type === 'percentage' ? parseFloat(parseFloat(withOutcorporateAdminCommission) * (parseFloat(selectedCarDetails.convenience_fees)/100)).toFixed(2) : parseFloat(selectedCarDetails.convenience_fees).toFixed(2);
                        console.log(adminCommissionWithOutlevy);

                        let adminCommission = offloadPaymentType === 'normal' ?  parseFloat(parseFloat(adminCommissionWithOutlevy) + 1.1).toFixed(2) : parseFloat(adminCommissionWithOutlevy).toFixed(2);
                        console.log(adminCommission);
                        
                        let driverShare = parseFloat(parseFloat(state.amount) - parseFloat(adminCommission) - parseFloat(corporateAdminCommission));
                        
                        var data = {
                          carType: selectedCarDetails.name,
                          carImage: selectedCarDetails.image,
                          capacity: selectedCarDetails.capacity,
                          customer_name: state.name,
                          customer_contact: formattedNum,
                          corporateAdminId: auth && auth.info ? auth.info.uid : null,
                          corporateAdminName: auth.info.profile.firstName + " " + auth.info.profile.lastName,
                          customer: ' ', //
                          customer_token: ' ',  //
                          drop: { lat: dropAddress.coords.lat, lng: dropAddress.coords.lng, add: dropAddress.description },
                          pickup: { lat: pickupAddress.coords.lat, lng: pickupAddress.coords.lng, add: pickupAddress.description },
                          estimateDistance: route.distance_in_km,
                          distance: route.distance_in_km,
                          estimateTime: route.time_in_secs,
                          status: "NEW",
                          tripdate: bookingType === 'Book Later' ? new Date(selectedDate).getTime() : new Date().getTime(),
                          bookLater: bookingType === 'Book Later' ? true : false,
                          bookingDate: new Date().getTime(),
                          booking_type_admin: false,
                          coords: waypoints,
                          trip_cost: offloadPaymentType === 'normal' ? parseFloat(state.amount)  : 0,
                          estimate: offloadPaymentType === 'normal' ? parseFloat(state.amount) : 0,
                          driver_instruction: state.driver_instruction,

                          corporateAdmin_share : offloadPaymentType === 'normal' ? corporateAdminCommission : parseFloat(state.admin_commission),
                          convenience_fees: offloadPaymentType === 'normal' ? adminCommission  : 0,
                          driver_share: offloadPaymentType ==='normal' ? driverShare.toFixed(2) : 0,

                          paid: state.paid,
                          offload_payment_type: offloadPaymentType === 'normal' ? null : "meter",
                          comissionType : comissionType,
                        }
                        //console.log(data);
                        //const url = `https://${config.projectId}.web.app/joboffload`;
                        const response = await fetch(`https://${config.projectId}.web.app/joboffload`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json'
                          },
                          body: JSON.stringify({
                            bookingdata: data
                          })
                        })
                        const json = await response.json();
                        if (json.success) {
                          setState(initData);
                          setCommonAlert({ open: true, msg: 'Offload Success' });
                        } else {
                          setCommonAlert({ open: true, msg: t('Offload Failed') });
                        }
                      }
                    }
                  }
                  // }
                  })
                }else {
                  setCommonAlert({ open: true, msg: t('driver_instruction')});
                }
              }else {
                setCommonAlert({ open: true, msg: t('mobile_no_blank_error')});
              }
            }else {
              setCommonAlert({ open: true, msg: t('name_blank_error')});
            }
          }else{
            setCommonAlert({ open: true, msg: t('country_blank_error')});
          }
        }else{
          setCommonAlert({ open: true, msg: t('no_details_error')});
        }
      } else {
        if(auth && auth.info && auth.info.profile.usertype === 'fleetadmin'){
          if (pickupAddress && dropAddress && selectedCarDetails){
            if (state.country) {
              if ( state.name !== '') {
                if(state.mobile ) {
                  if(state.driver_instruction) {
                    let formattedNum = state.mobile.replace(/ /g, '');
                    let s = formattedNum.toString();
                    let first_digit = parseInt(s.charAt(0));
                    if(first_digit === 0){
                      formattedNum =  parseInt(s.substring(1));
                    }
                    formattedNum = state.country + formattedNum;
                    const directionService = new window.google.maps.DirectionsService();
                    directionService.route(
                      {
                        origin: new window.google.maps.LatLng(pickupAddress.coords.lat, pickupAddress.coords.lng),
                        destination: new window.google.maps.LatLng(dropAddress.coords.lat, dropAddress.coords.lng),
                        travelMode: window.google.maps.TravelMode.DRIVING
                      },
                      async (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                          const route = {
                            distance_in_km: (result.routes[0].legs[0].distance.value / 1000),
                            time_in_secs: result.routes[0].legs[0].duration.value,
                            polylinePoints: result.routes[0].overview_polyline
                          };
                        let estimateRequest = {
                          pickup: pickupAddress,
                          drop: dropAddress,
                          carDetails: selectedCarDetails,
                          instructionData: instructionData,
                          routeDetails: route
                        };
                        await dispatch(getEstimate(estimateRequest));

                        if (route) {
                          let points = Polyline.decode(route.polylinePoints);

                          let waypoints = points.map((point) => {
                            return {
                              latitude: point[0],
                              longitude: point[1]
                            }
                          });


                          if (!pickupAddress || !dropAddress || state.name === '' || state.driver_instruction === '' || (offloadPaymentType === 'normal' && state.amount === '') || selectedCarDetails === null || state.admin_commission === '') {
                            setCommonAlert({ open: true, msg: t('no_details_error') });
                          } else {
                          
                            let withOutLevy = offloadPaymentType === 'normal' ?  parseFloat(parseFloat(parseFloat(state.amount) - 1.1)) : 0 ;
                            let fleetadminCommission = comissionType === 'percent' ? parseFloat(parseFloat(withOutLevy) * (parseFloat(state.admin_commission)/100)).toFixed(2) : parseFloat(state.admin_commission).toFixed(2);
                            
                            let withOutFleetadminCommission = parseFloat(withOutLevy - fleetadminCommission).toFixed(2);

                            let adminCommissionWithOutlevy = selectedCarDetails.convenience_fee_type === 'percentage' ? parseFloat(parseFloat(withOutFleetadminCommission) * (parseFloat(selectedCarDetails.convenience_fees)/100)).toFixed(2) : parseFloat(selectedCarDetails.convenience_fees).toFixed(2);
                            console.log(adminCommissionWithOutlevy);

                            let adminCommission = offloadPaymentType === 'normal' ?  parseFloat(parseFloat(adminCommissionWithOutlevy) + 1.1).toFixed(2) : parseFloat(adminCommissionWithOutlevy).toFixed(2);
                            console.log(adminCommission);
                            
                            let driverShare = parseFloat(parseFloat(state.amount) - parseFloat(adminCommission) - parseFloat(fleetadminCommission));

                            var data = {
                              carType: selectedCarDetails.name,
                              carImage: selectedCarDetails.image,
                              capacity: selectedCarDetails.capacity,
                              customer_name: state.name,
                              customer_contact: formattedNum,
                              fleetadmin: auth && auth.info ? auth.info.uid : null,
                              fleetAdminName: auth.info.profile.firstName + " " + auth.info.profile.lastName,
                              customer: ' ', //
                              customer_token: ' ',  //
                              drop: { lat: dropAddress.coords.lat, lng: dropAddress.coords.lng, add: dropAddress.description },
                              pickup: { lat: pickupAddress.coords.lat, lng: pickupAddress.coords.lng, add: pickupAddress.description },
                              estimateDistance: route.distance_in_km,
                              distance: route.distance_in_km,
                              estimateTime: route.time_in_secs,
                              status: "NEW",
                              tripdate: bookingType === 'Book Later' ? new Date(selectedDate).getTime() : new Date().getTime(),
                              bookLater: bookingType === 'Book Later' ? true : false,
                              bookingDate: new Date().getTime(),
                              booking_type_admin: false,
                              coords: waypoints,
                              trip_cost: offloadPaymentType === 'normal' ? parseFloat(state.amount) : 0,
                              estimate: offloadPaymentType === 'normal' ? parseFloat(state.amount) : 0,
                              driver_instruction: state.driver_instruction,

                              fleetadmin_share : offloadPaymentType === 'normal' ? fleetadminCommission : parseFloat(state.admin_commission),
                              convenience_fees: offloadPaymentType === 'normal' ? adminCommission  : 0,
                              driver_share: offloadPaymentType ==='normal' ? driverShare.toFixed(2) : 0,

                              paid: state.paid,
                              offload_payment_type: offloadPaymentType === 'normal' ? null : "meter",
                              comissionType : comissionType,
                            }
                            //console.log(data);
                              //const url = `https://${config.projectId}.web.app/joboffload`;
                              const response = await fetch(`https://${config.projectId}.web.app/joboffload`, {
                                method: 'POST',
                                headers: {
                                  'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                  bookingdata: data
                                })
                              })
                              const json = await response.json();
                              if (json.success) {
                                setState(initData);
                                setCommonAlert({ open: true, msg: 'Offload Success' });
                              } else {
                                setCommonAlert({ open: true, msg: t('Offload Failed') });
                              }
                            }
                          }
                        }
                      // }
                    })
                  }else {
                    setCommonAlert({ open: true, msg: t('driver_instruction')});
                  }
                }else {
                  setCommonAlert({ open: true, msg: t('mobile_no_blank_error')});
                }
              }else {
                setCommonAlert({ open: true, msg: t('name_blank_error')});
              }
            }else{
              setCommonAlert({ open: true, msg: t('country_blank_error')});
            }
          }else{
            setCommonAlert({ open: true, msg: t('no_details_error')});
          }
        }else{
          if (state.country) {
            if (state.mobile) {
              let formattedNum = state.mobile.replace(/ /g, '');
              let s = formattedNum.toString();
              let first_digit = parseInt(s.charAt(0));
              if(first_digit === 0){
                formattedNum =  parseInt(s.substring(1));
              }
              formattedNum = state.country + formattedNum;
              if (formattedNum.length > 8) {
                if (!pickupAddress || !dropAddress || state.name === '' || state.driver_instruction === '' || (offloadPaymentType === 'normal' && state.amount === '') || selectedCarDetails === null || state.admin_commission === '') {
                  setCommonAlert({ open: true, msg: t('no_details_error') });
                } else {
                  setTimeout(() => {
                    if (bookingType === 'Book Later' && selectedDate) {
                      const diffMins = MinutesPassed(selectedDate);
                      if (diffMins < 15) {
                        setCommonAlert({ open: true, msg: t('past_booking_error') });
                      } else {
                        const directionService = new window.google.maps.DirectionsService();
                        directionService.route(
                          {
                            origin: new window.google.maps.LatLng(pickupAddress.coords.lat, pickupAddress.coords.lng),
                            destination: new window.google.maps.LatLng(dropAddress.coords.lat, dropAddress.coords.lng),
                            travelMode: window.google.maps.TravelMode.DRIVING
                          },
                          async (result, status) => {
                            if (status === window.google.maps.DirectionsStatus.OK) {
                              const route = {
                                distance_in_km: (result.routes[0].legs[0].distance.value / 1000),
                                time_in_secs: result.routes[0].legs[0].duration.value,
                                polylinePoints: result.routes[0].overview_polyline
                              };
  
                              if (route) {
                                let points = Polyline.decode(route.polylinePoints);
  
                                let waypoints = points.map((point) => {
                                  return {
                                    latitude: point[0],
                                    longitude: point[1]
                                  }
                                });

                                let withOutLevy = offloadPaymentType === 'normal' ? parseFloat(parseFloat(parseFloat(state.amount) - 1.1)) : 0;
                                let adminCommissionWithOutlevy = comissionType === 'percent' ? parseFloat(parseFloat(withOutLevy) * (parseFloat(state.admin_commission)/100)).toFixed(2) : parseFloat(state.admin_commission).toFixed(2);
                                console.log(adminCommissionWithOutlevy);
  
                                let adminCommission = offloadPaymentType === 'normal' ? parseFloat(parseFloat(adminCommissionWithOutlevy) + 1.1 ).toFixed(2) : parseFloat(adminCommissionWithOutlevy)
                                console.log(adminCommission);
                                
                                let driverShare = parseFloat(parseFloat(state.amount) - adminCommission);

                                var data = {
                                  carType: selectedCarDetails.name,
                                  carImage: selectedCarDetails.image,
                                  capacity: selectedCarDetails.capacity,
                                  customer_name: state.name,
                                  customer_contact: formattedNum,
                                  customer: ' ', //
                                  customer_token: ' ',  //
                                  drop: { lat: dropAddress.coords.lat, lng: dropAddress.coords.lng, add: dropAddress.description },
                                  pickup: { lat: pickupAddress.coords.lat, lng: pickupAddress.coords.lng, add: pickupAddress.description },
                                  estimateDistance: route.distance_in_km,
                                  distance: route.distance_in_km,
                                  estimateTime: route.time_in_secs,
                                  status: "NEW",
                                  tripdate: new Date(selectedDate).getTime(),
                                  bookLater: true,
                                  bookingDate: new Date().getTime(),
                                  booking_type_admin: true,
                                  coords: waypoints,
                                  trip_cost: offloadPaymentType === 'normal' ? parseFloat(state.amount) : 0,
                                  estimate: offloadPaymentType === 'normal' ? parseFloat(state.amount) : 0,
                                  convenience_fees: offloadPaymentType === 'normal' ? adminCommission : parseFloat(state.admin_commission),
                                  driver_share: offloadPaymentType ==='normal' ? driverShare.toFixed(2) : 0,
                                  driver_instruction: state.driver_instruction,
                                  paid: state.paid,
                                  offload_payment_type: offloadPaymentType === 'normal' ? null : "meter",
                                  comissionType : comissionType
                                }
                                //console.log(data);
                                //const url = `https://${config.projectId}.web.app/joboffload`;
                                const response = await fetch(`https://${config.projectId}.web.app/joboffload`, {
                                  method: 'POST',
                                  headers: {
                                    'Content-Type': 'application/json'
                                  },
                                  body: JSON.stringify({
                                    bookingdata: data
                                  })
                                })
                                const json = await response.json();
                                if (json.success) {
                                  setState(initData);
                                  setCommonAlert({ open: true, msg: 'Offload Success' });
                                } else {
                                  setCommonAlert({ open: true, msg: t('Offload Failed') });
                                }
                              }
                            }
                          }
                        )
                      }
                    } else {
                      const directionService = new window.google.maps.DirectionsService();
                      directionService.route(
                        {
                          origin: new window.google.maps.LatLng(pickupAddress.coords.lat, pickupAddress.coords.lng),
                          destination: new window.google.maps.LatLng(dropAddress.coords.lat, dropAddress.coords.lng),
                          travelMode: window.google.maps.TravelMode.DRIVING
                        },
                        async (result, status) => {
                          if (status === window.google.maps.DirectionsStatus.OK) {
                            const route = {
                              distance_in_km: (result.routes[0].legs[0].distance.value / 1000),
                              time_in_secs: result.routes[0].legs[0].duration.value,
                              polylinePoints: result.routes[0].overview_polyline
                            };
  
                            if (route) {
                              let points = Polyline.decode(route.polylinePoints);
  
                              let waypoints = points.map((point) => {
                                return {
                                  latitude: point[0],
                                  longitude: point[1]
                                }
                              });

                              let withOutLevy = offloadPaymentType === 'normal' ? parseFloat(parseFloat(parseFloat(state.amount) - 1.1)) : 0;
                              let adminCommissionWithOutlevy = comissionType === 'percent' ? parseFloat(parseFloat(withOutLevy) * (parseFloat(state.admin_commission)/100)).toFixed(2) : parseFloat(state.admin_commission).toFixed(2);
                              console.log(adminCommissionWithOutlevy);

                              let adminCommission = offloadPaymentType === 'normal' ?  parseFloat(parseFloat(adminCommissionWithOutlevy) + 1.1 ).toFixed(2) : parseFloat(adminCommissionWithOutlevy)
                              console.log(adminCommission);
                              
                              let driverShare = parseFloat(parseFloat(state.amount) - adminCommission);

                              var data = {
                                carType: selectedCarDetails.name,
                                carImage: selectedCarDetails.image,
                                capacity: selectedCarDetails.capacity,
                                customer_name: state.name,
                                customer_contact: formattedNum,
                                customer: ' ', //
                                customer_token: ' ',  //
                                drop: { lat: dropAddress.coords.lat, lng: dropAddress.coords.lng, add: dropAddress.description },
                                pickup: { lat: pickupAddress.coords.lat, lng: pickupAddress.coords.lng, add: pickupAddress.description },
                                estimateDistance: route.distance_in_km,
                                distance: route.distance_in_km,
                                estimateTime: route.time_in_secs,
                                status: "NEW",
                                tripdate: new Date().getTime(),
                                bookLater: false,
                                bookingDate: new Date().getTime(),
                                booking_type_admin: true,
                                coords: waypoints,
                                trip_cost: offloadPaymentType === 'normal' ? parseFloat(state.amount) : 0,
                                estimate: offloadPaymentType === 'normal' ? parseFloat(state.amount) : 0,
                                convenience_fees: offloadPaymentType === 'normal' ? adminCommission : parseFloat(state.admin_commission),
                                driver_share: offloadPaymentType ==='normal' ? driverShare.toFixed(2) : 0,
                                driver_instruction: state.driver_instruction,
                                paid: state.paid ? state.paid : false,
                                offload_payment_type: offloadPaymentType === 'normal' ? null : "meter",
                                comissionType : comissionType
                              }
                              // console.log(data);
                              // const url = `https://${config.projectId}.web.app/joboffload`;
                              const response = await fetch(`https://${config.projectId}.web.app/joboffload`, {
                                method: 'POST',
                                headers: {
                                  'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                  bookingdata: data
                                })
                              })
                              const json = await response.json();
                              if (json.success) {
                                setState(initData);
                                setCommonAlert({ open: true, msg: 'Offload Success' });
                              } else {
                                setCommonAlert({ open: true, msg: t('Offload Failed') });
                              }
                            }
                          }
                        }
                      )
                    }
                  }, 1000);
                }
              } else {
                setCommonAlert({ open: true, msg: t('mobile_no_blank_error') });
              }
            } else {
              setCommonAlert({ open: true, msg: t('mobile_no_blank_error') });
            }
          } else {
            setCommonAlert({ open: true, msg: t('country_blank_error') });
          }
        }
      }
    } else {
      setCommonAlert({ open: true, msg: t('no_driver_found_alert_messege') });
    }
  };

  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearEstimate());
    setEstimateRequested(false);
  };

  const handleBookingAlertClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    dispatch(clearEstimate());
    clearForm();
  };

  const clearForm = () => {
    setPickupAddress(null);
    setDropAddress(null);
    setPickUpLocations(null);
    setDropLocations(null);
    setSelectedCarDetails(null);
    setCarType(t('select_car'));
    setBookingType('Book Now');
    setEstimateRequested(false);
  }

  const handleBookingErrorClose = (e) => {
    e.preventDefault();
    dispatch(clearBooking());
    setEstimateRequested(false);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  return (
    <div className={classes.box2}>
      <div className={classes.box}>
        <BookingMap 
          mapcenter={mylocation}
          pickUpLocations={pickUpLocations}
          dropLocations={dropLocations}
          loadingElement={<div style={{ height: '98%' }} />}
          containerElement={<div style={{ height: '98%' }} />}
          mapElement={<div style={{ height: '98%' }} />}
        />
        <Box boxShadow={6}
          p={2} style={{ width: 315, height: '92%', backgroundColor: 'white', position: 'absolute', left: 10, top: 10, overflowY: 'scroll', overflowX: 'hidden' }}>
          <Box boxShadow={6}
            p={2} style={{ width: '100%' }}>
            <Typography variant="h6" style={{ fontWeight: 'bolder', color: colors.Rigzie1, textAlign: 'center' }}>
              {t('booking_details')}
            </Typography>
          </Box>
          <CardContent>
            <Typography style={{ margin: 10, fontSize: 12 }}>{t('step1')}</Typography>
            <div className={classes.box1}>
              <GoogleMapsAutoComplete
                variant={"outlined"}
                placeholder={t('pickup_location')}
                value={pickupAddress}
                className={classes.items}
                onChange={
                  (value) => {
                    setPickupAddress(value);
                  }
                }
              />
            </div>
            <div className={classes.box1}>
              <GoogleMapsAutoComplete placeholder={t('drop_location')}
                variant={"outlined"}
                value={dropAddress}
                className={classes.items}
                onChange={
                  (value) => {
                    setDropAddress(value);
                  }
                }
              />
            </div>
            <div className={classes.box1}>
              {cartypes ?
                <Select
                  id="car-type-native"
                  value={carType}
                  onChange={handleCarSelect}
                  variant="outlined"
                  fullWidth
                  style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
                  className={carType === t('select_car') ? classes.inputdimmed : classes.input}
                >
                  <MenuItem value={t('select_car')} key={t('select_car')} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                    {t('select_car')}
                  </MenuItem>
                  {
                    cartypes.map((car) =>
                      <MenuItem key={car.name} value={car.name} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                        <img src={car.image} className={isRTL === 'rtl' ? classes.carphotoRtl : classes.carphoto} alt="car types" />{car.name}
                      </MenuItem>
                    )
                  }
                </Select>
                : null}
            </div>
            <div className={classes.box1}>
              <Select
                id="booking-type-native"
                value={bookingType}
                onChange={handleBookTypeSelect}
                className={classes.input}
                style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
                variant="outlined"
                fullWidth
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem key={"Book Now"} value={"Book Now"} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                  {t('book_now')}
                </MenuItem>
                <MenuItem key={"Book Later"} value={"Book Later"} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                  {t('book_later')}
                </MenuItem>
              </Select>
            </div>
            {bookingType === 'Book Later' ?

              <TextField
                id="datetime-local"
                label={t('booking_date_time')}
                type="datetime-local"
                variant="outlined"
                fullWidth
                className={isRTL === 'rtl' ? classes.inputRtl : classes.commonInputStyle}
                InputProps={{
                  className: classes.input,
                  style: { textAlignLast: isRTL === 'rtl' ? 'end' : 'start' }
                }}
                value={selectedDate}
                onChange={onDateChange}
              />

              : null}

            <Modal
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              open={estimateModalStatus}
              onClose={handleEstimateModalClose}
              className={classes.modal}
              container={() => rootRef.current}
            >
              <Grid container spacing={1} className={classes.paper}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                  {settings.swipe_symbol === false ?
                    <Typography color={'primary'} style={{ fontSize: 30 }}>
                      {t('total')} - {settings ? settings.symbol : null} {estimatedata.estimate ? estimatedata.estimate.estimateFare : null}
                    </Typography>
                    :
                    <Typography color={'primary'} style={{ fontSize: 30 }}>
                      {t('total')} - {estimatedata.estimate ? estimatedata.estimate.estimateFare : null} {settings ? settings.symbol : null}
                    </Typography>
                  }
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                  <Button onClick={handleEstimateModalClose} variant="contained" color="primary">
                    {t('cancel')}
                  </Button>
                  <Button onClick={confirmBooking} variant="contained" color="primary" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }}>
                    {t('book_now')}
                  </Button>
                </Grid>
              </Grid>
            </Modal>
            <Typography style={{ marginLeft: 10, marginTop: 10, fontSize: 12 }}>{t('step2')}</Typography>
              <div>
                <TextField
                  className={classes.rootRt}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label={t('name')}
                  name="name"
                  autoComplete="name"
                  onChange={handleTextChange}
                  defaultValue={state.name}
                />
                {settings && settings.AllowCountrySelection && state.selectedcountry ?   // COUNTRY
                  <CountrySelect
                    countries={countries}
                    label={t('select_country')}
                    value={state.selectedcountry}
                    onChange={onCountryChange}
                    style={{ paddingTop: 20 }}
                    //disabled={data.verificationId ? true : false}
                    variant={'outlined'}
                  />
                  : null}
                <TextField
                  className={classes.rootRt}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="mobile"
                  label={t('mobile')}
                  name="mobile"
                  autoComplete="mobile"
                  onChange={handleTextChange}
                  defaultValue={state.mobile}
                />
              </div>
            {/* } */}
            <Typography style={{ marginLeft: 10, fontSize: 12, marginTop: 5 }}>{t('step3')}</Typography>
            <TextField
              className={classes.rootRt}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="driver_instruction"
              label={t('driverinstruction')}
              name="driver_instruction"
              autoComplete="driver_instruction"
              onChange={handleTextChange}
              defaultValue={state.driver_instruction}
            />

            <Typography style={{ marginLeft: 10, fontSize: 12, marginTop: 5 }}>{t('trip_cost')}</Typography>
          
            <div className={classes.typeBox}>
              <Select
                id="booking-type-native"
                value={offloadPaymentType}
                onChange={offloadPaymentTypeSelect}
                className={classes.input}
                style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
                variant="outlined"
                fullWidth
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem key={"normal"} value={"normal"} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                  {t('fixed')}
                </MenuItem>
                <MenuItem key={"meter"} value={"meter"} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                  {t('meter')}
                </MenuItem>
              </Select>
            </div>

            { offloadPaymentType === 'normal'? 
              <TextField
                type="number"
                className={classes.rootRt}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="amount"
                label={t('amount')}
                name="amount"
                autoComplete="amount"
                onChange={handleTextChange}
                defaultValue={state.amount}
              />
            : null }

            <Typography style={{ marginLeft: 10, fontSize: 12, marginTop: offloadPaymentType === 'normal' ? 0 : 15 }}>{t('your_share_type')}</Typography>

            <div className={classes.typeBox}>
              <Select
                id="comission-type"
                value={comissionType}
                onChange={comissionTypeSelect}
                className={classes.input}
                style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
                variant="outlined"
                fullWidth
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem key={"percent"} value={"percent"} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                  {t('percent')}
                </MenuItem>
                <MenuItem key={"roundoff"} value={"roundoff"} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                  {t('roundoff')}
                </MenuItem>
              </Select>
            </div>

            <TextField
              type="number"
              className={classes.rootRt}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="admin_comission"
              label={t('your_share')}
              name="admin_commission"
              autoComplete="admin_commission"
              onChange={handleTextChange}
              defaultValue={state.admin_commission}
            />

          </CardContent>

          { offloadPaymentType === 'normal'? 
          <FormControlLabel
            style={{ flexDirection: isRTL === 'rtl' ? 'row-reverse' : 'row', marginLeft: 15, marginBottom: 10, marginTop: -5 }}
            control={
              <Switch
                checked={state.paid? state.paid : false}
                onChange={handleSwitchChange}
                name="paid"
                color="primary"
              />
            }
            label={t('PAID')}
          />
          : null }

          <Button
            size="large"
            onClick={confirmBooking}
            variant="contained"
            color="secondaryButton"
            className={classes.buttonStyle}
          >
            <i className="fas fa-car" style={isRTL === 'rtl' ? { marginLeft: 5 } : { marginRight: 5 }} />
            {t('book')}
          </Button>
          <AlertDialog open={bookingdata.booking ? true : false} onClose={handleBookingAlertClose}>{bookingdata.booking ? t('booking_success') + bookingdata.booking.booking_id : null}</AlertDialog>
          <AlertDialog open={bookingdata.error.flag} onClose={handleBookingErrorClose}>{bookingdata.error.msg}</AlertDialog>
          <AlertDialog open={estimatedata.error.flag} onClose={handleEstimateErrorClose}>{estimatedata.error.msg}</AlertDialog>
          <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
        </Box>
      </div>
    </div>
  );
}