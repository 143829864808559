import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import {
  Typography,
  Modal,
  Grid
} from '@material-ui/core';
import { colors } from '../components/Theme/WebTheme';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  deletepaper: {
    width: 590,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #009CA3',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10
  },
  heading: {
    marginBottom: 20
  },
  submit2: {
    backgroundColor: '#019b9d',
    marginLeft: 30
  },
  submit3:{
    backgroundColor:'#019b9d',
    width:'100%',
    borderRadius:7,
    marginTop:5,
    padding:4
  },
  submit4:{
    backgroundColor:'red',
    width:'100%',
    borderRadius:7,
    marginTop:5,
    padding:4
  }
}));

export default function Promos() {

  const history = useHistory();

  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const {
    editPromo
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);

  const columns = [
    { title: t('no'), render: (rowData) => rowData.tableData.id + 1 },
    { title: t('promo_name'), field: 'promo_name' },
    { title: t('description'), field: 'promo_description' },
    {
      title: t('type'),
      field: 'promo_discount_type',
      lookup: { flat: t('flat'), percentage: t('percentage') },
    },
    { title: t('promo_discount_value'), field: 'promo_discount_value', type: 'numeric' },
    { title: t('max_limit'), field: 'max_promo_discount_value', type: 'numeric' },
    { title: t('min_limit'), field: 'min_order', type: 'numeric' },
    { title: t('expiry_date'), field: 'promo_validity' },
    { title: t('promo_usage'), field: 'promo_usage_limit', type: 'numeric' },
    { title: t('promo_used_by'), field: 'user_avail', editable: 'never' }
  ];

  const [data, setData] = useState([]);
  const promodata = useSelector(state => state.promodata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (promodata.promos) {
      setData(promodata.promos);
    } else {
      setData([]);
    }
  }, [promodata.promos]);

  const addNew = () => {
    history.push({
      pathname: '/addpromo'
    });
  }

  const [deleteModel, setDeleteModel] = useState(false);
  const [promoDelete, setPromoDelete] = useState([]);

  const deleteSinglePromo=(rowData)=>{
    setDeleteModel(true);
    setPromoDelete(rowData)
  }

  const closeDeleteModel=()=>{
    setDeleteModel(false);
  }

  const deletePromoData = () => {
    dispatch(editPromo(promoDelete, "Delete"));
    setDeleteModel(false);
  }

  return (
    promodata.loading ? <CircularLoading /> :
      <div>
        <div className={classes.heading}>
          <Typography component="h1" variant="h5" style={{ marginTop: '8px', fontWeight: 'bolder', color: colors.Rigzie1, textDecoration: 'underline' }}>
            {t('promo')}  <Button
            type="submit"
            width='4%'
            variant="contained"
            className={classes.submit2}
            onClick={() => addNew()}
          >
            {"add"}
          </Button>
          </Typography>
        </div>
        <MaterialTable
          title={t('promo_offer')}
          style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', border: '1px solid #808080' }}
          columns={columns}
          data={data}
          options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            rowStyle: {
              alignItems: 'center'
            },
            actionsColumnIndex: -1,
            cellStyle: {
              textAlign: isRTL === 'rtl' ? 'right' : 'center',
              borderRight: '1px solid #808080',
              backgroundColor: '#f5f5f5'
            },
            headerStyle: {
              textAlign: isRTL === 'rtl' ? 'right' : 'center',
              border: '2px solid #808080',
            }
          }}
          localization={{
            body: {
              addTooltip: (t('add')),
              deleteTooltip: (t('delete')),
              editTooltip: (t('edit')),
              emptyDataSourceMessage: (
                (t('blank_message'))
              ),
              editRow: {
                deleteText: (t('delete_message')),
                cancelTooltip: (t('cancel')),
                saveTooltip: (t('save'))
              },
            },
            toolbar: {
              searchPlaceholder: (t('search')),
              exportTitle: (t('export')),
            },
            header: {
              actions: (t('actions'))
            },
            pagination: {
              labelDisplayedRows: ('{from}-{to} ' + (t('of')) + ' {count}'),
              labelRowsSelect: (t('rows')),
              firstTooltip: (t('first_page_tooltip')),
              previousTooltip: (t('previous_page_tooltip')),
              nextTooltip: (t('next_page_tooltip')),
              lastTooltip: (t('last_page_tooltip'))
            },
          }}
          editable={{
            // onRowAdd: newData =>
            //   new Promise(resolve => {
            //     setTimeout(() => {
            //       newData['createdAt'] = new Date().toISOString();
            //       dispatch(editPromo(newData, "Add"));
            //       resolve();
            //     }, 600);
            //   }),
            // // onRowUpdate: (newData, oldData) =>
            // //   settings.AllowCriticalEditsAdmin?
            // //   new Promise(resolve => {
            // //     setTimeout(() => {
            // //       resolve();
            // //       dispatch(editPromo(newData,"Update"));
            // //     }, 600);
            // //   })
            // //   :
            // //   new Promise(resolve => {
            // //     setTimeout(() => {
            // //       resolve();
            // //       alert(t('demo_mode'));
            // //     }, 600);
            // //   }),
            // onRowDelete: oldData =>
            //   settings.AllowCriticalEditsAdmin?
            //   new Promise(resolve => {
            //     setTimeout(() => {
            //       resolve();
            //       dispatch(editPromo(oldData,"Delete"));
            //     }, 600);
            //   })
            //   :
            //   new Promise(resolve => {
            //     setTimeout(() => {
            //       resolve();
            //       alert(t('demo_mode'));
            //     }, 600);
            //   })
          }}
          actions={[
            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
               <div  className={classes.submit3}>
              <EditIcon icon={EditIcon} style={{marginLeft:2,marginTop:5,color:'white'}} />
            </div>
              </div>,
              onClick: () => {
                history.push({
                  pathname: '/editpromo',
                  state: {
                    detail: rowData
                  }
                });
              }
            }),
            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <div className={classes.submit4}>
                <DeleteIcon icon={DeleteIcon} style={{marginLeft:2,marginTop:5,color:'white'}}/>
              </div>
              </div>,
              onClick: () => {
                settings.AllowCriticalEditsAdmin ?
                    deleteSinglePromo(rowData)
                  :
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      alert(t('demo_mode'));
                    }, 600);
                  })
              }
            })
          ]}
        />

        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={deleteModel}
          className={classes.modal}
        >
          <div className={classes.deletepaper}>
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <Typography component="h1" variant="h5" className={classes.title} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                  {t('delete_booking_message')}{t('promo')} ?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', marginLeft: isRTL === 'rtl' ? '65%' : 0 }}>
                <Button onClick={closeDeleteModel} variant="contained" color="primary">
                  {t('cancel')}
                </Button>
                <Button onClick={deletePromoData} variant="contained" color="primary" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }}>
                  {t('yes')}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </div>
  );
}          