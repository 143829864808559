import React,{ useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
//import { useHistory } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import { Typography } from '@material-ui/core';
import { colors } from '../components/Theme/WebTheme';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  deletepaper: {
    width: 590,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #009CA3',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10
  },
  heading:{
   marginBottom:20,
  height:'10%'
  },
  submit1: {
    backgroundColor:'#019b9d',
    marginLeft:30 
  },
  submit: {
    backgroundColor:'#de7d1e',
  },
  submit5: {
    backgroundColor:'#0c5c6b',
  },
  submit3:{
    backgroundColor:'#019b9d',
    width:'100%',
    borderRadius:7,
    marginTop:2,
    padding:4
  },
  submit4:{
    backgroundColor:'red',
    width:'100%',
    borderRadius:7,
    marginTop:2,
    padding:4
  }
}));

export default function SubscriptionReport() {

  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    fetchUsersOnce
  } = api;
  const [data, setData] = useState([]);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const dispatch = useDispatch();
  const loaded = useRef(false);

  useEffect(()=>{
      dispatch(fetchUsersOnce());
  },[dispatch,fetchUsersOnce]);

  useEffect(()=>{
    if(staticusers){
        setData(staticusers.filter(user => user.usertype ==='driver' && user.subscription ));
    }else{
      setData([]);
    }
    loaded.current = true;
  },[staticusers,auth.info.profile.usertype,auth.info.uid]);

  const classes = useStyles();


  //const history = useHistory();

  const columns = [
      {title: t('no'), render:(rowData)=>rowData.tableData.id+1},
      { title: t('driver_name'), field: 'firstName',render: rowData => <span>{t(rowData.firstName)+ " "+t(rowData.lastName)}</span> },
      { title: t('amount'), field: 'amount', render: rowData =>  rowData.subscription.amount},
      { title: t('plan_name'), field: 'name', render: rowData =>  rowData.subscription.name},
      { title: t('expiry_date'), field: 'toDate', render: rowData => rowData.subscription.toDate ? moment(rowData.subscription.toDate ).format('lll') : 'No Subscribe', }      
  ];


  return (
    <div>
      <div className={classes.heading}>
        <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
          {t('subscriber')} 
        </Typography>
      </div>
        <MaterialTable
        title={t('subscriber_list')}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr',border:'2px solid #808080'}}
        data={data}
        options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
            actionsColumnIndex: -1,
            pageSize:20,
            pageSizeOptions:[20,50,100],
            headerStyle:{
            textAlign:isRTL=== 'rtl' ?'right':'center',
            border:'2px solid #808080',
            },
            cellStyle:{
            textAlign:isRTL=== 'rtl' ?'right':'center',
            borderRight:'1px solid #808080',
            backgroundColor:'#f5f5f5',
            
            },
        }}
        localization={{body:{
            addTooltip: (t('add')),
            deleteTooltip: (t('delete')),
            editTooltip: (t('edit')),
            emptyDataSourceMessage: (
            (t('blank_message'))
        ),
        editRow: { 
            deleteText: (t('delete_message')),
            cancelTooltip: (t('cancel')),
            saveTooltip: (t('save')) 
            }, 
            },
            toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
            },
            header: {
            actions: (t('actions')) 
        },
        pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            labelRowsSelect: (t('rows')),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
        },
        }}
        />
    </div>
  );
}
