import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import AlertDialog from '../components/AlertDialog';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import Box from '@material-ui/core/Box';
import {
  Typography,
  Button,
  Grid,
  Modal,
  TextField
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import CardContent from '@material-ui/core/CardContent';
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { colors } from '../components/Theme/WebTheme';
import IconButton from '@material-ui/core/IconButton';
import UsersCombo from '../components/UsersCombo';
import { api } from 'common';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CancelIcon from '@material-ui/icons/Cancel';
import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
const useStyles = makeStyles(theme => ({
  paper: {

    flexDirection: 'column',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#019b9d'
  },
  submit2: {
    marginLeft: 75,
    top: -40,
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#019b9d'
  },
  reset: {
    marginLeft: 15,
    marginTop: 12
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%'
  },
  content: {
    flex: '1 0 auto',
  },
  cd: {
    display: 'flex'
  },
  img: {
    width: '50%',
  },
  heading: {
    marginBottom: 10
  },
  viw: {
    display: 'flex',
    width: '70%',
    marginTop: 50
  },
  viw1: {
    width: '40%',
  },
  viw2: {
    width: '60%',
  },
  txt: {
    padding: 5
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    marginLeft:400
  },
  paper1: {
    width: 680,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal1: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    marginLeft: 300

  },
  paper2: {
    width: 450,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 18,
  },
  submit3: {
    backgroundColor: '#019b9d',
    marginLeft: 230,
    color: 'white',
    marginTop:30 
  },
  submit4: {
    marginLeft: 20,
    marginTop:30
  },
  items1:{
    width:'100%'
  },
  txt1:{
    marginBottom:15
  }
}));

const BookingHistoryDetails = () => {
  let history = useHistory();
  const location = useLocation();
  const getId = location.state.detail;
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const [role, setRole] = useState(null);
  const userdata = useSelector(state => state.usersdata);
  const settings = useSelector(state => state.settingsdata.settings);
  const [users, setUsers] = useState(null);
  const [userCombo, setUserCombo] = useState(null);
  const rootRef = useRef(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const {
    cancelBooking,
    updateBooking,
    RequestPushMsg,
  } = api;
  const [fieldModalStatus,setFieldModalStatus] = useState(false);
  const [fieldValue, setFieldValue] = useState(null);
  const [fieldType, setFieldType] = useState(null);

  const goBack = () => {
    history.goBack();
  }
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };


  const handleClose = () => {
    setOpen(false);
  }
  const assignDriver = () => {
    let booking = getId;
    if (booking['requestedDrivers']) {
      booking['requestedDrivers'][userCombo.uid] = true;
    } else {
      booking['requestedDrivers'] = {};
      booking['requestedDrivers'][userCombo.uid] = true;
    }
    dispatch(updateBooking(booking));
    RequestPushMsg(
      userCombo.pushToken,
      {
        title: t('notification_title'),
        msg: t('new_booking_notification'),
        screen: 'DriverTrips'
      }
    );
    setUserCombo(null);
    handleClose();
    alert("Driver assigned successfully and notified.");
  }
  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [auth.info]);

  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.usertype === 'driver') {
          arr.push({
            'firstName': user.firstName,
            'lastName': user.lastName,
            'mobile': user.mobile,
            'email': user.email,
            'uid': user.id,
            'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
            'pushToken': user.pushToken,
            'carType': user.carType
          });
        }
      }
      setUsers(arr);
    }
  }, [userdata.users, settings.AllowCriticalEditsAdmin]);

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(cancelBooking({
        reason: value,
        booking: getId,
        cancelledBy: "admin"
      }));

    }
    setOpenConfirm(false);
  }

  const openFieldModal = (fType) => {
    setFieldType(fType);
    setFieldModalStatus(true);
  }

  const handleFieldModalClose = () => {
    setFieldValue(null);
    setFieldType(null);
    setFieldModalStatus(false);
  }
  
  const confirmChange = (e) => {
    e.preventDefault();
    let obj;
    console.log(fieldValue);

    switch(fieldType) {
      case "pickup": {
        obj = {...getId,
          pickupAddress: fieldValue.description,
          pickup: {
            lat:fieldValue.coords.lat,
            lng:fieldValue.coords.lng,
            add: fieldValue.description
          }
        }
         break;
      }
      case "drop": {
        obj = {...getId,
          dropAddress: fieldValue.description,
          drop: {
            lat:fieldValue.coords.lat,
            lng:fieldValue.coords.lng,
            add: fieldValue.description
          }
        }
         break;
      }
      case "mobile": {
        obj = {...getId,
          customer_contact:fieldValue
        }
        break;
      }
      default: {
         break;
      }
   }
    dispatch(updateBooking(obj));
    setFieldModalStatus(true);
    history.goBack();
  }


  return (
    <div className={classes.paper}>
      <div>
        <Box boxShadow={3}
          p={1} style={{ backgroundColor: colors.Rigzie1, borderBottomRightRadius: 20, position: 'absolute', top: 64, left: 240 }}>
          <KeyboardBackspaceIcon onClick={goBack} style={{ color: 'white', marginRight: 10, marginTop: 5, }} />

          <Typography component="h8" variant="h6" style={{ color: 'white', bottom: 5, position: 'relative' }}>

            {getId.customer_name + "  " + getId.id}
          </Typography>

        </Box>
      </div>
      <div className={classes.viw}>
        <div className={classes.viw1}>
          <Box boxShadow={6} m={2}
            style={{ borderRadius: 20, border: '2px solid white', width: '90%' }}>
            <Box boxShadow={3}
              p={1} style={{ backgroundColor: colors.Rigzie1, width: '70%', marginBottom: 1, borderBottomRightRadius: 60, borderTopLeftRadius: 20 }}>
              <Typography component="h1" variant="h6">
                {t('rider_details')}
              </Typography>
            </Box>
            <CardContent>
              <Typography className={classes.txt}>
                {t('customer_name') + ":  " + getId.customer_name}
              </Typography>
              <Button style={{color:'blue'}} variant="text" onClick={()=>openFieldModal('mobile')} className={classes.txt}>
                {t('privacy_policy_info_list4') + ":  " + getId.customer_contact}
              </Button>
              { getId.customer_email ?
              <Typography className={classes.txt}>
                {t('email') + ":  " + getId.customer_email}
              </Typography>
              : null }
            </CardContent>
          </Box>

          <Box boxShadow={6} m={2}
            style={{ borderRadius: 20, border: '2px solid white', width: '90%' }}>
            <Box boxShadow={3}
              p={1} style={{ backgroundColor: colors.Rigzie1, width: '70%', marginBottom: 1, borderBottomRightRadius: 60, borderTopLeftRadius: 20 }}>
              <Typography component="h1" variant="h6">
                {t('payment_details')}
              </Typography>
            </Box>
            <CardContent>
              {getId.payment_mode ?
                <Typography className={classes.txt}>
                  {t('pay_mode') + ":  " + getId.payment_mode}
                </Typography>
                :
                <Typography className={classes.txt}>
                  {t('pay_mode') + ":  "}
                </Typography>
              }
              <Typography className={classes.txt}>
                {t('case_payment_amount') + ":  " + getId.cashPaymentAmount}
              </Typography>
              <Typography className={classes.txt}>
                {t('card_amount') + ":  " + getId.cardPaymentAmount}
              </Typography>
              {getId.usedWalletMoney ?
                <Typography className={classes.txt}>
                  {t('wallet_amount') + ":  " + getId.usedWalletMoney}
                </Typography>
                :
                <Typography className={classes.txt}>
                  {t('wallet_amount') + ":  " + 0}
                </Typography>
              }
            </CardContent>
          </Box>
          <Box boxShadow={6} m={2}
            style={{ borderRadius: 20, border: '2px solid white', width: '90%' }}>
            <Box boxShadow={3}
              p={1} style={{ backgroundColor: colors.Rigzie1, width: '70%', marginBottom: 1, borderBottomRightRadius: 60, borderTopLeftRadius: 20 }}>
              <Typography component="h1" variant="h6">
                {t('commission_details')}
              </Typography>
            </Box>
            <CardContent>
              {getId.offload_payment_type && getId.offload_payment_type === "meter"  ?
                <Typography className={classes.txt}>
                   {t('booking_type')} {":  " }{t('meter') }
                </Typography>
              : <Typography className={classes.txt}>
                  {t('booking_type')} {":  " }{t('normal') }
                </Typography>
              }
              <Typography className={classes.txt}>
                {t('total_fare') + ":  " + getId.trip_cost}
              </Typography>
              {getId.offload_payment_type && getId.offload_payment_type === "meter" ?
                <Typography className={classes.txt}>
                  {t('levy_paid_by_driver') + ":  " + 1.1}
                </Typography>
              : <Typography className={classes.txt}>
                  {t('levy_paid_by_admin') + ":  " + 1.1}
                </Typography>
              }
              <Typography className={classes.txt}>
                {t('trip_cost_driver_share')} { ":  " } {getId.offload_payment_type && getId.offload_payment_type === "meter" ? (getId.driver_share - 1.1).toFixed(settings.decimal) : getId.driver_share }
              </Typography>
              <Typography className={classes.txt}>
                {t('convenience_fee')}  {":  "} {getId.offload_payment_type && getId.offload_payment_type === "meter" ? getId.convenience_fees : (getId.convenience_fees - 1.1).toFixed(settings.decimal)}
              </Typography>
              {getId.offLoaderFee ?
              <Typography className={classes.txt}>
                {t('off_loader-fee') + ":  " + getId.offLoaderFee}
              </Typography>
              : null}
              {getId.corporateAdmin_share ?
              <Typography className={classes.txt}>
                {t('corporateAdmin_share') + ":  " + getId.corporateAdmin_share}
              </Typography>
              : null}
               {getId.fleetadmin_share ?
              <Typography className={classes.txt}>
                {t('fleetadmin_share') + ":  " + getId.fleetadmin_share}
              </Typography>
              : null}
              {getId.discount_amount ?
                <Typography className={classes.txt}>
                  {t('rigzie_discount') + ":  " + getId.discount_amount}
                </Typography>
                :
                <Typography className={classes.txt}>
                  {t('rigzie_discount') + ":  "}
                </Typography>
              }
            </CardContent>
          </Box>
        </div>

        <div className={classes.viw2}>
          <Box boxShadow={6} m={2}
            style={{ borderRadius: 20, border: '2px solid white', width: '80%', height: '95%', }}>
            <Box boxShadow={3}
              p={1} style={{ backgroundColor: colors.Rigzie1, width: '70%', marginBottom: 1, borderBottomRightRadius: 60, borderTopLeftRadius: 20, }}>
              <Typography component="h1" variant="h6">
                {t('ride_information')}
              </Typography>
            </Box>
            <CardContent>
              <Typography className={classes.txt}>
                {t('booking_id') + ":  " + getId.id}
              </Typography>
              <Typography className={classes.txt}>
                {t('booking_date_time') + ":  " + moment(getId.tripdate).format('lll')}
              </Typography>
              <Button style={{textAlign:'left', color:'blue'}} variant="text" onClick={()=>openFieldModal('pickup')}>{t('pickup_address') + ":  " + getId.pickupAddress}</Button>
              <Button style={{textAlign:'left', color:'blue'}} variant="text" onClick={()=>openFieldModal('drop')}>{t('drop_address') + ":  " + getId.dropAddress}</Button>
              <Typography className={classes.txt}>
                {t('car_type') + ":  " + getId.carType}
              </Typography>
              {/* {getId.vehicleMake && getId.vehicleModel ?
                <Typography className={classes.txt}>
                  {t('vehicle_name') + ":  " + getId.vehicleMake + " " + getId.vehicleModel}
                </Typography>
                :
                <Typography className={classes.txt}>
                  {t('vehicle_name') + ":  "}
                </Typography>
              } */}
              {getId.vehicle_number ?
                <Typography className={classes.txt}>
                  {t('vehicle_no') + ":  " + getId.vehicle_number}
                </Typography>
                :
                <Typography className={classes.txt}>
                  {t('vehicle_no') + ":  "}
                </Typography>
              }
              {getId.driver_name ?
                <Typography className={classes.txt}>
                  {t('assign_driver') + ":  " + getId.driver_name}
                </Typography>
                :
                <Typography className={classes.txt}>
                  {t('assign_driver') + ":  "}
                </Typography>
              }
               {getId.driver_instruction?
                <Typography className={classes.txt}>
                  {t('driver_instruction') + ":  " + getId.driver_instruction}
                </Typography>
                :
                null
              }
              {getId.otp ?
                <Typography className={classes.txt}>
                  {t('otp') + "  " + getId.otp}
                </Typography>
                :
                null
                // <Typography className={classes.txt}>
                //   {t('otp') + "  " + t('na')}
                // </Typography>
              }
              <Typography className={classes.txt}>
                {t('trip_cost') + ":  " + getId.trip_cost}
              </Typography>
              {getId.trip_start_time ?
                <Typography className={classes.txt}>
                  {t('trip_start_time') + ":  " + getId.trip_start_time}
                </Typography>
                :
                <Typography className={classes.txt}>
                  {t('trip_start_time') + ":  " + 0}
                </Typography>
              }
              {getId.trip_end_time ?
                <Typography className={classes.txt}>
                  {t('trip_end_time') + ":  " + getId.trip_end_time}
                </Typography>
                :
                <Typography className={classes.txt}>
                  {t('trip_end_time') + ":  " + 0}
                </Typography>
              }
              {getId.total_trip_time ?
                <Typography className={classes.txt}>
                  {t('total_time') + ":  " + parseFloat(parseFloat(getId.total_trip_time)/60).toFixed(settings.decimal)} {t('mins')}
                </Typography>
                :
                <Typography className={classes.txt}>
                  {t('estimate_time') + ":  " + parseFloat(parseFloat(getId.estimateTime)/60).toFixed(settings.decimal)} {t('mins')}
                </Typography>
              }
              <Typography className={classes.txt}>
                {t('distance') + ":  " + parseFloat(getId.distance).toFixed(settings.decimal)}
              </Typography>
              <Typography className={classes.txt}>
                {t('booking_status') + ":  " + getId.status}
              </Typography>
              {getId.reason ?
                <Typography className={classes.txt}>
                  {t('cancellation_reason') + ":  " + getId.reason}
                </Typography>
                :
                null
                // <Typography className={classes.txt}>
                //   {t('cancellation_reason') + ":  "}
                // </Typography>
              }
              {getId.cancellationFee ?
                <Typography className={classes.txt}>
                  {t('cancellationFee') + ":  " + getId.cancellationFee}
                </Typography>
                :
                <Typography className={classes.txt}>
                  {t('cancellationFee') + ":  " + 0}
                </Typography>
              }
              {role === 'admin' ?
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
                disabled={((getId.status === 'NEW' || getId.status === 'ACCEPTED') && role === 'admin') ? false : true}
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
              >
                <PersonAddIcon />
                <Typography>{t('assign')}</Typography>
              </IconButton >
              : null }
              {role === 'admin' ?
              <IconButton
                onClick={() => {
                  if ((getId.status === 'NEW' || getId.status === 'ACCEPTED') && (role === 'admin')) {
                    setOpenConfirm(true);
                  }
                }}
                disabled={getId.status === 'NEW' || getId.status === 'ACCEPTED' || getId.status === 'PAYMENT_PENDING' ? false : true}
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
              >
                <CancelIcon />
                <Typography>{t('cancel')}</Typography>
              </IconButton >
              : null }
            </CardContent>
          </Box>
        </div>
      </div>
      <ConfirmationDialogRaw
        open={openConfirm}
        onClose={onConfirmClose}
        value={''}
      />
      {users && getId ?
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          onClose={handleClose}
          open={open}
          className={classes.modal}
          container={() => rootRef.current}
        >
          <div className={classes.paper1}>
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <Typography component="h1" variant="h5" className={classes.title} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                  {t('assign')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <UsersCombo
                  className={classes.items}
                  placeholder={t('select_user')}
                  users={users}
                  value={userCombo}
                  onChange={(event, newValue) => {
                    setUserCombo(newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', marginLeft: isRTL === 'rtl' ? '65%' : 0 }}>
                <Button onClick={handleClose} variant="contained" color="primary">
                  {t('cancel')}
                </Button>
                <Button onClick={assignDriver} variant="contained" color="primary" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }}>
                  {t('assign')}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
        : null}
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={fieldModalStatus}
        onClose={handleFieldModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper1}>
          <Grid container spacing={1} className={classes.paper}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
              {fieldType === 'pickup' || fieldType === 'drop'?
              <GoogleMapsAutoComplete
                variant={"outlined"}
                placeholder={fieldType === 'pickup'? t('pickup_location') : t('drop_location')}
                value={fieldValue}
                className={classes.items}
                onChange={
                  (value) => {
                    setFieldValue(value);
                  }
                }
              />
              :
              <TextField
                className={classes.rootRt}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={t('mobile')}
                onChange={
                  (event)=>{
                    setFieldValue(event.target.value);
                  }
                }
                defaultValue={fieldValue}
              />
              }
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
              <Button onClick={handleFieldModalClose} variant="contained" color="primary">
                {t('cancel')}
              </Button>
              <Button onClick={confirmChange} variant="contained" color="primary" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }}>
                {t('confirm')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>


  );
};

export default BookingHistoryDetails;