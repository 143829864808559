import {
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILED,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  FETCH_ALL_USERS_STATIC,
  FETCH_ALL_USERS_STATIC_SUCCESS,
  FETCH_ALL_USERS_STATIC_FAILED
} from "../store/types";
import { firebase } from '../config/configureFirebase';
import { onValue, set, push, update, remove, query, orderByChild, equalTo } from "firebase/database";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const fetchUsers = () => (dispatch) => {

  const {
    usersRef
  } = firebase;

  dispatch({
    type: FETCH_ALL_USERS,
    payload: null
  });
  onValue(usersRef, async snapshot => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const arr = Object.keys(data)
      .filter(i => data[i].usertype!='admin')
      .map(i => {
        data[i].id = i;
        return data[i];
      });
      dispatch({
        type: FETCH_ALL_USERS_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_ALL_USERS_FAILED,
        payload: "No users available."
      });
    }
  });
};


export const fetchUsersOnce = () => (dispatch) => {

  const {
    usersRef
  } = firebase;

  dispatch({
    type: FETCH_ALL_USERS_STATIC,
    payload: null
  });
  onValue(usersRef, async snapshot => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const arr = Object.keys(data)
      .map(i => {
        data[i].id = i;
        return data[i];
      });
      dispatch({
        type: FETCH_ALL_USERS_STATIC_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_ALL_USERS_STATIC_FAILED,
        payload: "No users available."
      });
    }
  },{onlyOnce: true});
};

export const fetchDrivers = () => (dispatch) =>  {

  const {
    usersRef
  } = firebase;

  dispatch({
    type: FETCH_ALL_USERS,
    payload: null
  });

  onValue(query(usersRef, orderByChild('queue'), equalTo(false)), snapshot => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const arr = Object.keys(data)
      .filter(i => data[i].usertype=='driver' && data[i].approved == true && data[i].driverActiveStatus == true && data[i].location)
      .map(i => {
        data[i].id = i;
        return {
          location: data[i].location,
          carType: data[i].carType
        };
      });
      dispatch({
        type: FETCH_ALL_USERS_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_ALL_USERS_FAILED,
        payload: "No users available."
      });
    }
  },{onlyOnce:true});
};

export const addUser = (userdata) => async (dispatch) => {
  const {
    usersRef
  } = firebase;

  if (userdata.licenseImage) {
    let timestamp = new Date().toISOString();
    await uploadBytesResumable(driverDocsRef(timestamp,"licenseImage"), userdata.licenseImage);
    updateData.licenseImage = await getDownloadURL(driverDocsRef(timestamp,"licenseImage"));
  }

  push(usersRef, userdata).then(() => {
    dispatch({
      type: EDIT_USER_SUCCESS,
      payload: null
    });
  }).catch((error) => {
    dispatch({
      type: EDIT_USER_FAILED,
      payload: error
    });
  });
}

export const editUser = (id, user) => (dispatch) => {

  const {
    singleUserRef
  } = firebase;

  dispatch({
    type: EDIT_USER,
    payload: user
  });
  let editedUser = user;
  delete editedUser.id;
  set(singleUserRef(id), editedUser).then(() => {
    dispatch({
      type: EDIT_USER_SUCCESS,
      payload: null
    });
  }).catch((error) => {
    dispatch({
      type: EDIT_USER_FAILED,
      payload: error
    });
  });
}

export const updateLicenseImage = (user, imageBlob, imageType, check) => async (dispatch)  => {

  const {
    singleUserRef,
    driverDocsRef
  } = firebase;

  if(check == true){
    uploadBytesResumable(driverDocsRef(user.uid, imageType), imageBlob).then(() => {
      imageBlob.close()
      return  getDownloadURL(driverDocsRef(user.uid, imageType))
    }).then((url) => {
      let profile = user.profile;
      let doc;
      if(profile.document){
        doc = profile.document;
      }else{
        doc = {};
      }
      doc[imageType] = url;
      profile.document = doc;
      update(singleUserRef(user.id),profile);
      dispatch({
        type: EDIT_USER,
        payload: user
      });
    })
  } else {
    await uploadBytesResumable(driverDocsRef(user.id, imageType), imageBlob);
    let image = await getDownloadURL(driverDocsRef(user.id, imageType));
      let profile = user;
      let doc;
      if(profile.document){
        doc = profile.document;
      }else{
        doc = {};
      }
      doc[imageType] = image;
      profile.document = doc;
      update(singleUserRef(user.id),profile);
      dispatch({
        type: EDIT_USER,
        payload: user
      });
  }
};

export const deleteUser = (uid) => (dispatch) => {

  const {
    singleUserRef,
  } = firebase;

  dispatch({
    type: DELETE_USER,
    payload: uid
  });

  remove(singleUserRef(uid)).then(() => {
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: null
    });
  }).catch((error) => {
    dispatch({
      type: DELETE_USER_FAILED,
      payload: error
    });
  });
}
