import {
    FETCH_SUBSCRIPTION_LIST,
    FETCH_SUBSCRIPTION_LIST_SUCCESS,
    FETCH_SUBSCRIPTION_LIST_FAILED
  } from "../store/types";

  import { firebase } from '../config/configureFirebase';
import { onValue } from "firebase/database";
  
  export const fetchsubscriptionList = () => (dispatch) => {
    const {
      subscriptionListRef
    } = firebase;
  
    dispatch({
      type: FETCH_SUBSCRIPTION_LIST,
      payload: null
    });
    onValue(subscriptionListRef, snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_SUBSCRIPTION_LIST_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_SUBSCRIPTION_LIST_FAILED,
          payload: "No subscriptions available."
        });
      }
    });
  }