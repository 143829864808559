import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function TermsAndCondition(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>

        <div className={classes.container}>
          <br />
          <h2 style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', position: "relative", marginTop: "30px", minHeight: "32px", color: "#383838", textDecoration: "none" }}>{t('terms_and_conditions')}</h2>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY BEFORE DOWNLOADING OUR APPLICATION AND/OR USING OUR SERVICE.</p>

          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>Your contracting party is RIGZIE Pty Ltd (ABN: 0287199957) and it provides you with the Services, Application and Website (RIGZIE Pty Ltd, we, our)</p>

          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>RIGZIE Pty Ltd is a Commercial Passenger Vehicle Booking Service enabling you to book for, a vehicle using an application supplied by us and downloaded and installed by you on your mobile device (smartphone) (Application) or via our Website. RIGZIE Pty Ltd connects you to drivers so that they may pick you up and take you where you need to go. The Application allows you to track the driver’s location and manage other functions. All services provided to you by means of you use of the Application and/or Website are collectively referred to as the Service.</p>

          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>RIGZIE Pty Ltd facilitates the formation and administration of travel arrangements between you and drivers. By using our Services, you are acknowledging that RIGZIE Pty Ltd is not providing you with transportation services and agreeing that RIGZIE Pty Ltd has no responsibility or liability for any transportation services provided to you by drivers.</p>

          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>Services may also be accessed by ordinary telephone connections.</p>

          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>Further information about us can be found at https://rigzie-cf338.web.app/</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>General Terms And Conditions</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>By registering on our Website or Application, you consent to the validity of these Terms and Conditions. Neither the registration nor the use of the complete spectrum of Services shall be possible without explicit consent to these Terms and Conditions.</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>We reserve the right to amend these Terms and Conditions at any time. Following such amendment, the continued use of our Services by you is subject to your consent to such amended terms and conditions. Your continued use of the Services following posting of any changes to this agreement constitutes acceptance of those changes. It is your responsibility to check this agreement periodically for changes. RIGZIE Pty Ltd may also, in the future offer news services and/or features through the Services. Such new features and/or services shall be subject to these Terms and Conditions.</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>Your Agreement With Us</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>By using our Services, you are entering into an agreement with us to be bound by these Terms and Conditions. In order to be able to first use our Services, you first need to sign up with us. When signing up, you are obliged to provide us with your personal information. Upon successful completion of your signing up with us, we will provide you with a personal account, accessible for you with a password of your choice. You will also need to provide us with your credit card details.</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>Payment</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>The use of our Services is free of charge. We reserve the right to introduce a fee for the use of our Services. If we decide to introduce such a fee, we shall inform you accordingly and allow you to either continue or terminate using our Services.</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>In some cases, we may authorise a temporary charge on your credit card (or whatever payment method you use) for any amount up to $1.00 to verify that your card is open and in good standing. This charge will appear as ‘pending’ and will be automatically removed after verification.</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>We shall charge you for the services provided to you by the drivers on behalf of the drivers. You agree that you will pay for all services, and that we may charge your credit card as provided by you for the services (including any taxes and late fees, as applicable) that may be accrued by or in connection with your account. You are responsible for the timely payment of all fees and for providing us with a valid credit card for payment of all fees at all times.</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>Charges to you will include, but not be limited to, the metered fare plus any tolls levies or extras that may apply to your ride. You agree to pay an additional service fee of 5% in New South Wales, Victoria, South Australia, Queensland, Western Australia, the Australian Capital Territory and in the Northern Territory and Tasmania. This charge will be supplementary to your fare at the end of your trip.</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>If we use a third-party payment processor (the “Payment Processor”) to link your credit card account to the Service, this paragraph will apply. The processing of payments or credits, as applicable, in connection with your use of the Service will be subject to the terms, conditions and privacy policies of the Payment Processor and your credit card issuer in addition to these Terms and Conditions. We are not responsible for any errors by the Payment Processor. In connection with your use of the Services, we will obtain certain transaction details, which we will use solely in accordance with its Privacy and Cookie Notice.</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>Links To Third Party Sites</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>The RIGZIE Pty Ltd Services may contain links to other Websites (“Linked Sites”). The Linked Sites are not under the control of RIGZIE Pty Ltd and RIGZIE Pty Ltd is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. RIGZIE Pty Ltd is not responsible for webcasting or any other form of transmission received from any Linked Site. RIGZIE Pty Ltd is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by RIGZIE Pty Ltd of the site or any association with its operators.</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>Please note that these other websites may send their own cookies to users, collect data or solicit personal information, and you are therefore advised to check the terms of use or produce polices on those websites prior to using them.</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>No Unlawful Or Prohibited Use</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            The Services may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, Communication Services), you agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service. By way of example, and not as a limitation, you agree that when using a Communication Service, you will not: Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Publish post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another’s computer.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Conduct or forward surveys, contests, pyramid schemes or chain letters.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Restrict or inhibit any other user from using and enjoying the Communication Services.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Violate any code of conduct or other guidelines which may be applicable for any Communication Service.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Harvest or otherwise collect information about others, including e-mail addresses, without their consent.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>Use Of Communication Services</p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            You warrant that the information you provide to us is accurate and complete. We are always entitled to verify the information that you have provided and to refuse the Service without providing reasons.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            You may only access the Service using authorised means. It is your responsibility to check to ensure you download the correct Application for your device. We are not liable if you do not have a compatible mobile device or you download the wrong version of the Application for your mobile device. We reserve the right to terminate the Service and the use of the Application should you be using the Service or Application with an incompatible or unauthorised device.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            As a condition of your use of the Services, you warrant to us that you will not use the Services for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You may not use the Services in any manner which could damage, disable, overburden, or impair the Services or interfere with any other party’s use and enjoyment of the Services. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Services. You will only use the Services or download the Application for your sole, personal use and will not resell it to a third party. You will keep secure and confidential your account password or any identification we provide you which allows access to the Service.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            RIGZIE PTY LTD reserves the rights to immediately terminate the Services and use of the Application should you not comply with any of the above rules.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>
            Violate Any Applicable Laws Or Regulations
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            RIGZIE PTY LTD has no obligation to monitor the Communication Services. However, RIGZIE PTY LTD reserves the right to review materials posted to a Communication Service and to remove any materials in its sole discretion. RIGZIE PTY LTD reserves the right to terminate your access to any or all the Communication Services at any time without notice for any reason whatsoever.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            RIGZIE PTY LTD always reserves the right to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in RIGZIE PTY LTD’s sole discretion.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Always use caution when giving out any personally identifying information about yourself or your children in any Communication Service. RIGZIE PTY LTD does not control or endorse the content, messages or information found in any Communication Service and, therefore, RIGZIE PTY LTD specifically disclaims any liability about the Communication Services and any actions resulting from your participation in any Communication Service. Managers and hosts are not authorised RIGZIE PTY LTD spokespersons, and their views do not necessarily reflect those of RIGZIE PTY LTD.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you download the materials.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>
            Term And Termination
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            The agreement between us and you are concluded for an indefinite period.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            You are always entitled to terminate the agreement by permanent deletion of the Application installed on your smart phone or closing your user account thus disabling the use by you of the Application and the Service.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            We are always entitled to terminate the agreement and with immediate effect (by disabling your use of the Application and the Service) if you:

            violate or breach any term of these Terms and Conditions, or

            in the opinion of us, misuse the Service. We are not obliged to give notice of the termination of the agreement in advance. After termination we will give notice thereof in accordance with these Terms and Conditions.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', textTransform: 'uppercase' }}>
            <strong>Materials Provided To Restrict PTY LTD Or Posted On The Website</strong>
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            RIGZIE PTY LTD does not claim ownership of the materials you provide to RIGZIE PTY LTD (including feedback and suggestions) or post, upload, input or submit to any Website or its associated services (collectively Submissions). However, by posting, uploading, inputting, providing or submitting your Submission you are granting RIGZIE PTY LTD, its affiliated companies and necessary sub licensees permission to use your Submission in connection with the operation of their Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            No compensation will be paid with respect to the use of your Submission, as provided herein. RIGZIE PTY LTD is under no obligation to post or use any Submission you may provide and may remove any Submission at any time in RIGZIE PTY LTD’s sole discretion.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or otherwise control all the rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', textTransform: 'uppercase' }}>
            <strong>Information For Personal, Non-Commercial Use Only</strong>
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            You agree that information provided by to you by RIGZIE PTY LTD in connection with the Services is for personal use only and may not be sold, redistributed or used for any commercial purpose (this includes but is not limited to the use of Advertiser contact details for unsolicited commercial correspondence). You may download material from our Services for your personal, non-commercial use only, provided you keep intact all copyright and other proprietary notices. You may not modify, copy, reproduce, republish, upload, post, transmit or distribute in any way any material from our Services including code and software. You must not use our Services for any purpose that is unlawful or prohibited by these terms of use.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            You may not use data mining, robots, screen scraping, or similar data gathering and extraction tools on our Services for establishing, maintaining, advancing or reproducing information contained on our Services on your own website, application or in any other publication, except with our prior written consent.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>
            Privacy
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            You agree that RIGZIE PTY LTD may collect and use, personal information, technical data and related information – including but not limited to technical information about your device, system and application software and peripherals – that is gathered periodically to facilitate the provisions of software updates, product support, and other services to you (if any) related to the Services and to administer those Services. We may also use information to develop new products and services, attend to your enquiries, seek feedback, or tell you about other products or services offered by RIGZIE PTY LTD and its related companies. We may base this information if it is in a form that does not personally identify you, to improve our products or to provide services or technologies to you.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            We will not use or disclose personal information about you for a purpose (the secondary purpose) other than the primary purpose of collection unless:
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            both of the following apply:
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            the secondary purpose is related to the primary purpose of collection and, if the personal information is sensitive information, directly related to the primary purpose of collection;
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            the individual would reasonably expect RIGZIE PTY LTD to use or disclose the information for the secondary purpose; or
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            the individual has consented to the use or disclosure; or
          </p>
          if the information is not sensitive information and the use of the information is for the secondary purpose of direct marketing:
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            it is impractical for RIGZIE PTY LTD to seek the individual’s consent before that particular use; and
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            RIGZIE PTY LTD will not charge the individual for giving effect to a request by the individual to RIGZIE PTY LTD not to receive direct marketing communications; and
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            the individual has not made a request to RIGZIE PTY LTD not to receive direct marketing communications; and
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            in each direct marketing communication with the individual, RIGZIE PTY LTD draws to the individual’s attention, or prominently displays a notice, that he or she may express a wish not to receive any further direct marketing communications; and
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            each written direct marketing communication by RIGZIE PTY LTD with the individual (up to and including the communication that involves the use) sets out RIGZIE PTY LTD’s business address and telephone number and, if the communication with the individual is made by fax, telex or other electronic means, a number or address which RIGZIE PTY LTD can be directly contacted electronically.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            The above operates in relation to personal information that RIGZIE PTY LTD has collected from a related body corporate as if RIGZIE PTY LTD’s primary purpose of collection of the information were the primary purpose for which the related body corporate collected the information.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            The complete RIGZIE PTY LTD privacy policy can be found at: https://rigzie-cf338.web.app/privacy-policy
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>
            Liability Disclaimer
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            The information, software, products, and services included in or available through the Services may include inaccuracies or typographical errors. Changes are periodically added to the information. RIGZIE PTY LTD and/or its suppliers may make improvements and/or changes in the Services at any time. Advice received via the Services should not be relied upon for personal, medical, legal or financial decisions and you should consult an appropriate professional for specific advice tailored to your situation.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            RIGZIE PTY LTD and/or its suppliers make no representations about the suitability, reliability, availability, timeliness, and accuracy of the information, software, products, services and related graphics contained on the Services for any purpose. To the maximum extent permitted by applicable law, all such information, software, products, services and related graphics are provided “as is” without warranty or condition of any kind. RIGZIE PTY LTD and/or its suppliers hereby disclaim all warranties and conditions about this information, software, products, services and related graphics, including all implied warranties or conditions of merchantability, fitness for a particular purpose, title and non-infringement.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            To the maximum extent permitted by applicable law, in no event shall RIGZIE PTY LTD and/or its suppliers be liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages whatsoever including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the Services, with the delay or inability to use the Services or related services, the provision of or failure to provide services, or for any information, software, products, services and related graphics obtained through the Services, or otherwise arising out of the use of the Services, whether based on contract, tort, negligence, strict liability or otherwise, even if RIGZIE PTY LTD or any of its suppliers has been advised of the possibility of damages. Because some states/jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, the above limitation may not apply to you. If you are dissatisfied with any portion of the Service, or with any of these terms of use, your sole and exclusive remedy is to discontinue using the Services.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Service contact: inforigziesydney@gmail.com
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>
            Indemnification
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            By accepting these terms and conditions and using the Services, you agree to indemnify and hold RIGZIE PTY LTD, its affiliates, its licensors, and each of their officers, directors, other users, employees, attorneys and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including lawyer’s fees and costs) arising out of or in connections with your use of the Services, including but not limited to your violation of these Terms and Conditions.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            <strong>Termination/Access Restriction</strong>
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            RIGZIE PTY LTD reserves the right, in its sole discretion, to terminate your access to the RIGZIE PTY LTD Web Site and the related services or any portion thereof at any time, without notice.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            GENERAL To the maximum extent permitted by law, this agreement is governed by the laws of New South Wales, Australia and you hereby consent to the exclusive jurisdiction and venue of courts in New South Wales, Australia in all disputes arising out of or relating to the use of the RIGZIE PTY LTD Web Site.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            <strong>Trademarks</strong>
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            The names of actual companies and products mentioned herein may be the trademarks of their respective owners. Any rights not expressly granted herein are reserved.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            <strong>Copyright</strong>
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Unless otherwise indicated, copyright in all information and other materials on our Services (including information and its arrangement) is owned by or licensed to RIGZIE PTY LTD. All rights are reserved.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Nothing contained on this site should be construed as granting any licence or right of use of any trade mark displayed on the site without the express written consent of RIGZIE PTY LTD.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            <strong>Applicable Law</strong>
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            These Terms and Conditions and all disputes arising in connection with these Terms and Conditions and/or with the use of the Services are governed by the laws of Australia.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>
            Fare
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            In case of any fare dispute trip should be validated in the booking dispatch system otherwise full refund given to the customer.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            Whoever charging on their own personal eftpos terminal and if the query arises regarding the fare dispute you are liable to refund the whole fare and we refund the whole fare without any enquiry.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            <strong>Login In The Dispatch System</strong>
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            All the drivers must login while driving the vehicle and display Network ID in the vehicle.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            All the drivers must be registered with Wavcabs, it’s the responsibility of the operator to make sure as it happens.
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>
            Contact Details
          </p>
          <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', }}>
            All the Operators/Drivers are required to update their contact information within 7 days of change.
          </p>
          <br />
        </div>
      </div>

      <Footer />
    </div>
  );
}
