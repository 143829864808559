import React,{ useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import {
  Typography,
} from '@material-ui/core';
import { colors } from '../components/Theme/WebTheme';
const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  }
}));

const Withdraws = () => {

  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const {
    completeWithdraw
  } = api;
  const dispatch = useDispatch();
  const columns =  [
      {title: t('no'), render:(rowData)=>rowData.tableData.id+1},
      { title: 'ID', field: 'id',editable: 'never'},
      { title: t('driver_name'),field: 'name',editable: 'never'},
      { title: t('requestDate'), field: 'date',editable: 'never'},
      { title: t('amount'), field: 'amount',editable: 'never'},
      { title: t('processed'), field: 'processed', type: 'boolean',editable: 'never'},  
      { title: t('usertype'), field: 'usertype',editable: 'never'}, 
      { title: t('bankName'), field: 'bankName', hidden: settings.bank_fields===false? true: false,editable: 'never'},
      { title: t('bankCode'), field: 'bankCode' , hidden: settings.bank_fields===false? true: false,editable: 'never'},
      { title: t('bankAccount'), field: 'bankAccount', hidden: settings.bank_fields===false? true: false,editable: 'never'}
  ];
  const [data, setData] = useState([]);
  const withdrawdata = useSelector(state => state.withdrawdata);

  const [alluser, setAlluser] = useState('driver');

  useEffect(()=>{
        if(withdrawdata.withdraws){
            let items = withdrawdata.withdraws;
            let arr = [];
            for(let i=0; i<items.length; i++){
              if(items[i].processed === false && items[i].usertype === alluser){
                arr.push(items[i])
              }
            }
            setData(arr);
        }else{
          setData([]);
        }
  },[withdrawdata.withdraws, alluser]);

  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState(null);
  const [itemDetails, setItemDetails] = useState(null);

  // const submitValue = () => {
  //   alert('User Successfully Updated');
  //   dispatch(clearAllUserWithdraw())
  // }

  const drivers = () => {
    setAlluser('driver');
    if(withdrawdata.withdraws){
      let items = withdrawdata.withdraws;
      let arr = [];
      for(let i=0; i<items.length; i++){
        if(items[i].processed === false && items[i].usertype === 'driver'){
          arr.push(items[i])
        }
      }
      setData(arr);
    }else{
      setData([]);
    }
  }

  const fleetadmins = () => {
    setAlluser('fleetadmin');
    if(withdrawdata.withdraws){
      let items = withdrawdata.withdraws;
      let arr = [];
      for(let i=0; i<items.length; i++){
        if(items[i].processed === false && items[i].usertype === 'fleetadmin'){
          arr.push(items[i])
        }
      }
      setData(arr);
    }else{
      setData([]);
    }
  }

  const corporateAdmins = () => {
    setAlluser('corporateAdmin');
    if(withdrawdata.withdraws){
      let items = withdrawdata.withdraws;
      let arr = [];
      for(let i=0; i<items.length; i++){
        if(items[i].processed === false && items[i].usertype === 'corporateAdmin'){
          arr.push(items[i])
        }
      }
      setData(arr);
    }else{
      setData([]);
    }
  }

  return (
    // withdrawdata.loading? <CircularLoading/>:
    <div>
    <div className={classes.heading}>
    <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
    {t('withdraw')}
          </Typography>
            {/* <Button
              type="submit"
              width='45%'
              variant="contained"
              className={classes.button}
              style={{marginTop:10}}
             onClick = {submitValue}
            >
              {t('withdraw_all')}
            </Button> */}

            <Button
              type="submit"
              width='45%'
              variant="contained"
              className={classes.button}
              style={{marginTop:10}}
             onClick = {() => drivers()}
            >
              {t('drivers')}
            </Button>

            <Button
              type="submit"
              width='45%'
              variant="contained"
              className={classes.button}
              style={{marginTop:10, marginLeft: 15}}
             onClick = {() => fleetadmins()}
            >
              {t('fleetadmin')}
            </Button>

            <Button
              type="submit"
              width='45%'
              variant="contained"
              className={classes.button}
              style={{marginTop:10, marginLeft: 15}}
             onClick = {() => corporateAdmins()}
            >
              {t('corporateAdmin')}
            </Button>

          </div>
           
    <MaterialTable
      title={t('withdraw_list')}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr',border:'1px solid #808080'}}
      columns={columns}
      data={data}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        actionsColumnIndex: -1,
        pageSize:20,
        pageSizeOptions:[20,50,100],
        cellStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          borderRight:'1px solid #808080',
          backgroundColor:'#f5f5f5'
      },
        headerStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          border:'2px solid #808080',}
      }}
      localization={{
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('actions')) 
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        labelRowsSelect: (t('rows')),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
      }}
      actions={[
        
        (rowData, index) => ({
          icon: () => <div style={{width:190,fontSize:15}}>
            <input
                type="file"
                style={{width:'100%'}}
                name= {t('image')}
                disabled= {rowData.processed}
                onChange={(event, index) => {
                  setSelectedImage(event.target.files[0]);
                  setItemDetails(rowData);
                }}
            />
            <div>
              {selectedImage && !rowData.processed && rowData.id === itemDetails.id ? 
                <img alt="not fount" width={"100px"} src={URL.createObjectURL(selectedImage)} style={{marginTop:15,marginBottom:20}}/>
                :
               null
              }
              {rowData.receiptImage ? 
              <img alt='License' src={rowData.receiptImage } style={{width: 100}}/>
              : null }
              <br />
              {/* {selectedImage && !rowData.processed && rowData.id === itemDetails.id ? 
                <button onClick={()=>{
                  {
                    setSelectedImage();
                    setItemDetails();
                  }
                }}>Remove</button>
              : null } */}
            </div>
          </div>
        }),
        rowData => ({
          icon: 'check',
          tooltip: t('process_withdraw'),
          disabled: rowData.processed,
          onClick: (event, rowData) => {
            if(selectedImage){
              setAlluser(rowData.usertype);
              dispatch(completeWithdraw({...rowData, withdraImage:selectedImage}));
            }
          }         
        })
      ]}
    />
    </div>
  );
}

export default Withdraws;