import React,{ useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {
  Typography,
  MenuItem,
  Select, 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import moment from 'moment/min/moment-with-locales';
import { colors } from '../components/Theme/WebTheme';
const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#019b9d'
  },
  submit2: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#808080'
  },
  input:{
    width:"100%"
  }
}));

export default function Notifications() {
  const classes = useStyles();

  const { t, i18n  } = useTranslation();
  const isRTL = i18n.dir();
  const {
    sendNotification,
    editNotifications
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);
  const columns =  [
    {title: t('no'), render:(rowData)=>rowData.tableData.id+1},
      {
        title: 'Sent To',
        field: 'usertype',
        lookup: { rider: t('rider'), driver: t('driver') , All: t('all')},
        
      },
      { title: t('title'),field: 'title', },
      { title: t('body'), field: 'body', },
      { title: 'Sent', field: 'date', render: rowData => rowData.date?moment(rowData.date).format('lll'):null},
  ];

  const [data, setData] = useState([]);
  const notificationdata = useSelector(state => state.notificationdata);
  const dispatch = useDispatch();
  const [userType, setUserType] = useState('driver');

  const [pushMessage, setPushMessage] = useState({
    title:'',
    message:''
  });

  console.log(userType);

  
  useEffect(()=>{
    if(notificationdata.notifications){
        setData(notificationdata.notifications);
    }else{
        setData([]);
    }
  },[notificationdata.notifications]);

  const handleUserTypeSelect = (event) => {
    setUserType(event.target.value); 
  }

  const handleTextChange = (e) => {
    setPushMessage({ ...pushMessage, [e.target.name]: e.target.value });
  }

  const submit = () => {
    if(userType === 'Select User'){
      alert('Please Select User');
    }
    else{
      if(pushMessage.title && pushMessage.message){
        let arr = {
          date: new Date().getTime(),
          usertype:userType,
          title:pushMessage.title,
          body:pushMessage.message
        };
        if(settings.AllowCriticalEditsAdmin){
          dispatch(sendNotification(arr));
          dispatch(editNotifications(arr, "Add"));
          setUserType('driver');
          setPushMessage({
            title:'',
            message:''
          });
        }else{
          alert(t('demo_mode'));
        }
      }else{
        alert('Please Fill add field');
      }
    }
  }

  return (
    <div>
        {/* promodata.loading ? <CircularLoading /> : */}
    <div>
    <div className={classes.heading}>

      <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
        {t('push_notification_title')}
      </Typography>
    </div>
      <Box boxShadow={6} m={2} p={2} style={{borderRadius:20,border: '2px solid #109496',width:'100%'}}>
     <CardContent>

     <Grid container style={{flexDirection:'row'}} >
          <Grid style={{width:'20%'}}>
                <Typography component="h1" variant="h5"className={isRTL ==="rtl"? classes.rootRtl:null}  style={{ fontWeight:'bolder',color:'black',}}>
                    {t('sent_to')}
                </Typography>
                <Typography component="h1" variant="h5" style={{ fontWeight:'bolder',color:'black',marginTop:40}}>
                  {t('title')}
                </Typography>
                <Typography component="h1" variant="h5" style={{fontWeight:'bolder',color:'black',marginTop:50}}>
                  {t('push_message')}
                </Typography>
        </Grid>
          <Grid item xs={9}>
            <Select
              id="booking-type-native"
              value={userType}
              onChange={handleUserTypeSelect}
              className={classes.input}
              variant="outlined"
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem  key={'Select User'} value={'Select User'} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                {'Select User'}
              </MenuItem>
              <MenuItem  key={'All Driver'} value={'driver'} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                {'All Driver'}
              </MenuItem>
              {/* <MenuItem  key={'All Rider'} value={'rider'} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                {'All Rider'}
              </MenuItem>
              <MenuItem  key={'Both'} value={'All'} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                {'Both'}
              </MenuItem> */}
            </Select> 
            <TextField
              className={isRTL ==="rtl"? classes.rootRtl:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label= {t('title')}
              name= "title"
              onChange={handleTextChange}
              value={pushMessage.tittle}
            />
            <TextField
              className={isRTL ==="rtl"? classes.rootRtl:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="message"
              label={t('push_message')}
              name="message"
              //autoComplete="promo_discount_value"
              onChange={handleTextChange}
              value={pushMessage.message}
            />
      </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
              type="submit"
              width='45%'
              variant="contained"
              className={classes.submit}
              onClick={()=>submit()}
            >
              {t('submit')}
            </Button>
          </Grid>
        </Grid>
        {/* <AlertDialog open={settingsdata.error.flag && clicked} onClose={handleClose}>{t('update_failed')}</AlertDialog> */}
       </CardContent>
       </Box>
      </div>
   {/* notificationdata.loading? <CircularLoading/>: */}
    <div style={{marginTop:50}} > </div>
    <div className={classes.heading}>

      <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
        {t('notification_history')}
      </Typography>
    </div>
    <MaterialTable
      title={t('push_notification_title')}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr',border:'2px solid #808080'}}
      data={data}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        sorting: true,
        actionsColumnIndex: -1,
        pageSize:10,
        pageSizeOptions:[10,30,60,100],
        headerStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          border:'2px solid #808080',
        },
        cellStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          borderRight:'1px solid #808080',
          backgroundColor:'#f5f5f5'
        },
      }}
      localization={{body:{
        addTooltip: (t('add')),
        deleteTooltip: (t('delete')),
        editTooltip: (t('edit')),
        emptyDataSourceMessage: (
          (t('blank_message'))
      ),
      editRow: { 
        deleteText: (t('delete_message')),
        cancelTooltip: (t('cancel')),
        saveTooltip: (t('save')) 
        }, 
        },
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('actions')) 
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        labelRowsSelect: (t('rows')),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
      }}
    />
    </div>
  );
}
