export const FirebaseConfig = {
	"projectId": "rigzie-cf338",
	"appId": "1:322238705424:web:6a4090a829c2c8d4467f95",
	"databaseURL": "https://rigzie-cf338-default-rtdb.firebaseio.com",
	"storageBucket": "rigzie-cf338.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCax2260PBeBrnY22nWuUa0EMbHEjb_2ak",
	"authDomain": "rigzie-cf338.firebaseapp.com",
	"messagingSenderId": "322238705424",
	"measurementId": "G-BPVKP7SRR8"
};