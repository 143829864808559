import React from 'react';
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
  } from "react-google-maps";
  /*global google*/

const BookingMap = withGoogleMap(props =>
  <GoogleMap
    defaultZoom={12}
    zoom={12}
    defaultCenter={{ lat: 22.66368, lng: 88.42728 }}
  >
    {props.pickUpLocations !== null ?
        <Marker
            position={{ lat: props.pickUpLocations.lat, lng: props.pickUpLocations.lng }}
            key={props.pickUpLocations.description}
            icon={{   url: "https://cdn.pixabay.com/photo/2014/04/03/10/03/google-309741_960_720.png" ,
            scaledSize:  new google.maps.Size(25,40)}}
        >
            <InfoWindow>
            <div>{props.pickUpLocations.description}</div>
            </InfoWindow>
        </Marker>
        :null
    }
    {props.dropLocations !== null ?
        <Marker
            position={{ lat: props.dropLocations.lat, lng: props.dropLocations.lng }}
            key={props.dropLocations.description}
            icon={{   url: "https://cdn.pixabay.com/photo/2014/04/03/10/03/google-309740_960_720.png" ,
            scaledSize:  new google.maps.Size(25,40)}}
        >
            <InfoWindow>
            <div>{props.dropLocations.description}</div>
            </InfoWindow>
        </Marker>
        :null
    }
  </GoogleMap>
);

export default BookingMap;