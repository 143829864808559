import {
    FETCH_PAYMENT_METHODS,
    FETCH_PAYMENT_METHODS_SUCCESS,
    FETCH_PAYMENT_METHODS_FAILED,
    UPDATE_WALLET_BALANCE,
    UPDATE_WALLET_BALANCE_SUCCESS,
    UPDATE_WALLET_BALANCE_FAILED,
    CLEAR_PAYMENT_MESSAGES
} from "../store/types";
import { RequestPushMsg } from '../other/NotificationFunctions';
import { firebase } from '../config/configureFirebase';
import store from '../store/store';
import { onValue, get, update, push, set } from "firebase/database";
import { SMSFunction } from '../other/SMSFunction';

export const fetchPaymentMethods = () => (dispatch) => {
   
    const {
        config
    } = firebase;
 
    dispatch({
        type: FETCH_PAYMENT_METHODS,
        payload: null,
    });
    
    fetch(`https://${config.projectId}.web.app/get_providers`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .then((responseJson) => {
            if (responseJson.length > 0) {
                dispatch({
                    type: FETCH_PAYMENT_METHODS_SUCCESS,
                    payload: responseJson,
                });
            }else{
                dispatch({
                    type: FETCH_PAYMENT_METHODS_FAILED,
                    payload: store.getState().languagedata.defaultLanguage.no_provider_found,
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: FETCH_PAYMENT_METHODS_FAILED,
                payload: store.getState().languagedata.defaultLanguage.provider_fetch_error + ": " + error.toString(),
            });
        });
};

export const clearMessage = () => (dispatch) => {
    dispatch({
        type: CLEAR_PAYMENT_MESSAGES,
        payload: null,
    });    
};


export const addToWallet = (uid, amount) => async (dispatch) => {
    const {
        walletHistoryRef,
        singleUserRef,
        settingsRef
    } = firebase;

    dispatch({
        type: UPDATE_WALLET_BALANCE,
        payload: null
    });

    const settingsdata = await get(settingsRef);
    const settings = settingsdata.val();

    onValue(singleUserRef(uid), snapshot => {
        if (snapshot.val()) {
            let walletBalance = parseFloat(snapshot.val().walletBalance);
            const pushToken = snapshot.val().pushToken;
            walletBalance = parseFloat((parseFloat(walletBalance) + parseFloat(amount)).toFixed(settings.decimal));
            let details = {
                type: 'Credit',
                amount: parseFloat(amount),
                date: new Date().getTime(),
                txRef: 'AdminCredit'
            }
            update(singleUserRef(uid),{walletBalance: walletBalance}).then(() => {
                push(walletHistoryRef(uid), details).then(()=>{
                    dispatch({
                        type: UPDATE_WALLET_BALANCE_SUCCESS,
                        payload: null
                    });
                }).catch(error=>{
                    dispatch({
                        type: UPDATE_WALLET_BALANCE_FAILED,
                        payload: error.code + ": " + error.message,
                    });            
                })
                if(pushToken){
                    RequestPushMsg(
                        pushToken,
                        {
                            title: store.getState().languagedata.defaultLanguage.notification_title,
                            msg:  store.getState().languagedata.defaultLanguage.wallet_updated,
                            screen: 'Wallet'
                        }
                    );
                }
                if (snapshot.val().mobile) {
                    SMSFunction(snapshot.val(), false);
                }
            }).catch(error=>{
                dispatch({
                    type: UPDATE_WALLET_BALANCE_FAILED,
                    payload: error.code + ": " + error.message,
                });
            });
            
        }                                                                                                                                                                                                                                                                                                                                                                               
    }, {onlyOnce: true});
};


export const updateWalletBalance = (balance, details) => async (dispatch) => {

    const {
        walletBalRef,
        walletHistoryRef,
        auth,
        singleUserRef,
        withdrawRef,
        settingsRef
    } = firebase;
    
    let uid = auth.currentUser.uid;
    dispatch({
        type: UPDATE_WALLET_BALANCE,
        payload: null
    });

    const settingsdata = await settingsRef.once("value");
    const settings = settingsdata.val();
    set(walletBalRef(uid),parseFloat(parseFloat(balance).toFixed(settings.decimal))).then(() => {
        push(walletHistoryRef(uid),details).then(()=>{
            onValue(singleUserRef(uid), async snapshot => {
                if (snapshot.val()) {
                    let profile = snapshot.val();
                    dispatch({
                        type: UPDATE_USER_PROFILE,
                        payload: profile
                    });
                    dispatch({
                        type: UPDATE_WALLET_BALANCE_SUCCESS,
                        payload: null
                    });

                    RequestPushMsg(
                        snapshot.val().pushToken,
                        {
                            title: store.getState().languagedata.defaultLanguage.notification_title,
                            msg:store.getState().languagedata.defaultLanguage.wallet_updated,
                            screen: 'Wallet'
                        })(firebase);

                    if (snapshot.val().mobile) {
                        SMSFunction(snapshot.val(), false)(firebase);
                    }   

                    if(details.type == 'Withdraw'){
                        push(withdrawRef,{
                            uid : uid,
                            name : profile.firstName +  ' ' + profile.lastName,
                            amount : parseFloat(details.amount),
                            date : details.date,
                            bankName : profile.bankName? profile.bankName : '',
                            bankCode : profile.bankCode? profile.bankCode : '',
                            bankAccount : profile.bankAccount? profile.bankAccount : '',
                            processed:false
                        });
                    }
                }
            },{onlyOnce: true}); 
        }).catch(error=>{
            dispatch({
                type: UPDATE_WALLET_BALANCE_FAILED,
                payload: error.code + ": " + error.message,
            });            
        })
    }).catch(error=>{
        dispatch({
            type: UPDATE_WALLET_BALANCE_FAILED,
            payload: error.code + ": " + error.message,
        });
    });
};
