import React from 'react';
import { Col, Row} from 'antd';
import 'antd/dist/antd.css';

export class PrintDoc extends React.PureComponent {
  
    render() {
      const {image} = this.props;
       
      return (
     
          <Row>
            <Col>
              <div style={{width:'250'}}>
                <img src={image} alt='Icon' width="100%" height="600"/>
              </div>
            </Col>
          </Row>
    
      )
    }
  }

export default PrintDoc;
