import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { colors } from '../components/Theme/WebTheme';
const useStyles = makeStyles((theme) => ({
heading:{
marginBottom:20
},
submit:{
backgroundColor:'#379ab2',
width:'95%'
},
submit1:{
backgroundColor:'White',
color:'#4c63b8'
},
notchedOutline:{
borderColor:colors.Rigzie1,
width:'95%',
},
input:{
  width:"95%",
  marginTop:16
}
}));

export default function EditSubscription() {

const location = useLocation();
const getId = location.state.detail;
const [data, setData] = useState(getId);

const { t,i18n } = useTranslation();
const isRTL = i18n.dir();
const classes = useStyles();
const {
    editSubscription
} = api;
const history = useHistory();
const dispatch = useDispatch();

const handleTextChange = (e) => {
  setData({ ...data, [e.target.name]: e.target.value });
};

const submitValue = () => {
  if( data.subscription_name  && data.subscription_value && data.subscription_description && data.subscription_days ){
    let arr = {
      id: data.id,
      subscription_description: data.subscription_description,
      subscription_value: data.subscription_value,
      subscription_name: data.subscription_name,
      subscription_days: data.subscription_days
    };
    dispatch(editSubscription(arr, "Update"));
    alert('Subscription Update Successfuly');
  } else {
    alert('Please Fill add field');
  }
}

const goBack=()=>{
  history.goBack();
}

  return (
    // data.loading ? <CircularLoading /> :
    <div>
    <div className={classes.heading}>
    <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
    {t('edit_subscription')}
          </Typography>
          </div>
          <Button
                type="back"
                 width='3%'
                 variant="contained"
                 className={classes.submit1}
                 startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
                 onClick={()=>goBack()}
               >
                  {t('go_back')}
               </Button>
               <Box  boxShadow={6}
       
       m={2}
       p={2} style={{borderRadius:20,border: '2px solid #109496',width:'35%'}}>
     <CardContent>

     <Grid container style={{direction:isRTL ==='rtl'?'rtl':'ltr'}} >
          <Grid item xs={12}>
            <TextField
            className={isRTL ==="rtl"? classes.rootRtl:null}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              margin="normal"
              required
              fullWidth
              id="subscription_name"
               label={t('subscription_name')}
               name="subscription_name"
              autoComplete="subscription_name"
              onChange={handleTextChange}
              defaultValue={data.subscription_name}
            />
            <TextField
              className={isRTL ==="rtl"? classes.rootRtl:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="subscription_description"
              label={t('description')}
              name="subscription_description"
              autoComplete="subscription_description"
              onChange={handleTextChange}
              defaultValue={data.subscription_description}
            />
            <TextField
              className={isRTL ==="rtl"? classes.rootRtl:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="subscription_value"
              label={t('subscription_value')}
              name="subscription_value"
              autoComplete="subscription_value"
              onChange={handleTextChange}
              defaultValue={data.subscription_value}
            />
             <TextField
              className={isRTL ==="rtl"? classes.rootRtl:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="subscription_days"
              label={t('subscription_days')}
              name="subscription_days"
              autoComplete="subscription_days"
              onChange={handleTextChange}
              defaultValue={data.subscription_days}
            />
        </Grid>
    </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
              type="submit"
              width='45%'
              variant="contained"
              className={classes.submit}
              onClick = {submitValue}
            >
              {t('submit')}
            </Button>
            {/* <Button
              type="submit"
              width='45%'
              variant="contained"
              
              className={classes.reset}
            >
              Reset
            </Button> */}
          </Grid>
        </Grid>
        {/* <AlertDialog open={settingsdata.error.flag && clicked} onClose={handleClose}>{t('update_failed')}</AlertDialog> */}
       </CardContent>
       </Box>
      </div>
  );
}