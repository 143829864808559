import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  Typography,
  Modal
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { colors } from '../components/Theme/WebTheme';
import { useSelector, useDispatch } from 'react-redux';
import { api } from 'common';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import IconButton from '@material-ui/core/IconButton';
import ReactToPrint from "react-to-print";
import PrintDoc from '../components/PrintDoc.js';
import camera from '../assets/img/camera.png';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 20
  },
  submit: {
    backgroundColor: '#379ab2',
    width: '25%',
    marginRight: 20
  },
  remove: {
    backgroundColor: '#ff6666',
    width: '25%',
    marginRight: 20
  },
  submit1: {
    backgroundColor: 'White',
    color: '#4c63b8'
  },
  notchedOutline: {
    borderColor: colors.Rigzie1,
    width: '95%',
  },
  txt: {
    textAlign: 'center',
    color: colors.Rigzie1,
    fontWeight: 'bold',
    marginBottom: 15
  },
  txt1: {
    color: 'black',
    fontWeight: 'bold',
    margin: 20
  },
  box: {
    borderRadius: 10,
    border: '1px solid #444444'
  },
  printPaper: {
    width: 800,
    height: 720,
    backgroundColor: theme.palette.background.paper,
    margin: 50,
    marginLeft:250,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
  },
  buttonStyle: {
    height: 20
  }
}));

export default function DriverDocument() {
  const location = useLocation();
  const data1 = location.state.detail;
  const data = location.state.detail;
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const auth = useSelector(state => state.auth);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const history = useHistory()
  const [data2, setdata2] = useState([]);

  const {
    updateLicenseImage,
    fetchUsersOnce
  } = api;
  const dispatch = useDispatch();

    useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);


  useEffect(() => {
    if (staticusers && data1) {
      setdata2(staticusers.filter(user => user.usertype === 'driver' && user.id === data1.id));
    } else {
      setdata2([]);
    }
  }, [staticusers, data1]);

  const [state, setState] = useState({
    licenceFront: null,
    licenceBack: null,
    alarmCertificate: null,
    cameraInstaller: null,
    engineeringCertificate: null,
    inspectionReport: null,
    cameraCertificate: null,
    rego: null,
    ctp: null
  });

  const goBack = () => {
    history.goBack();
  }

  const submitValue = async () => {
    if (auth && auth.info) {
      if (state.licenceFront || state.licenceBack || state.alarmCertificate || state.cameraInstaller || state.engineeringCertificate || state.inspectionReport || state.cameraCertificate || state.rego || state.ctp) {
        if (state.licenceFront) {
         await dispatch(updateLicenseImage(data2[0], state.licenceFront, 'licenceFront', false));
          setState({
            ...state,
            licenceFront: null,

          });
          alert(t('update_data'))
        }
        else if (state.licenceBack) {
          await dispatch(updateLicenseImage(data2[0], state.licenceBack, 'licenceBack', false));
          setState({
            ...state,
            licenceBack: null,
          });
          alert(t('update_data'))
        }
        else if (state.alarmCertificate) {
          await dispatch(updateLicenseImage(data2[0], state.alarmCertificate, 'alarmCertificate', false));
          setState({
            ...state,
            alarmCertificate: null
          });
          alert(t('update_data'))
        }
        else if (state.cameraInstaller) {
          await dispatch(updateLicenseImage(data2[0], state.cameraInstaller, 'cameraInstaller', false));
          setState({
            ...state,
            cameraInstaller: null
          });
          alert(t('update_data'))
        }
        else if (state.engineeringCertificate) {
          await dispatch(updateLicenseImage(data2[0], state.engineeringCertificate, 'engineeringCertificate', false));
          setState({
            ...state,
            engineeringCertificate: null
          });
          alert(t('update_data'))
        }
        else if (state.inspectionReport) {
          await dispatch(updateLicenseImage(data2[0], state.inspectionReport, 'inspectionReport', false));
          setState({
            ...state,
            inspectionReport: null
          });
          alert(t('update_data'))
        }
        else if (state.cameraCertificate) {    
          await dispatch(updateLicenseImage(data2[0], state.cameraCertificate, 'cameraCertificate', false));
          setState({
            ...state,
            cameraCertificate: null
          });
          alert(t('update_data'))
        }
        else if (state.rego) {  
          await dispatch(updateLicenseImage(data2[0], state.rego, 'rego', false));
          setState({
            ...state,
            rego: null
          });
          alert(t('update_data'))
        }
        else if (state.ctp) {        
          await dispatch(updateLicenseImage(data2[0], state.ctp, 'ctp', false));
          setState({
            ...state,
            ctp: null
          });
          alert(t('update_data'))
        }
        dispatch(fetchUsersOnce());
      }
    } 
  }

  const [printModel, setPrintModel] = useState(false);
  const [printData, setPrintData] = useState();

  const handlePrintModelColse = (e) => {
    e.preventDefault();
    setPrintModel(false);
  };
  const componentRef = useRef();
  const rootRef = useRef(null);

  return (
    <div>
      <div className={classes.heading}>
        <Typography component="h1" variant="h5" style={{ marginTop: '8px', fontWeight: 'bolder', color: colors.Rigzie1, textDecoration: 'underline' }}>
          {t('documet') + ' ' + (data2 && data2[0] ? data2[0].firstName : null) }
        </Typography>
      </div>
      <Button
        type="back"
        width='3%'
        variant="contained"
        className={classes.submit1}
        startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
        onClick={() => goBack()}
      >
        {t('go_back')}
      </Button>
      <Box boxShadow={6}
        m={2}
        style={{ borderRadius: 20, width: '100%', height: '90%' }}>
        <Grid container style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
          {/* Left Column */}
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('licence_front')}
              </Typography>
              {state.licenceFront ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.licenceFront)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.licenceFront })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.licenceFront })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.licenceFront ?
                    <div>
                      <img alt='LicenceFront' src={data2[0].document.licenceFront} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.licenceFront);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >
                    </div>
                    :
                    <div>
                      {!state.licenceFront ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, licenceFront: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>

            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('inspection_report')}
              </Typography>
              {state.inspectionReport ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.inspectionReport)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.inspectionReport })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.inspectionReport })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.inspectionReport ?
                    <div>
                      <img alt='inspectionReport' src={data2[0].document.inspectionReport} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.inspectionReport);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >
                    </div>
                    :
                    <div>
                      {!state.inspectionReport ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, inspectionReport: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>

            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('alarm_certificate')}
              </Typography>
              {state.alarmCertificate ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.alarmCertificate)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.alarmCertificate })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.alarmCertificate })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.alarmCertificate ?
                    <div>
                      <img alt='alarmCertificate' src={data2[0].document.alarmCertificate} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.alarmCertificate);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >
                    </div>
                    :
                    <div>
                      {!state.alarmCertificate ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, alarmCertificate: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>

            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('vehicle_registration')}
              </Typography>
              <div>
                <img src={'https://cdn.pixabay.com/photo/2017/11/10/05/24/add-2935429_960_720.png'} 
                onClick={()=>history.push( {pathname: '/addMultiDoc', state: {detail: data2, document: t('vehicle_registration') }})} 
                  style={{ width: '100%', height: 280, borderRadius: 10 }} alt="nice"/>
              </div>
            </Box>

            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('driving_record')}
              </Typography>
              <div>
                <img src={'https://cdn.pixabay.com/photo/2017/11/10/05/24/add-2935429_960_720.png'} 
                onClick={()=>history.push( {pathname: '/addMultiDoc', state: {detail: data2, document: t('driving_record') }})} 
                  style={{ width: '100%', height: 280, borderRadius: 10 }} alt="nice"/>
              </div>
            </Box>

          </Grid>

          {/* Middle Column */}
          <Grid item xs={4} >
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('licence_back')}
              </Typography>
              {state.licenceBack ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.licenceBack)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.licenceBack })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.licenceBack })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data.document.licenceBack ?
                    <div>
                      <img alt='LicenceBack' src={data2[0].document.licenceBack} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data.document.licenceBack);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >
                    </div>
                    :
                    <div>
                      {!state.licenceBack ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, licenceBack: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>

            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('camera_installer')}
              </Typography>
              {state.cameraInstaller ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.cameraInstaller)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.cameraInstaller })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.cameraInstaller })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.cameraInstaller ?
                    <div>
                      <img alt='cameraInstaller' src={data2[0].document.cameraInstaller} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.cameraInstaller);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >
                    </div>
                    :
                    <div>
                      {!state.cameraInstaller ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, cameraInstaller: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>

            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('ctp')}
              </Typography>
              {state.ctp ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.ctp)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.ctp })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.ctp })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.ctp ?
                    <div>
                      <img alt='ctp' src={data2[0].document.ctp} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.ctp);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >
                    </div>
                    :
                    <div>
                      {!state.ctp ?
                        // <div style={{ width: '100%', height: 250, borderRadius: 10, backgroundColor: colors.background }}>

                        //   <Typography className={classes.txt1}>
                        //     {t('ctp_not_found')}
                        //   </Typography>
                        // </div>

                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>

                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, ctp: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>

            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('vehicle_insurance')}
              </Typography>
              <div>
                <img src={'https://cdn.pixabay.com/photo/2017/11/10/05/24/add-2935429_960_720.png'} 
                onClick={()=>history.push( {pathname: '/addMultiDoc', state: {detail: data2, document: t('vehicle_insurance') }})} 
                  style={{ width: '100%', height: 280, borderRadius: 10 }} alt="nice"/>
              </div>
            </Box>

            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('mechanic_receipts')}
              </Typography>
              <div>
                <img src={'https://cdn.pixabay.com/photo/2017/11/10/05/24/add-2935429_960_720.png'} 
                onClick={()=>history.push( {pathname: '/addMultiDoc', state: {detail: data2, document: t('mechanic_receipts') }})} 
                  style={{ width: '100%', height: 280, borderRadius: 10 }} alt="nice"/>
              </div>
            </Box>

          </Grid>

          {/* Right column */}
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('engineering_certificate')}
              </Typography>
              {state.engineeringCertificate ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.engineeringCertificate)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.engineeringCertificate })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.engineeringCertificate })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.engineeringCertificate ?
                    <div>
                      <img alt='engineeringCertificate' src={data2[0].document.engineeringCertificate} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.engineeringCertificate);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >
                    </div>
                    :
                    <div>
                      {!state.engineeringCertificate ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, engineeringCertificate: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>

            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('camera_certificate')}
              </Typography>
              {state.cameraCertificate ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.cameraCertificate)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.cameraCertificate })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.cameraCertificate })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.cameraCertificate ?
                    <div>
                      <img alt='cameraCertificate' src={data2[0].document.cameraCertificate} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.cameraCertificate);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >
                    </div>
                    :
                    <div>
                      {!state.cameraCertificate ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, cameraCertificate: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>

            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('rego')}
              </Typography>
              {state.rego ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.rego)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.rego })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.rego })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.rego ?
                    <div>
                      <img alt='rego' src={data2[0].document.rego} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.rego);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >
                    </div>
                    :
                    <div>
                      {!state.rego ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, rego: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
            
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('police_check')}
              </Typography>
              <div>
                <img src={'https://cdn.pixabay.com/photo/2017/11/10/05/24/add-2935429_960_720.png'} 
                onClick={()=>history.push( {pathname: '/addMultiDoc', state: {detail: data2, document: t('police_check') }})} 
                  style={{ width: '100%', height: 280, borderRadius: 10 }} alt="nice"/>
              </div>
            </Box>

            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('identity_card')}
              </Typography>
              <div>
                <img src={'https://cdn.pixabay.com/photo/2017/11/10/05/24/add-2935429_960_720.png'} 
                onClick={()=>history.push( {pathname: '/addMultiDoc', state: {detail: data2, document: t('identity_card') }})} 
                  style={{ width: '100%', height: 280, borderRadius: 10 }} alt="nice"/>
              </div>
            </Box>

          </Grid>
          {/* <Grid item xs={3} style={{ marginLeft: 30, margin: 30 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submitValue}
            >
              {t('submit')}
            </Button>
          </Grid> */}
        </Grid>
        {/* <AlertDialog open={settingsdata.error.flag && clicked} onClose={handleClose}>{t('update_failed')}</AlertDialog> */}
      </Box>

      {printData ?
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={printModel}
          className={classes.modal}
          onClose={handlePrintModelColse}
          container={() => rootRef.current}
        >
          <Grid container spacing={1} className={classes.printPaper}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <PrintDoc ref={componentRef}
                image={printData}
              />
              <ReactToPrint
                content={() => componentRef.current}
                trigger={() => <Button variant="contained" color="success" style={{marginLeft:260}}>{'Print'}</Button>}
              />
              <Button onClick={handlePrintModelColse} variant="contained" style={{ marginLeft: 20 }}>{t('cancel')}</Button>
            </Grid>
          </Grid>
        </Modal>
        : null}

    </div>
  );
}