import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import {
  Typography,
  Button,
  Grid,
  Modal
} from '@material-ui/core';
import moment from 'moment/min/moment-with-locales';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { colors } from '../components/Theme/WebTheme';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import UsersCombo from '../components/UsersCombo';
import { api, FirebaseContext } from 'common';
import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: 680,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  deletepaper: {
    width: 590,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #009CA3',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10
  },
  heading: {
    marginBottom: 20
  },
  submit: {
    backgroundColor: colors.Rigzie1,
    color: 'white'
  },
  submit2: {
    display: 'flex'
  },
  submit4:{
    display: 'flex'
  },
  submit5:{
    backgroundColor:'red',
    width:'100%',
    borderRadius:7,
    marginTop:5,
    padding:4
  },
}));
const BookingHistory = (props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const classes = useStyles();
  const [users, setUsers] = useState(null);
  const [userCombo, setUserCombo] = useState(null);
  const rootRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const [role, setRole] = useState(null);
  const dispatch = useDispatch();
  const { config } = useContext(FirebaseContext);
  const {
    cancelBooking,
    updateBooking,
    RequestPushMsg,
    deleteBooking
  } = api;
  const [selectedBooking, setSelectedBooking] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const auth = useSelector(state => state.auth);
  const userdata = useSelector(state => state.usersdata);
  const columns = [
    { title: t('no'), render: (rowData) => rowData.tableData.id + 1 },
    { title: t('booking_id'), field: 'id', },
    { title: t('booking_date'), field: 'tripdate', render: rowData => rowData.tripdate ? moment(rowData.tripdate).format('lll') : null, },
    { title: t('customer_name'), field: 'customer_name' },
    { title: t('rider_contact'), field: 'customer_contact' },
    { title: t('assign_driver'), field: 'driver_name' },
    { title: t('trip_start_time'), field: 'trip_start_time' },
    { title: t('trip_end_time'), field: 'trip_end_time' },
    { title: t('pickup_address'), field: 'pickupAddress' },
    { title: t('drop_address'), field: 'dropAddress' },
    { title: t('car_type'), field: 'carType' },
    { title: t('booking_status'), field: 'status', render: rowData => <span style={{ backgroundColor: rowData.status === 'NEW' || rowData.status === 'ACCEPTED' || rowData.status === 'ARRIVED'  ? 'gold' : (rowData.status === 'CANCELLED' ? 'red' : 'green'), color: 'white' }}>{t(rowData.status)}</span> }
  ];

  const assignDriver = () => {
    let booking = data[rowIndex];
    if (booking['requestedDrivers']) {
      booking['requestedDrivers'][userCombo.uid] = true;
    } else {
      booking['requestedDrivers'] = {};
      booking['requestedDrivers'][userCombo.uid] = true;
    }
    dispatch(updateBooking(booking));
    RequestPushMsg(
      userCombo.pushToken,
      {
        title: t('notification_title'),
        msg: t('new_booking_notification'),
        screen: 'DriverTrips'
      }
    );
    setUserCombo(null);
    handleClose();
    alert("Driver assigned successfully and notified.");
  }

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(cancelBooking({
        reason: value,
        booking: selectedBooking,
        cancelledBy: role
      }));
    }
    setOpenConfirm(false);
  }


  const handleClose = () => {
    setOpen(false);
  }
  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setRole(auth.info.profile.usertype);
    }
  }, [auth.info]);

  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (user.usertype === 'driver') {
            arr.push({
              'firstName': user.firstName,
              'lastName': user.lastName,
              'mobile': user.mobile,
              'email': user.email,
              'uid': user.id,
              'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
              'pushToken': user.pushToken,
              'carType': user.carType,
              'fleetadminId' : user.fleetadminId ? user.fleetadminId: null,
              'driverActiveStatus' : user.driverActiveStatus ? user.driverActiveStatus : false
            });
        }
      }
      setUsers(arr);
    }
  }, [userdata.users, settings.AllowCriticalEditsAdmin]);
 
  useEffect(() => {
    if (bookinglistdata.bookings) {
      setData(bookinglistdata.bookings);
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings]);

  const [filteruser, setfilteruser] = useState([])

  const filterUser=(rowData)=>{
    setRowIndex(rowData.tableData.id);
    if(users && data){
      let arr = []
      for (let i = 0; i < users.length; i++) {        
          if (role === 'admin' && users[i].uid !== data[rowData.tableData.id].offLoader && users[i].driverActiveStatus ) {
            arr.push(users[i]);
          }
          if (role === 'fleetadmin' && users[i].fleetadminId === auth.info.uid && users[i].driverActiveStatus) {
            arr.push(users[i]);;
          }
      }
      setfilteruser(arr);
    }
    setOpen(true);
  }

  const [deleteModel, setDeleteModel] = useState(false);
  const [bookingDelete, setBookingDelete] = useState([]);

  const deleteSingleBooking=(rowData)=>{
    setDeleteModel(true);
    setBookingDelete(rowData)
  }

  const closeDeleteModel=()=>{
    setDeleteModel(false);
  }

  const deleteBookingData = () => {
    dispatch(deleteBooking(bookingDelete));
    setDeleteModel(false);
  }

  const pushSms = async (item) => {

    let body = {
      "messages": [
      {
        "content": "HI, Your booking Id is - " + item.id + ", " +
        "Your Pickup lOcation is - " + item.pickup.add + ", " +
        "Your Drop lOcation is - " + item.drop.add + ", " +
        "Booking Status - " + item.status + ", " +
        "Trip Cost - " + item.trip_cost,
        "destination_number" : item.customer_contact,
        "format": "SMS",
        "source_number": "+61459331800"
      }
  ]}

  await fetch(`https://${config.projectId}.web.app/smsFunctions`, {
    method: 'POST',
    headers: {
        'Accept': 'application/json'
    },
    body: JSON.stringify(body)
    })
    .then((response) => {
        console.log('SMS send')
    })
    .catch((error) => {
        console.log(error)
    });
  }

  return (
    bookinglistdata.loading ? <CircularLoading /> :
      <div>
        <div className={classes.heading}>
          <Typography component="h1" variant="h5" style={{ marginTop: '8px', fontWeight: 'bolder', color: colors.Rigzie1, textDecoration: 'underline' }}>
            {t('booking_history')}
          </Typography>
        </div>
        <MaterialTable
          title={t('booking_title')}
          style={{ border: '1px solid #808080' }}
          columns={columns}
          data={data}
          options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100],
            cellStyle: {
              textAlign: isRTL === 'rtl' ? 'right' : 'center',
              borderRight: '1px solid #808080',
              backgroundColor: '#f5f5f5'
            },
            headerStyle: {
              textAlign: isRTL === 'rtl' ? 'right' : 'center',
              border: '2px solid #808080',
            }
          }}
          localization={{
            toolbar: {
              searchPlaceholder: (t('search')),
              exportTitle: (t('export')),
            },
            header: {
              actions: (t('details'))
            },
            pagination: {
              labelDisplayedRows: ('{from}-{to} ' + (t('of')) + ' {count}'),
              labelRowsSelect: (t('rows')),
              firstTooltip: (t('first_page_tooltip')),
              previousTooltip: (t('previous_page_tooltip')),
              nextTooltip: (t('next_page_tooltip')),
              lastTooltip: (t('last_page_tooltip'))
            },
          }}
          actions={[
            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <div className={classes.submit2}>
                  <PersonAddIcon icon={PersonAddIcon} />
                  <Typography variant="subtitle2">{t('assign')}</Typography>
                </div>
              </div>,
              disabled: ((rowData.status === 'NEW' || rowData.status === 'ACCEPTED' ) && (role === 'admin' || role === 'fleetadmin')) ? false : true,
              onClick: (event, rowData) => {
                // setRowIndex(rowData.tableData.id);
                filterUser(rowData);
                //setOpen(true);
              }
            }),
            rowData => ({
              icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
               <div className={classes.submit4}>
                  <Typography>{t('cancel')}</Typography>
                  <CancelIcon icon={CancelIcon}/>
                </div>
            </div>,
            disabled: rowData.status==='NEW' || rowData.status==='ACCEPTED' || rowData.status==='PAYMENT_PENDING'? false:true,
             onClick: () => {
              if(settings.AllowCriticalEditsAdmin && (role ==='admin')){
                if(rowData.status==='NEW' || rowData.status==='ACCEPTED'){
                  setSelectedBooking(rowData);
                  setOpenConfirm(true);
                }else{
                  setTimeout(()=>{
                    dispatch(cancelBooking({
                      reason: t('cancelled_incomplete_booking'),
                      booking:rowData
                    }));
                  },1500);
                }
              }else{
                alert(t('demo_mode'));
              }
            }         
          }),
            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                >
                  {t('details')}
                </Button>
              </div>,
              onClick: () => {
                history.push({
                  pathname: '/bookinghistorydetails',
                  state: {
                    detail: rowData
                  }
                });
              }
            }), 
            rowData => ({
              icon: () => <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                >
                  {t('sms')}
                </Button>
              </div>,
              onClick: () => {
                pushSms(rowData)
              }
            }), 
          rowData => ({
            icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
             <div className={classes.submit5}>
                <DeleteIcon icon={DeleteIcon} style={{marginLeft:2,marginTop:5,color:'white', marginBottom: -4}}/>
              </div>
         
          </div>,
          disabled: ((rowData.status === 'CANCELLED' || rowData.status === 'COMPLETE' || rowData.status === 'PAID' ) && role === 'admin') ? false : true,
           onClick: (event, rowData) => {
            settings.AllowCriticalEditsAdmin?
              deleteSingleBooking(rowData)
            :
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                alert(t('demo_mode'));
              }, 600);
            })
          }
        }),
          ]}
        />
        <ConfirmationDialogRaw
          open={openConfirm}
          onClose={onConfirmClose}
          value={''}
        />
        {users && filteruser && data && rowIndex >= 0 ?
          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            onClose={handleClose}
            open={open}
            className={classes.modal}
            container={() => rootRef.current}
          >
            <div className={classes.paper}>
              <Grid container spacing={2} >
                <Grid item xs={12}>
                  <Typography component="h1" variant="h5" className={classes.title} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                    {t('assign')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <UsersCombo
                    className={classes.items}
                    placeholder={t('select_user')}
                    users={filteruser}
                    value={userCombo}
                    onChange={(event, newValue) => {
                      setUserCombo(newValue);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', marginLeft: isRTL === 'rtl' ? '65%' : 0 }}>
                  <Button onClick={handleClose} variant="contained" color="primary">
                    {t('cancel')}
                  </Button>
                  <Button onClick={assignDriver} variant="contained" color="primary" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }}>
                    {t('assign')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>
          : null}

          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={deleteModel}
            className={classes.modal}
          >
            <div className={classes.deletepaper}>
              <Grid container spacing={2} >
                <Grid item xs={12}>
                  <Typography component="h1" variant="h5" className={classes.title} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                    {t('delete_booking_message')}{t('booking')} ?
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', marginLeft: isRTL === 'rtl' ? '65%' : 0 }}>
                  <Button onClick={closeDeleteModel} variant="contained" color="primary">
                    {t('cancel')}
                  </Button>
                  <Button onClick={deleteBookingData} variant="contained" color="primary" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }}>
                    {t('yes')}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>
      </div>

  );
}

export default BookingHistory;
