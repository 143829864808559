import React,{ useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { colors } from '../components/Theme/WebTheme';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 680,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  heading:{
    marginBottom:20
  },
  submit1:{
  backgroundColor:'White',
  color:'#4c63b8'
  }
}));


const DriveHistory = () => {

  const location = useLocation();
  const getId = ({id:location.state.detail, name:location.state.name});

  const { t, i18n  } = useTranslation();
  const isRTL = i18n.dir();
  
  const settings = useSelector(state => state.settingsdata.settings);

  const [data, setData] = useState([]);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const classes = useStyles();
  const columns =  [
    {title: t('no'), render:(rowData)=>rowData.tableData.id+1},
    { title: t('booking_id'), field: 'id'},
    { title: t('date'), field: 'bookingDate',render: rowData => rowData.bookingDate?moment(rowData.bookingDate).format('lll'):null},
    { title: t('pickup_address'), field: 'pickupAddress'},
    { title: t('drop_address'), field: 'dropAddress'},
    { title: t('earnd'), field: 'driver_share'},
    { title: t('booking_status'), field: 'status',render: rowData => <span>{t(rowData.status)}</span>},
];
const history = useHistory();
const goBack=()=>{
  history.goBack();
}
  useEffect(()=>{
        if(bookinglistdata.bookings){
          let arr = [];
          for(let i=0; i<bookinglistdata.bookings.length; i++){
            if(bookinglistdata.bookings[i].driver === getId.id){
              arr.push(bookinglistdata.bookings[i]);
            }
          }
          setData(arr);
        }else{
          setData([]);
        }
  },[getId.id, bookinglistdata.bookings]);

  return (
    bookinglistdata.loading? <CircularLoading/>:
    <div>
        <div className={classes.heading}>
        <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
          {getId.name +' '+ t('drive_history')}
        </Typography>
        <Button
                type="back"
                 width='3%'
                 variant="contained"
                 className={classes.submit1}
                 startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
                 onClick={()=>goBack()}
               >
                  {t('go_back')}
               </Button>

      </div>
    <MaterialTable
      title={t('booking_history')}
      style={{border:'1px solid #808080'}}
      columns={columns}
      data={data}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        sorting: true,
        actionsColumnIndex: -1,
        pageSize:20,
        pageSizeOptions:[20,50,100],
        headerStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          border:'2px solid #808080',
        },
        cellStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          borderRight:'1px solid #808080',
          backgroundColor:'#f5f5f5'
        },
      }}
      localization={{
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('booking_status')) 
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        labelRowsSelect: (t('rows')),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
    }}
    />
    </div>

  );
}

export default DriveHistory;
