import React,{ useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Typography, 
  Modal,
  Grid
} from '@material-ui/core';
import { colors } from '../components/Theme/WebTheme';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  deletepaper: {
    width: 590,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #009CA3',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10
  },
  heading:{
    marginBottom:20
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#de7d1e'
  },
  submit2: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#808080'
  },
  submit1: {
    backgroundColor:'#019b9d',
    marginLeft:30
  },
  submit3:{
    backgroundColor:'#019b9d',
    width:'100%',
    borderRadius:7,
    marginTop:5,
    padding:4
  },
  submit4:{
    backgroundColor:'red',
    width:'100%',
    borderRadius:7,
    marginTop:5,
    padding:4
  }
}));

export default function Users(props) {

  const history = useHistory();


  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { 
    deleteUser,
    fetchUsersOnce
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const auth = useSelector(state => state.auth);
  const activeBookings = useSelector(state => state.bookinglistdata.active);

  const [activeUser, setActiveUser] = useState('');

  useEffect(()=>{
    if(activeBookings){
      let arr = [];
      for(let i = 0; i<data.length; i++){
        for(let j = 0; j<activeBookings.length; j++){
          if(data[i].id === activeBookings[j].customer){
            arr.push(activeBookings[j].customer);
          }
        }
      }
      setActiveUser(arr);
    }else{
      setActiveUser([]);
    };
  },[activeBookings, data]);

  useEffect(()=>{
    dispatch(fetchUsersOnce());
},[dispatch,fetchUsersOnce]);

  // useEffect(()=>{
  //   if(staticusers){
  //     setData(staticusers.filter(user => user.usertype ==='rider'));
  //   }else{
  //     setData([]);
  //   }
  //   loaded.current = true;
  // },[staticusers]);

  useEffect(()=>{
    if(staticusers){
        setData(staticusers.filter(user => user.usertype ==='rider' && ((user.corporateAdminId === auth.info.uid && auth.info.profile.usertype === 'corporateAdmin')|| auth.info.profile.usertype === 'admin')));
    }else{
      setData([]);
    }
    loaded.current = true;
  },[staticusers,auth.info.profile.usertype,auth.info.uid]);
  const classes = useStyles();

  const columns = [
    {title: t('no'), render:(rowData)=>rowData.tableData.id+1},
    { title: t('rider_name'), field: 'firstName' ,render: rowData => <span>{t(rowData.firstName) + " " + t(rowData.lastName)} </span>},
    { title: t('email'), field: 'email', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo"},
    { title: t('mobile'), field: 'mobile', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",},
    { title: t('profile_image'),  field: 'profile_image', render: rowData => rowData.profile_image?<img alt='Profile' src={rowData.profile_image} style={{width: 50,borderRadius:'50%'}}/>:null, editable:'never'},
  ];
  const addNew=()=>{
    history.push({
      pathname: '/addrider'
    });
  }

  const [deleteModel, setDeleteModel] = useState(false);
  const [riderDelete, setRiderDelete] = useState([]);

  const deleteSingleRider=(rowData)=>{
    setDeleteModel(true);
    setRiderDelete(rowData)
  }

  const closeDeleteModel=()=>{
    setDeleteModel(false);
  }

  const deleteRiderData = () => {
    dispatch(deleteUser(riderDelete.id));
    setDeleteModel(false);
    dispatch(fetchUsersOnce());
  }


  return (
    <div>
    <div className={classes.heading}>
    <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
    {t('riders')} <Button
        type="submit"
        variant="contained"
        className={classes.submit1}
        onClick={()=>addNew()}
      >
        {t("add_rider")}
      </Button>
          </Typography>
          </div>
    <MaterialTable
      title={t('riders_list')}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr',border:'1px solid #808080'}}
      data={data}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        sorting: true,
        actionsColumnIndex: -1,
        pageSize:20,
        pageSizeOptions:[20,50,100],
        headerStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          border:'2px solid #808080',
        },
        cellStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          borderRight:'1px solid #808080',
          backgroundColor:'#f5f5f5'
        },
      }}
      localization={{ body:{
        addTooltip: (t('add')),
        deleteTooltip: (t('delete')),
        editTooltip: (t('edit')),
        emptyDataSourceMessage: (
          (t('blank_message'))
      ),
        editRow: { 
          deleteText: (t('delete_message')),
          cancelTooltip: (t('cancel')),
          saveTooltip: (t('save')) 
          }, 
        },
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('actions')) 
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        labelRowsSelect: (t('rows')),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
      }}
      actions={[
        rowData => ({
          icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
            <Button
              type="submit"
              width='5%'
              variant="contained"
              className={classes.submit}
            >
              {t('ride_history')}
            </Button>
          </div>,
          onClick: () => {
            history.push({
              pathname: '/ridehistory',
              state: { 
                detail: rowData.id ,
                name: rowData.firstName 
              }
            });
          }
        }),
        rowData => ({
          icon: () =>
            activeUser.includes(rowData.id) ?
              <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <Button
                  type="submit"
                  width='5%'
                  variant="contained"
                  className={classes.submit}
                >
                  {t('active')}
                </Button>
              </div>
            :
              <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <Button
                  type="submit"
                  width='5%'
                  variant="contained"
                  className={classes.submit2}
                >
                  {t('active')}
                </Button>
              </div>
          }),
          rowData => ({
            icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
              <div  className={classes.submit3}>
              <EditIcon icon={EditIcon} style={{marginLeft:2,marginTop:5,color:'white'}} />
            </div>
            </div>,
            onClick: () => {
              history.push({
                pathname: '/editrider',
                state: { 
                  detail: rowData,
                  name: rowData.firstName 
                }
              });
            }
          }),
          rowData => ({
          icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
           <div className={classes.submit4}>
              <DeleteIcon icon={DeleteIcon} style={{marginLeft:2,marginTop:5,color:'white'}}/>
            </div>
       
        </div>,
         onClick: () => {
          settings.AllowCriticalEditsAdmin ?
            deleteSingleRider(rowData)
            :
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                alert(t('demo_mode'));
              }, 600);
            })
        }
      }),
      ]}
    />
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={deleteModel}
        className={classes.modal}
      >
        <div className={classes.deletepaper}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Typography component="h1" variant="h5" className={classes.title} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                {t('delete_booking_message')}{t('rider')} ?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', marginLeft: isRTL === 'rtl' ? '65%' : 0 }}>
              <Button onClick={closeDeleteModel} variant="contained" color="primary">
                {t('cancel')}
              </Button>
              <Button onClick={deleteRiderData} variant="contained" color="primary" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }}>
                {t('yes')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
