import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
} from '@material-ui/core';
import { colors } from '../components/Theme/WebTheme';
const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  }
}));

export default function DriverEarning() {
  const classes = useStyles();
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
    const columns =  [
        {title: t('no'), render:(rowData)=>rowData.tableData.id+1},
        { title: t('driver_name'), field: 'driverName',},
        { title: t('year'),field: 'year',},
        { title: t('month'), field: 'monthsName',},
        { title: t('vehicle_reg_no'), field: 'driverVehicleNo',},
        { title: t('booking_count'), field: 'total_rides',},
        { title: t('earning_amount'), field: 'driverShare',},
        { title: t('total_gst'), render:(rowData)=> parseFloat(parseFloat(rowData.driverShare) * 10/100).toFixed(settings.decimal)},
    ];

  const [data, setData] = useState([]);
  const driverearningdata = useSelector(state => state.driverearningdata);

  useEffect(()=>{
        if(driverearningdata.driverearnings){
            setData(driverearningdata.driverearnings);
        }
  },[driverearningdata.driverearnings]);

  return (
    <div>
    {/* driverearningdata.loading? <CircularLoading/>: */}
    <div className={classes.heading}>
    <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
    {t('driver_earning')}
          </Typography>
          </div>
    <MaterialTable
      title={t('reports')} 
      columns={columns}
      data={data}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr',border:'1px solid #808080'}}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        grouping: true,
        pageSize:20,
        pageSizeOptions:[20,50,100],
        headerStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          border:'2px solid #808080',
        },
        cellStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          borderRight:'1px solid #808080',
          backgroundColor:'#f5f5f5'
        },
      }}
      localization={{
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        pagination: {
          labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
          labelRowsSelect: (t('rows')),
          firstTooltip: (t('first_page_tooltip')),
          previousTooltip: (t('previous_page_tooltip')),
          nextTooltip: (t('next_page_tooltip')),
          lastTooltip: (t('last_page_tooltip'))
        },
      }}
      
    />
    </div>
  );
}
