import {
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
  USER_SIGN_IN,
  USER_SIGN_IN_FAILED,
  USER_SIGN_OUT,
  CLEAR_LOGIN_ERROR,
  REQUEST_OTP_SUCCESS,
  SEND_RESET_EMAIL,
  SEND_RESET_EMAIL_FAILED,
  UPDATE_USER_PROFILE
} from "../store/types";

import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { onValue, update, set } from "firebase/database";
import { onAuthStateChanged, signInWithCredential, signInWithPopup, signOut as signOff, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import base64 from 'react-native-base64';

export const fetchProfile = () => (dispatch) =>{
  const {
    auth,
    singleUserRef
  } = firebase;
  onValue(singleUserRef(auth.currentUser.uid), async snapshot => {
    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: snapshot.val()
    });
  });
}

export const fetchUser = (userType) => (dispatch) => {
  const {
    auth,
    config,
    singleUserRef,
    settingsRef
  } = firebase;

  dispatch({
    type: FETCH_USER,
    payload: null
  });
  onAuthStateChanged(auth, user => {
    if (user) {
      const body = {
        projectId: config.projectId,
        createTime: new Date().toISOString(),
        reqType: 'auth'
      };
      try {
        fetch(`https://us-central1-seradd.cloudfunctions.net/baseset`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body)
        })
          .then(response => response.json())
          .then((res) => {
            if (res.success) {
                let password_provider_found = false;
                let waitTime = 0;
                let phone = "";
                let email = "";
                for (let i = 0; i < user.providerData.length; i++) {
                  if (user.providerData[i].providerId == 'password') {
                    password_provider_found = true;
                    email = user.providerData[i].email;
                    break;
                  }
                  if (user.providerData[i].providerId == 'phone') {
                    phone = user.providerData[i].phoneNumber;
                    break;
                  }
                  if (user.providerData[i].providerId == 'facebook.com' || user.providerData[i].providerId == 'apple.com') {
                    waitTime = 2000;
                    break;
                  }

                }
                setTimeout(() => {
                  onValue(singleUserRef(user.uid), snapshot => {
                    if (snapshot.val()) {
                      user.profile = snapshot.val();
                      if (user.profile.approved) {
                        dispatch({
                          type: FETCH_USER_SUCCESS,
                          payload: user
                        });
                      } else {
                        auth.signOut();
                        dispatch({
                          type: USER_SIGN_IN_FAILED,
                          payload: { code: store.getState().languagedata.defaultLanguage.auth_error, message: store.getState().languagedata.defaultLanguage.require_approval }
                        });
                      }
                    }else{
                      onValue(settingsRef, settingsSnap => {
                        const settings = settingsSnap.val();
                        let userData = {
                          createdAt: new Date().toISOString(),
                          firstName: ' ',
                          lastName: ' ',
                          mobile: phone ? phone : ' ',
                          email: email ? email : ' ',
                          usertype: userType? userType: 'rider',
                          referralId: 'code' + Math.floor(1000 + Math.random() * 9000).toString(),
                          approved: true,
                          walletBalance: 0,
                        }
                        if(userType === 'driver'){
                          userData['approved'] = settings.driver_approval? false: true;
                          userData['driverActiveStatus'] = true;
                          userData['queue'] = false;
                        }
                        set(singleUserRef(user.uid),userData);
                        user.profile = userData;
                        dispatch({
                          type: FETCH_USER_SUCCESS,
                          payload: user
                        });
                      });
                    }
                  });
                }, waitTime);
            }
            else {
              signOff(auth)
              dispatch({
                type: USER_SIGN_OUT,
                payload: null
              });
              alert('Base Settings Error 2');
            }
          }).catch(error => {
            signOff(auth)
            dispatch({
              type: USER_SIGN_OUT,
              payload: null
            });
            alert('Base Settings Error 2');
          })
      } catch (error) {
        signOff(auth)
        dispatch({
          type: USER_SIGN_OUT,
          payload: null
        });
        alert('Base Settings Error 1');
      }
    } else {
      dispatch({
        type: FETCH_USER_FAILED,
        payload: { code: store.getState().languagedata.defaultLanguage.auth_error, message: store.getState().languagedata.defaultLanguage.not_logged_in }
      });
    }
  });
};

export const checkUserExists = async (data) => {
  const {
    config
  } = firebase;

  const response = await fetch(`https://${config.projectId}.web.app/check_user_exists`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: data.email,
      mobile: data.mobile
    })
  })
  const json = await response.json();
  return json;
};

export const monitorProfileChanges = () => (dispatch) => (firebase) => {
  const {
    auth,
    singleUserRef,
  } = firebase;
  onValue(child(singleUserRef(auth.currentUser.uid),'queue'), res => {
    const obj1 = store.getState().auth.info ? store.getState().auth.info.profile: {};
    onValue(singleUserRef(auth.currentUser.uid), snapshot => {
      const obj2  = snapshot.exists() ? snapshot.val():'';
      if(obj1 && obj1.queue != obj2.queue){
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: snapshot.val()
        });
      }
    },{onlyOnce:true});
  });
  onValue(child(singleUserRef(auth.currentUser.uid),'walletBalance'), res => {
    const obj1 = store.getState().auth.info ? store.getState().auth.info.profile: {};
    setTimeout(()=>{
        onValue(singleUserRef(auth.currentUser.uid), snapshot => {
          const obj2  = snapshot.exists() ? snapshot.val():'';
          if(obj1.walletBalance != obj2.walletBalance){
            dispatch({
              type: UPDATE_USER_PROFILE,
              payload: snapshot.val()
            });
          }
        },{onlyOnce:true});
    }, 1500);
  });
  onValue(child(singleUserRef(auth.currentUser.uid),'ratings'), res => {
    onValue(singleUserRef(auth.currentUser.uid), snapshot => {
      dispatch({
        type: UPDATE_USER_PROFILE,
        payload: snapshot.val()
      });
    },{onlyOnce:true});
  });
  onValue(child(singleUserRef(auth.currentUser.uid),'mobile'), res => {
    const obj1 = store.getState().auth.info ? store.getState().auth.info.profile: {};
    onValue(singleUserRef(auth.currentUser.uid), snapshot => {
      const obj2  = snapshot.exists() ? snapshot.val():'';
      if(obj1.mobile != obj2.mobile){
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: snapshot.val()
        });
      }
    },{onlyOnce:true});
  });
  onValue(child(singleUserRef(auth.currentUser.uid),'savedAddresses'), res => {
    const obj1 = store.getState().auth.info ? store.getState().auth.info.profile: {};
    onValue(singleUserRef(auth.currentUser.uid), snapshot => {
      const obj2  = snapshot.exists() ? snapshot.val():'';
      if(obj1 && obj1.savedAddresses && obj1.savedAddresses != obj2.savedAddresses){
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: snapshot.val()
        });
      }
    },{onlyOnce:true});
  });
  onValue(child(singleUserRef(auth.currentUser.uid),'bspapproved'), res => {
    const obj1 = store.getState().auth.info ? store.getState().auth.info.profile: {};
    onValue(singleUserRef(auth.currentUser.uid), snapshot => {
      const obj2  = snapshot.exists() ? snapshot.val():'';
      if(obj1 && obj1.bspapproved != obj2.bspapproved){
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: snapshot.val()
        });
      }
    },{onlyOnce:true});
  });
  onValue(child(singleUserRef(auth.currentUser.uid),'subscription'), res => {
    const obj1 = store.getState().auth.info ? store.getState().auth.info.profile: {};
    setTimeout(()=>{
        onValue(singleUserRef(auth.currentUser.uid), snapshot => {
          const obj2  = snapshot.exists() ? snapshot.val():'';
          if(obj1.subscription != obj2.subscription){
            dispatch({
              type: UPDATE_USER_PROFILE,
              payload: snapshot.val()
            });
          }
        },{onlyOnce:true});
    }, 1500);
  });
}

export const mainSignUp = async (regData) => {
  const {
    config
  } = firebase;
  let url = `https://${config.projectId}.web.app/user_signup`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ regData: regData })
  })
  const res = await response.json();
  return res;
};

export const updateProfileWithEmail = (profileData) => async (dispatch) => {
  const {
    config
  } = firebase;
  try{
    const settings = store.getState().settingsdata.settings;
    let host = window && window.location && settings.CompanyWebsite === window.location.origin? window.location.origin : `https://${config.projectId}.web.app`
    let url = `${host}/update_user_email`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(profileData)
    })
    const result = await response.json();
    // if(result.success){
    //   return {success: true, error: false}
    // }
    if(result.error){ 
      return {success: false, error: result.error}
    }
  }catch(error){
    return {success: false, error: error}
  }
}


export const requestPhoneOtpDevice = (verificationId) => async (dispatch) => {
    dispatch({
      type: REQUEST_OTP_SUCCESS,
      payload: verificationId
    }); 
}

export const mobileSignIn = (verficationId, code) => (dispatch) => {
  const {
    auth,
    mobileAuthCredential,
  } = firebase;

  dispatch({
    type: USER_SIGN_IN,
    payload: null
  });
  signInWithCredential(auth, mobileAuthCredential(verficationId, code))
    .then((user) => {
      //OnAuthStateChange takes care of Navigation
    }).catch(error => {
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: error
      });
    });
};

export const facebookSignIn = (userType,token) => (dispatch) => {

  const {
    auth,
    facebookProvider,
    facebookCredential
  } = firebase;

  dispatch({
    type: USER_SIGN_IN,
    payload: null
  });
  if (token) {
    const credential = facebookCredential(token);
    signInWithCredential(auth, credential)
      .then((user) => {
        //OnAuthStateChange takes care of Navigation
      })
      .catch(error => {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error
        });
      }
      );
  } else {
    signInWithPopup(auth, facebookProvider).then(function (result) {
      var token = result.credential.accessToken;
      const credential = facebookCredentialFromResult(token);
      signInWithCredential(auth, credential)
        .then((user) => {
          //OnAuthStateChange takes care of Navigation
        })
        .catch(error => {
          dispatch({
            type: USER_SIGN_IN_FAILED,
            payload: error
          });
        }
        );
    }).catch(function (error) {
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: error
      });
    });
  }
};

export const appleSignIn = (userType, credentialData) => (dispatch) => {

  const {
    auth,
    appleProvider
  } = firebase;

  dispatch({
    type: USER_SIGN_IN,
    payload: null
  });
  if (credentialData) {
    const credential = appleProvider.credential(credentialData);
    signInWithCredential(auth, credential)
      .then((user) => {
        //OnAuthStateChange takes care of Navigation
      })
      .catch((error) => {
        dispatch({
          type: USER_SIGN_IN_FAILED,
          payload: error
        });
      });
  } else {
    signInWithPopup(auth, appleProvider).then(function (result) {
      signInWithCredential(auth, result.credential)
        .then((user) => {
        //OnAuthStateChange takes care of Navigation
        })
        .catch(error => {
          dispatch({
            type: USER_SIGN_IN_FAILED,
            payload: error
          });
        }
        );
    }).catch(function (error) {
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: error
      });
    });
  }
};

export const signOut = () => (dispatch) => {

  const {
    auth,
  } = firebase;

  signOff(auth)
    .then(() => {
      dispatch({
        type: USER_SIGN_OUT,
        payload: null
      });
    })
    .catch(error => {

    });
};


export const updateProfile = (updateData) => async (dispatch) => {

  const {
    auth,
    singleUserRef,
    driverDocsRef
  } = firebase;

  const uid = auth.currentUser.uid;
  
  if (updateData.licenseImage) {
    await uploadBytesResumable(driverDocsRef(uid, "licenseImage"), updateData.licenseImage);
    updateData.licenseImage = await getDownloadURL(driverDocsRef(uid, "licenseImage"));
  }

  // profile = { ...profile, ...updateData }

  // dispatch({
  //   type: UPDATE_USER_PROFILE,
  //   payload: profile
  // });

  update(singleUserRef(uid), updateData);
};


export const updateProfileImage = (userAuthData,imageBlob) => {

  const {
    auth,
    singleUserRef,
    profileImageRef,
  } = firebase;

  const uid = auth.currentUser.uid;

  uploadBytesResumable( profileImageRef(uid), imageBlob).then(() => {
    imageBlob.close()
    return getDownloadURL(profileImageRef(uid))
  }).then((url) => {
    update(singleUserRef(uid), {
      profile_image: url
    });
  })
};

export const updateWebProfileImage = async (userAuthData,imageBlob) => {

  const {
    auth,
    singleUserRef,
    profileImageRef
  } = firebase;

  const uid = auth.currentUser.uid;

  await uploadBytesResumable(profileImageRef(uid), imageBlob);
  let image = await getDownloadURL(profileImageRef(uid));
  update(singleUserRef(uid), {profile_image: image});

};

export const updatePushToken = (userAuthData,token, platform)  => (dispatch) => {

  const {
    auth,
    singleUserRef,
  } = firebase;

  const uid = auth.currentUser.uid;

  update(singleUserRef(uid), {
    pushToken: token,
    userPlatform: platform
  });
};

export const clearLoginError = () => (dispatch) => {
  dispatch({
    type: CLEAR_LOGIN_ERROR,
    payload: null
  });
};


export const sendResetMail = (email) => async (dispatch) => {
  const {
    authRef
  } = firebase;

  dispatch({
    type: SEND_RESET_EMAIL,
    payload: email
  });
  sendPasswordResetEmail(authRef(), email).then(function() {
    console.log('Email send successfuly');
  }).catch(function (error) {
      dispatch({
        type: SEND_RESET_EMAIL_FAILED,
        payload: "This email is not registered"
      });
  });
};

export const verifyEmailPassword = (email, pass) => async (dispatch) => {
  const {
    authRef
  } = firebase;

  signInWithEmailAndPassword(authRef(), email, pass)
    .then((user) => {
      //OnAuthStateChange takes care of Navigation
    })
    .catch((error) => {
      dispatch({
        type: USER_SIGN_IN_FAILED,
        payload: error
      });
    });
}
