import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { colors } from '../components/Theme/WebTheme';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import moment from 'moment/min/moment-with-locales';
const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  },
  submit: {
    backgroundColor: colors.Rigzie1,
    color: 'white'
  },
  submit1: {
    backgroundColor: 'White',
    color: '#4c63b8',
    marginBottom:10
 },
}));

export default function EarningReportsDetails() {
    let history = useHistory();
    const location = useLocation();
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const earning = location.state.detail;
  
  const goBack = () => {
    history.goBack();
  }
  const auth = useSelector(state => state.auth);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const [data, setData] = useState([]);
  const [role, setRole] = useState(null);
  const columns = [
    { title: t('no'), render: (rowData) => rowData.tableData.id + 1 },
    { title: t('booking_id'), field: 'id', },
    { title: t('booking_date'), field: 'tripdate', render: rowData => rowData.tripdate ? moment(rowData.tripdate).format('lll') : null, },
    { title: t('customer_name'), field: 'customer_name' },
    { title: t('assign_driver'), field: 'driver_name', render: rowData => rowData.driver_name ? rowData.driver_name : "-"}, 
    { title: t('offLoader_name'), field: 'offLoaderName', render: rowData => rowData.offLoaderName ? rowData.offLoaderName : "-"}, 
    { title: t('offLoader_fee'), field: 'offLoaderFee', render: rowData => rowData.offLoaderFee ? rowData.offLoaderFee  : "-"}, 
    { title: t('booking_status'), field: 'status', render: rowData => rowData.status ? rowData.status  : "-"}, 
    { title: t('trip_cost'), field: 'trip_cost'},
    { title: t('convenience_fee'), hidden: role==='admin'? false : true, field: 'convenience_fees'},
    { title: t('corporateAdmin_share'), hidden: role==='corporateAdmin'? false : true, field: 'corporateAdmin_share'},
    // { title: t('total_gst'), render: rowData => parseFloat((parseFloat(rowData.convenienceFee) + parseFloat(rowData.cancellationFee) - parseFloat(rowData.discountAmount))* 10/100).toFixed(settings.decimal), editable:'never'},
  ]

  useEffect(()=>{
    if(bookinglistdata.bookings){
        setData(bookinglistdata.bookings);
    }else{
      setData([]);
    }
  },[bookinglistdata.bookings]);

  const [newData, setNewData] = useState(null);

  useEffect(()=>{
    if(data){
      let arr = [];
      var monthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      for(let i=0; i<data.length; i++){
        let bdt = new Date(data[i].tripdate);
        if(monthsName[bdt.getMonth()] === earning.monthsName && bdt.getFullYear() === earning.year){
          arr.push(data[i]);
        }
      }
      setNewData(arr);
    }
  },[data, earning.monthsName, earning.year]);

useEffect(() => {
if(auth.info && auth.info.profile){
  setRole(auth.info.profile.usertype);
}
}, [auth.info]);

  return (
    // earningreportsdata.loading? <CircularLoading/>:
    <div>
    <div className={classes.heading}>
    <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
    {t('earning_reports_details')}
          </Typography>
          </div>
          <Button
          type="back"
          width='3%'
          variant="contained"
          className={classes.submit1}
          startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
          onClick={() => goBack()}
        >
          {t('go_back')}
        </Button>
    <MaterialTable
      title={t('reports')}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr',border:'1px solid #808080'}}
      columns={columns}
      data={newData}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        pageSize:20,
        pageSizeOptions:[20,50,100],
        headerStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          border:'2px solid #808080',
        },
        cellStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          borderRight:'1px solid #808080',
          backgroundColor:'#f5f5f5'
        },
      }}
      localization={{
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        pagination: {
          labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
          labelRowsSelect: (t('rows')),
          firstTooltip: (t('first_page_tooltip')),
          previousTooltip: (t('previous_page_tooltip')),
          nextTooltip: (t('next_page_tooltip')),
          lastTooltip: (t('last_page_tooltip'))
        },
      }}
     
    />
    </div>
  );
}