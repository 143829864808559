import {
    FETCH_SUBSCRIPTION,
    FETCH_SUBSCRIPTION_SUCCESS,
    FETCH_SUBSCRIPTION_FAILED,
    EDIT_SUBSCRIPTION
  } from "../store/types";

import { firebase } from '../config/configureFirebase';
import { onValue, set, push, remove } from "firebase/database";
  
  export const fetchSubscription = () => (dispatch) => {
  
    const {
      subscriptionRef
    } = firebase;
  
    dispatch({
      type: FETCH_SUBSCRIPTION,
      payload: null
    });
    onValue(subscriptionRef, snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_SUBSCRIPTION_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_SUBSCRIPTION_FAILED,
          payload: "No subscriptions available."
        });
      }
    });
  };
  
  export const editSubscription = (value, method) => (dispatch) => {
    const {
      subscriptionRef, 
      subscriptionEditRef
    } = firebase;
    dispatch({
      type: EDIT_SUBSCRIPTION,
      payload: { method, value }
    });
    if (method === 'Add') {
      push(subscriptionRef,value);
    } else if (method === 'Delete') {
      remove(subscriptionEditRef(value.id));
    } else {
      set(subscriptionEditRef(value.id),value);
    }
  }