import React, { useState } from 'react';
import {
  Typography,
  ListItemIcon,
  MenuList,
  MenuItem
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CarIcon from '@material-ui/icons/DirectionsCar';
import OfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NotifyIcon from '@material-ui/icons/NotificationsActive';
import { colors } from '../components/Theme/WebTheme';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from "react-i18next";
import SettingsIcon from '@material-ui/icons/Settings';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import MoneyIcon from '@material-ui/icons/Money';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import ViewListIcon from '@material-ui/icons/ViewList';
import AirlineSeatReclineNormalIcon from '@material-ui/icons/AirlineSeatReclineNormal';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ReportIcon from '@material-ui/icons/Report';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
function AppMenu() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const auth = useSelector(state => state.auth);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: colors.rigziedrawer,
    },
    nested: {
      paddingRight: theme.spacing(4),
    },
    appMenu: {
      backgroundColor: colors.rigziedrawer,
      marginTop: barhight,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${barhight}px)`,
      },
      display: 'flex',
      marginBottom: 70
    },
    menu: {
      color: 'white'
    }
  }));
  const barhight = 0;
  const classes = useStyles();

  const [menuActive, setMenuActive] = useState([false, false, false, false]);

  const handleClick = (index) => {
    let temp = menuActive;
    temp[index] = !menuActive[index];
    setMenuActive(temp);
  };
  const arrowLeft = {
    position: 'absolute',
    left: 0
  };
  const arrowRight = {
    position: 'absolute',
    right: 0
  };

  let isAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'admin';
  let isCorporateAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'corporateAdmin';
  let isFleetAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'fleetadmin';
  return (
    <div className={classes.appMenu}>
      <MenuList>
        <MenuItem component={Link} to="/">
          <ListItemIcon>
            <HomeIcon className={classes.menu} />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.menu}>{t('home')}</Typography>
        </MenuItem>
        {isAdmin ?
          <MenuItem component={Link} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon className={classes.menu} />
            </ListItemIcon>
            <Typography variant="inherit" className={classes.menu}>{t('dashboard_text')}</Typography>
          </MenuItem>
          : null}
        <MenuItem component={Link} to="/bookings">
          <ListItemIcon>
            <ViewListIcon className={classes.menu} />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.menu}>{t('booking_history')}</Typography>
        </MenuItem>
        {isAdmin || isFleetAdmin || isCorporateAdmin ?
          <MenuItem component={Link} to="/addbookings">
            <ListItemIcon>
              <ContactPhoneIcon className={classes.menu} />
            </ListItemIcon>
            <Typography variant="inherit" className={classes.menu}>{t('addbookinglable')}</Typography>
          </MenuItem>
          : null}
        {isCorporateAdmin ?
          <ListItem button className={classes.nested} component={Link} to="/corporateearning">
            <ListItemIcon>
              <AccountBalanceIcon className={classes.menu} />
            </ListItemIcon>
            <Typography variant="inherit" className={classes.menu}>{t('earning_reports')}</Typography>
          </ListItem>
        : null}
        {isFleetAdmin ?
          <MenuItem component={Link} to="/drivers">
            <ListItemIcon>
              <AirlineSeatReclineNormalIcon className={classes.menu} />
            </ListItemIcon>
            <Typography variant="inherit" className={classes.menu}>{t('drivers')}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem button onClick={() => handleClick(1)} component={Link} to="/riders">
            <ListItemIcon>
              <PeopleIcon className={classes.menu} />
            </ListItemIcon>
            <Typography className={classes.menu}>{t('users_title')}</Typography>
            {menuActive[1] ?
              <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                <KeyboardArrowDownIcon style={{ direction: 'rtl' }} className={classes.menu} />
              </ListItemIcon>
              :
              <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                <KeyboardArrowLeftIcon className={classes.menu} />
              </ListItemIcon>
            }
          </MenuItem>
          : null}
        <Collapse in={menuActive[1]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/riders">
                <ListItemIcon>
                  <EmojiPeopleIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('riders')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/drivers">
                <ListItemIcon>
                  <AirlineSeatReclineNormalIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('drivers')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/fleetadmins">
                <ListItemIcon>
                  < GroupAddIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('fleetadmins')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/corporateAdmin">
                <ListItemIcon>
                  < GroupAddIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('corporateAdmin')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/alladmin">
                <ListItemIcon>
                  < SupervisorAccountIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('alladmin')}</Typography>
              </ListItem>
              : null}

          </List>
        </Collapse>

        {isAdmin ?
          <MenuItem component={Link} to="/cartypes">
            <ListItemIcon>
              <CarIcon className={classes.menu} />
            </ListItemIcon>
            <Typography variant="inherit" className={classes.menu}>{t('car_type')}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/cancelreasons">
            <ListItemIcon>
              <CancelScheduleSendIcon className={classes.menu} />
            </ListItemIcon>
            <Typography variant="inherit" className={classes.menu}>{t('cancellation_reasons')}</Typography>
          </MenuItem>
          : null}
        {isFleetAdmin ?
          <MenuItem component={Link} to="/driverearning">
            <ListItemIcon>
              <MoneyIcon className={classes.menu} />
            </ListItemIcon>
            <Typography variant="inherit" className={classes.menu}>{t('driver_earning')}</Typography>
          </MenuItem>
          : null}
        {isFleetAdmin || isAdmin ?
          <MenuItem component={Link} to="/fleetEarning">
            <ListItemIcon>
              <MoneyIcon className={classes.menu} />
            </ListItemIcon>
            <Typography variant="inherit" className={classes.menu}>{t('fleet_earning_reports')}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem button onClick={() => handleClick(2)} component={Link} to="/earningreports">
            <ListItemIcon>
              <AccountBalanceIcon className={classes.menu} />
            </ListItemIcon>
            <Typography className={classes.menu}>{t('wallet_title')}</Typography>
            {menuActive[2] ?
              <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                <KeyboardArrowDownIcon className={classes.menu} />
              </ListItemIcon>
              :
              <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                <KeyboardArrowLeftIcon className={classes.menu} />
              </ListItemIcon>
            }
          </MenuItem>
          : null}
        <Collapse in={menuActive[2]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/earningreports">
                <ListItemIcon>
                  <AssessmentIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('earning_reports')}</Typography>
              </ListItem>
              : null}
              {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/adminReport">
                <ListItemIcon>
                  <AssessmentIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('admim_report_details')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/subscriptionreport">
                <ListItemIcon>
                  <LowPriorityIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('subscription_reports')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/driverearning">
                <ListItemIcon>
                  <AccountBalanceIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('driver_earning')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/addtowallet">
                <ListItemIcon>
                  <AccountBalanceWalletIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('add_to_wallet_tittle')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/withdraws">
                <ListItemIcon>
                  <MoneyIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('pending_withdraws')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/withdrawsReports">
                <ListItemIcon>
                  <MoneyIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('withdraws_reports')}</Typography>
              </ListItem>
              : null}
          </List>
        </Collapse>

        {isAdmin ?
          <MenuItem component={Link} to="/promos">
            <ListItemIcon>
              <OfferIcon className={classes.menu} />
            </ListItemIcon>
            <Typography variant="inherit" className={classes.menu}>{t('promo')}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/subscription">
            <ListItemIcon>
              <CardMembershipIcon className={classes.menu} />
            </ListItemIcon>
            <Typography variant="inherit" className={classes.menu}>{t('subscription')}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/notifications">
            <ListItemIcon>
              <NotifyIcon className={classes.menu} />
            </ListItemIcon>
            <Typography variant="inherit" className={classes.menu}>{t('push_notification_title')}</Typography>
          </MenuItem>
          : null}
        {isAdmin ?
          <MenuItem component={Link} to="/reportdetails">
            <ListItemIcon>
              <ReportIcon className={classes.menu} />
            </ListItemIcon>
            <Typography variant="inherit" className={classes.menu}>{t('report_istory')}</Typography>
          </MenuItem>
          : null}

        {isAdmin ?
          <MenuItem button onClick={() => handleClick(3)} component={Link} to="/appinfo">
            <ListItemIcon>
              <SettingsIcon className={classes.menu} />
            </ListItemIcon>
            <Typography className={classes.menu}>{t('settings_title')}</Typography>
            {menuActive[3] ?
              <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                <KeyboardArrowDownIcon className={classes.menu} />
              </ListItemIcon>
              :
              <ListItemIcon style={isRTL === 'rtl' ? arrowLeft : arrowRight}>
                <KeyboardArrowLeftIcon className={classes.menu} />
              </ListItemIcon>
            }
          </MenuItem>
          : null}
        <Collapse in={menuActive[3]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/appinfo">
                <ListItemIcon>
                  <PhoneIphoneIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('app_info')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/settings">
                <ListItemIcon>
                  <SettingsApplicationsIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('general_settings')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/languagesetting">
                <ListItemIcon>
                  <LanguageIcon className={classes.menu} />
                </ListItemIcon>
                <Typography variant="inherit" className={classes.menu}>{t('language')}</Typography>
              </ListItem>
              : null}
          </List>
        </Collapse>
      </MenuList>
    </div>
  );
}

export default AppMenu;