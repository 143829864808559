import {
  FETCH_WITHDRAWS,
  FETCH_WITHDRAWS_SUCCESS,
  FETCH_WITHDRAWS_FAILED,
  EDIT_WITHDRAWS,
  FETCH_WITHDRAWSREPORT,
  FETCH_WITHDRAWSREPORT_SUCCESS,
  FETCH_WITHDRAWSREPORT_FAILED,
  UPDATE_WALLET_BALANCE,
  UPDATE_WALLET_BALANCE_SUCCESS,
  UPDATE_WALLET_BALANCE_FAILED
} from "../store/types";
import { firebase } from '../config/configureFirebase';
import { onValue, child, set, push, update } from "firebase/database";
import { RequestPushMsg } from '../other/NotificationFunctions';
import store from '../store/store';
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const fetchWithdraws = () => (dispatch) => {

  const {
    withdrawRef
  } = firebase;

  dispatch({
    type: FETCH_WITHDRAWS,
    payload: null
  });
 onValue(withdrawRef, snapshot => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const arr = Object.keys(data).map(i => {
        data[i].id = i
        return data[i]
      });
      dispatch({
        type: FETCH_WITHDRAWS_SUCCESS,
        payload: arr.reverse()
      });
    } else {
      dispatch({
        type: FETCH_WITHDRAWS_FAILED,
        payload: "No WITHDRAWS available."
      });
    }
  });
};

export const fetchMonthlyWithdraws = () => async (dispatch) => {

  const {
    withdrawRef
  } = firebase;

  dispatch({
    type: FETCH_WITHDRAWSREPORT,
    payload: null
  });

  const settings = store.getState().settingsdata.settings;


  onValue(withdrawRef, snapshot => {
    if (snapshot.val()) {
      const mainArr = snapshot.val();
      var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
      var monthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      var renderobj = {};
      Object.keys(mainArr).map(j => {
        if (mainArr[j].processed == true) {
          let bdt = new Date(mainArr[j].procesDate);
          var elm = document.createElement('input');
          elm.type = 'week';
          elm.valueAsDate = bdt;
          var week = elm.value.split('W').pop();
          let uniqueKey = week;
          if (renderobj[uniqueKey]) {
            renderobj[uniqueKey].totalAmount = (parseFloat(renderobj[uniqueKey].totalAmount) + parseFloat(mainArr[j].amount)).toFixed(settings.decimal);
            renderobj[uniqueKey]['total_withdraws'] = renderobj[uniqueKey]['total_withdraws'] + 1;
          } else {
            renderobj[uniqueKey] = {};
            renderobj[uniqueKey]['dated'] = mainArr[j].procesDate;
            renderobj[uniqueKey]['year'] = bdt.getFullYear();
            renderobj[uniqueKey]['month'] = bdt.getMonth();
            renderobj[uniqueKey]['dated'] = mainArr[j].date;
            renderobj[uniqueKey]['monthsName'] = monthsName[bdt.getMonth()];
            renderobj[uniqueKey]['dayName'] = days[bdt.getDay()];
            renderobj[uniqueKey]['weekNumber'] = week;
            renderobj[uniqueKey]['uniqueKey'] = uniqueKey;
            renderobj[uniqueKey]['total_withdraws'] = 1;
            renderobj[uniqueKey]['totalAmount'] = parseFloat(mainArr[j].amount).toFixed(settings.decimal);
          }
        }
        return null;
      });
      if (renderobj) {
        const arr = Object.keys(renderobj).map(i => {
          renderobj[i].totalAmount = parseFloat(renderobj[i].totalAmount).toFixed(settings.decimal);
          return renderobj[i]
        })
        dispatch({
          type: FETCH_WITHDRAWSREPORT_SUCCESS,
          payload: arr
        });
      }
    } else {
      dispatch({
        type: FETCH_WITHDRAWSREPORT_FAILED,
        payload: "No WITHDRAWS available."
      });
    }
  });
};

export const completeWithdraw = (entry) => async (dispatch) =>  {

  const {
    withdrawRef,
    withdrawDocsRef,
    singleUserRef,
    walletBalRef,
    walletHistoryRef,
    adminReportRef
  } = firebase;
  if(entry.withdraImage){

    await uploadBytesResumable(withdrawDocsRef(entry.id), entry.withdraImage).then(() => {
      return getDownloadURL(withdrawDocsRef(entry.id));
    }).then((url) => {
      let item = entry;
      delete item.withdraImage;
      update(child(withdrawRef,entry.id),{...item, procesDate: new Date().toString(), processed: true, receiptImage: url});

      onValue(adminReportRef, snapshot => {
        let allDetails = snapshot.val();
        let lastAmount = 0;
        if(allDetails && Object.keys(allDetails).length > 0){
          var keys = Object.values(allDetails);
          var last = keys[keys.length-1];
          lastAmount = parseFloat(last.updateBalance);
        }  
        push(adminReportRef,{
          date: new Date().toString(),
          uid: item.uid,
          adminAmount: item.usertype === 'admin' ? parseFloat(item.amount):0,
          driverAmount: item.usertype === 'driver' ? parseFloat(item.amount):0,
          fleetAmount: item.usertype === 'fleetadmin' ? parseFloat(item.amount):0,
          corporateAmount: item.usertype === 'corporateAdmin' ? parseFloat(item.amount):0,
          type:'debit',
          craditAmount: 0,
          debitAmount: parseFloat(item.amount),
          updateBalance: parseFloat(lastAmount - parseFloat(item.amount))
        });
      },{onlyOnce: true});

      onValue(singleUserRef(entry.uid), snapshot => {
        if (snapshot.val()) {
          const setData = snapshot.val();
          let oldBalance = parseFloat(setData.walletBalance);
          let updateBalance = parseFloat(oldBalance - parseFloat(entry.amount));
          set(walletBalRef(entry.uid),updateBalance).then(() => {
            push(walletHistoryRef(entry.uid),details).then(()=>{
              dispatch({
                  type: UPDATE_WALLET_BALANCE_SUCCESS,
                  payload: null
              });
          }).catch(error=>{
              dispatch({
                  type: UPDATE_WALLET_BALANCE_FAILED,
                  payload: error.code + ": " + error.message,
              });            
          })
          if(setData.pushToken){
            RequestPushMsg(
              setData.pushToken,
              {
                  title: store.getState().languagedata.defaultLanguage.notification_title,
                  msg:store.getState().languagedata.defaultLanguage.wallet_updated,
                  screen: 'Wallet'
            })(firebase);
          }
          }).catch(error=>{
            dispatch({
                type: UPDATE_WALLET_BALANCE_FAILED,
                payload: error.code + ": " + error.message,
            });
          });
        }
      }, {onlyOnce: true});
      dispatch({
        type: EDIT_WITHDRAWS,
        payload: entry
      });
    })
  }
}

export const clearAllUserWithdraw = () => async (dispatch) => {

  const {
    usersRef,
    withdrawRef
  } = firebase;

  dispatch({
    type: UPDATE_WALLET_BALANCE,
    payload: null
  });

  onValue(usersRef, snapshot => {
    if (snapshot.val()) {
      const data = snapshot.val();
       Object.keys(data)
      .filter(i => data[i].usertype=='driver' && parseFloat(data[i].walletBalance)>0)
      .map(i => {
        data[i].id = i;
        let tDate = new Date();
        let details = {
          type: 'Withdraw',
          amount: parseFloat(data[i].walletBalance),
          date: tDate.toString(),
          txRef: 'WithdrawByAdmin',
          transaction_id: tDate.getTime().toString()
        }
        push(withdrawRef,{
          uid : data[i].id,
          name : data[i].firstName +  ' ' + data[i].lastName,
          amount : parseFloat(details.amount),
          date : details.date,
          bankName : data[i].bankName? data[i].bankName : '',
          bankCode : data[i].bankCode? data[i].bankCode : '',
          bankAccount : data[i].bankAccount? data[i].bankAccount : '',
          processed: false, 
        });
    });
    }
  },{onlyOnce: true});
}

export const editWithdraw = (itemData) => async (dispatch) => {
  const {
    withdrawDocsRef,
    withdrawRef
  } = firebase;

  if(itemData.withdraImage){

    await uploadBytesResumable(withdrawDocsRef(itemData.id), itemData.withdraImage).then(() => {
      return getDownloadURL(withdrawDocsRef(itemData.id));
    }).then((url) => {
      let item = itemData;
      delete item.withdraImage;
      set(child(withdrawRef,itemData.id),{...item, receiptImage:url});
    })
  };
  
  dispatch({
    type: EDIT_WITHDRAWS,
    payload: itemData
  });
}