import React,{ useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import {
  Typography,
} from '@material-ui/core';
import { colors } from '../components/Theme/WebTheme';
const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  }
}));

const ReportDetails = (props) => {
  const { t, i18n  } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const classes = useStyles();
  const columns =  [
    {title: t('no'), render:(rowData)=>rowData.tableData.id+1},
    { title: t('booking_id'), field: 'id'},
    { title: t('booking_date'), field: 'tripdate', render: rowData => rowData.tripdate?moment(rowData.tripdate).format('lll'):null},
    { title: t('customer_name'),field: 'customer_name'},
    { title: t('assign_driver'), field: 'driver_name'},
    { title: t('vehicle_no'), field: 'vehicle_number'},
    { title: t('title'), field: 'report.heading'},
    { title: t('description'), field: 'report.description'}
  ];

  useEffect(()=>{
        if(bookinglistdata.bookings){
            let arr = [];
            for(let i = 0; i<bookinglistdata.bookings.length; i++ ){
                if(bookinglistdata.bookings[i].report && bookinglistdata.bookings[i].report != null){
                    arr.push(bookinglistdata.bookings[i]);
                }
            }
            setData(arr);
        }else{
          setData([]);
        }
  },[bookinglistdata.bookings]);

  return (
    bookinglistdata.loading? <CircularLoading/>:
    <div>
      <div className={classes.heading}>
        <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
          {t('report_istory')}
        </Typography>
      </div>
      <MaterialTable
        title={t('reports')}
        style={{border:'1px solid #808080'}}
        columns={columns}
        data={data}
        options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
            pageSize:20,
            pageSizeOptions:[20,50,100],
            headerStyle:{
              textAlign:isRTL=== 'rtl' ?'right':'center',
              border:'2px solid #808080',
            },
            cellStyle:{
              textAlign:isRTL=== 'rtl' ?'right':'center',
              borderRight:'1px solid #808080',
              backgroundColor:'#f5f5f5'
            },
        }}
        localization={{
            toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
            },
            header: {
            actions: (t('actions')) 
        },
        pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            labelRowsSelect: (t('rows')),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
        },
        }}
      />
    </div>
  );
}

export default ReportDetails;
