import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../components/Theme/WebTheme';
import Button from "components/CustomButtons/Button.js";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  },
  submit:{
    backgroundColor:'#379ab2',
    width:'100%'
  },
  input:{
    width:'100%',
    marginTop:15
  },
  submit1:{
    backgroundColor:'White',
    color:'#4c63b8'
  },
  notchedOutline:{
    borderColor:colors.Rigzie1,
    width:'100%',
  }
}));

export default function AddSubscription() {

const [data, setData] = useState({
        subscription_description: '',
        subscription_value: '',
        subscription_name: '',
        subscription_days: ''
});


const { t,i18n } = useTranslation();
const isRTL = i18n.dir();
const classes = useStyles();
const {
    editSubscription
} = api;
const history = useHistory();
const dispatch = useDispatch();

const handleTextChange = (e) => {
  setData({ ...data, [e.target.name]: e.target.value });
};

const goBack=()=>{
  history.goBack();
}

const submitValue = () => {
    if( data.subscription_name  && data.subscription_value && data.subscription_description && data.subscription_days ){
      let arr = {
        id:'',
        subscription_description: data.subscription_description,
        subscription_value: data.subscription_value,
        subscription_name: data.subscription_name,
        subscription_days: data.subscription_days
        };
        dispatch(editSubscription(arr, 'Add'));
        setTimeout(() => {
          alert('New Subscription Successfuly Add.');
          setData({
              subscription_description: '',
              subscription_value: '',
              subscription_name: '',
              subscription_days: ''
          });
        }, 300);
    } else {
      alert('Please Fill add field');
    }
  }

  return (
    // data.loading ? <CircularLoading /> :
    <div>
      <div className={classes.heading}>
        <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
          {'Add New Subscription'}
        </Typography>
      </div>
      <Button
        type="back"
        width='3%'
        variant="contained"
        className={classes.submit1}
        startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
        onClick={()=>goBack()}
      >
        {t('go_back')}
      </Button>
      <Box boxShadow={6} m={2} style={{ borderRadius: 20, border: '1px solid #109496', width: '35%', height: '90%', overflow: 'hidden' }}>
        <Box boxShadow={6} p={2} style={{border: '1px solid #109496', width: '100%', height: '90%', backgroundColor: colors.Rigzie1 }}>
          <Typography component="h1" variant="h5" className={classes.title} style={{ textAlign: 'center', color: 'white', fontWeight: 'bold', }}>
              {t('add_new')}
          </Typography>
        </Box>
        <CardContent>

          <Grid container style={{direction:isRTL ==='rtl'?'rtl':'ltr'}} >
            <Grid item xs={12}>
              <TextField
                className={isRTL ==="rtl"? classes.rootRtl:null}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                margin="normal"
                required
                fullWidth
                id="subscription_name"
                label={t('subscription_name')}
                name="subscription_name"
                autoComplete="subscription_name"
                onChange={handleTextChange}
                defaultValue={data.subscription_name}
              />
              <TextField
                className={isRTL ==="rtl"? classes.rootRtl:null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="subscription_description"
                label={t('description')}
                name="subscription_description"
                autoComplete="subscription_description"
                onChange={handleTextChange}
                defaultValue={data.subscription_description}
              />
              <TextField
                type="number"
                className={isRTL ==="rtl"? classes.rootRtl:null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="subscription_value"
                label={t('subscription_value')}
                name="subscription_value"
                autoComplete="subscription_value"
                onChange={handleTextChange}
                defaultValue={data.subscription_value}
              />
              <TextField
                type="number"
                className={isRTL ==="rtl"? classes.rootRtl:null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="subscription_days"
                label={t('subscription_days')}
                name="subscription_days"
                autoComplete="subscription_days"
                onChange={handleTextChange}
                defaultValue={data.subscription_days}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Button
                type="submit"
                variant="contained"
                className={classes.submit}
                onClick = {submitValue}
              >
                {t('submit')}
              </Button>
            </Grid>
          </Grid>
        {/* <AlertDialog open={settingsdata.error.flag && clicked} onClose={handleClose}>{t('update_failed')}</AlertDialog> */}
        </CardContent>
      </Box>
    </div>
  );
}