import {
    FETCH_ADMIN_REPORT,
    FETCH_ADMIN_REPORT_SUCCESS,
    FETCH_ADMIN_REPORT_FAILED,
    EDIT_ADMIN_REPORT
} from "../store/types";
  
  export const INITIAL_STATE = {
    reports:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export const adminreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_ADMIN_REPORT:
        return {
          ...state,
          loading:true
        };
      case FETCH_ADMIN_REPORT_SUCCESS:
        return {
          ...state,
          reports:action.payload,
          loading:false
        };
      case FETCH_ADMIN_REPORT_FAILED:
        return {
          ...state,
          reports:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      case EDIT_ADMIN_REPORT:
        return state;
      default:
        return state;
    }
  };