import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import { colors } from '../components/Theme/WebTheme';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  Typography,
  MenuItem,
  Select, 
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  },
  submit:{
    backgroundColor:'#379ab2',
    width:'100%'
  },
  submit1:{
    backgroundColor:'White',
    color:'#4c63b8'
  },
  notchedOutline:{
    borderColor:colors.Rigzie1,
    width:'100%',
  },
  carphotoRtl:{
    height: '16px',
    marginLeft:'10px'
  },
  carphoto: {
    height: '18px',
    marginRight: '10px'
  },
  input:{
    width:'100%',
    borderColor:colors.Rigzie1,
  }
}));

export default function AddCar() {

const [data, setData] = useState({
  name: '',
  image: '',
  base_fare: '',
  rate_per_unit_distance: '',
  rate_per_hour: '',
  convenience_fees : '',
  convenience_fee_type : '',
  extra_info : '',
  min_fare : '',
  bsp_percentage : '',
  bsp_percentage_type : '' ,
});


const { t,i18n } = useTranslation();
const isRTL = i18n.dir();
const classes = useStyles();
const {
  editCarType
} = api;
const history = useHistory();
const dispatch = useDispatch();
const [conveniencefeetype, setconveniencefeetype] = useState('Select Type');

const handleconveniencefeetype = (event) => {
  setconveniencefeetype(event.target.value); 
}
const handleTextChange = (e) => {
  setData({ ...data, [e.target.name]: e.target.value });
};

const goBack=()=>{
  history.goBack();
}

const [selectedImage, setSelectedImage] = useState(null);

const submitValue = () => {
  if(selectedImage){
    if(data.name.length > 0 && data.capacity.length > 0 && data.base_fare.length > 0 && data.rate_per_unit_distance.length > 0 && data.rate_per_hour.length > 0
      && data.min_fare.length > 0 && data.convenience_fees.length > 0 && conveniencefeetype !== 'Select Type' && data.extra_info.length > 0){
      let arr = {
        name: data.name,
        capacity: parseFloat(data.capacity),
        image: selectedImage,
        base_fare: parseFloat(data.base_fare),
        rate_per_unit_distance: parseFloat(data.rate_per_unit_distance),
        rate_per_hour: parseFloat(data.rate_per_hour),
        convenience_fees : parseFloat(data.convenience_fees),
        convenience_fee_type : conveniencefeetype,
        extra_info : data.extra_info,
        min_fare : parseFloat(data.min_fare),
        bsp_percentage : 0,
        bsp_percentage_type : 'flat' ,
      };
      dispatch(editCarType(arr, "Add"));
      alert('Car Successfully Add');
    }else{
      alert('Fill All Data Properly');
    }
  }
  else {
    alert('Please add Car image');
  }
}

  return (
    // data.loading ? <CircularLoading /> :
    <div>
    <div className={classes.heading}>
    <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
    {t('add_new_car')}
          </Typography>
          </div>
          <Button
                type="back"
                 width='3%'
                 variant="contained"
                 className={classes.submit1}
                 startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
                 onClick={()=>goBack()}
               >
                  {t('go_back')}
               </Button>

               <Box boxShadow={6}
       
       m={2}
       p={2} style={{borderRadius:20,border: '2px solid #109496',width:'80%',height:'90%'}}>
     <CardContent>
      <Grid container style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}  >
          <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: 20}}> 
         
          <Typography component="h1" variant="h6">
              {t('car_name')}
            </Typography>
            <TextField
            className={isRTL ==="rtl"? classes.rootRtl:null}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              margin="normal"
              required
              fullWidth
              id="name"
               label={t('car_name')}
               name="name"
              autoComplete="name"
              onChange={handleTextChange}
              defaultValue={data.name}
            />

          <Typography component="h1" variant="h6">
              {t('car_capacity')}
            </Typography>
            <TextField
              type="number"
              className={isRTL ==="rtl"? classes.rootRtl:null}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              margin="normal"
              required
              fullWidth
              id="capacity"
              label={t('car_capacity')}
              name="capacity"
              autoComplete="capacity"
              onChange={handleTextChange}
              defaultValue={data.capacity}
            />
   
      
        <Typography component="h1" variant="h6">
              {t('base_fare')}
            </Typography>
             <TextField
              type="number"
              className={isRTL ==="rtl"? classes.rootRtl:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="base_fare"
              label={t('base_fare')}
              name="base_fare"
              autoComplete="base_fare"
              onChange={handleTextChange}
              defaultValue={data.base_fare}
            />
     
     
            <Typography component="h1" variant="h6">
              {t('rate_per_unit_distance')}
            </Typography>
            <TextField
              type="number"
              className={isRTL ==="rtl"? classes.rootRtl:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="rate_per_unit_distance"
              label={t('rate_per_unit_distance')}
              name="rate_per_unit_distance"
              autoComplete="rate_per_unit_distance"
              onChange={handleTextChange}
              defaultValue={data.rate_per_unit_distance}
            />
              <Typography component="h1" variant="h6">
              {t('rate_per_hour')}
            </Typography>
            <TextField
              type="number"
              className={isRTL ==="rtl"? classes.rootRtl:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="rate_per_hour"
              label= {t('rate_per_hour')}
              name="rate_per_hour"
              autoComplete="rate_per_hour"
              onChange={handleTextChange}
              defaultValue={data.rate_per_hour}
            />
             <Typography component="h1" variant="h6">
              {t('min_fare')}
            </Typography>
            <TextField
              type="number"
              className={isRTL ==="rtl"? classes.rootRtl:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="min_fare"
              label= {t('min_fare')}
              name="min_fare"
              autoComplete="min_fare"
              onChange={handleTextChange}
              defaultValue={data.min_fare}
            />
           
   </Grid>
    <Grid item xs={12} sm={12} md={6} lg={6} style={{padding: 20}}>
    <Typography component="h1" variant="h6">
              {t('convenience_fee')}
            </Typography>
            <TextField
              type="number"
              className={isRTL ==="rtl"? classes.rootRtl_1:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              id="convenience_fees"
              label= {t('convenience_fee')}
              name="convenience_fees"
              autoComplete="convenience_fees"
              onChange={handleTextChange}
              defaultValue={data.convenience_fees}
            />
            <Typography component="h1" variant="h6" style={{marginBottom: 15}}>
              {t('convenience_fee_type')}
            </Typography>
             <Select
              id="convenience_fee_type"
              value={conveniencefeetype}
              onChange={handleconveniencefeetype}
              className={classes.input}
              style={{textAlign:isRTL==='rtl'? 'right':'left'}}
              variant="outlined"
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem  key={'Select Type'} value={'Select Type'} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                {'Select Type'}
              </MenuItem>
              <MenuItem  key={"Percentage"} value={"percentage"} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                {"Percentage"}
              </MenuItem>
              <MenuItem  key={"Flat"} value={"flat"} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                {'Flat'}
              </MenuItem>
            </Select>

            <Typography component="h1" variant="h6" style={{marginTop: 10}}>
              {t('extra_info')}
            </Typography>
             <TextField
              className={isRTL ==="rtl"? classes.rootRtl_1:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              id="extra_info"
              label=  {t('extra_info')}
              name="extra_info"
              autoComplete="extra_info"
              onChange={handleTextChange}
              defaultValue={data.extra_info}
            />
             <Typography component="h1" variant="h6">
              {t('image')}
            </Typography>
            <input
                type="file"
                style={{marginTop:10}}
                name= {t('image')}
                onChange={(event) => {
                    setSelectedImage(event.target.files[0]);
                }}
            />
            {selectedImage && (
                <div>
                    <img alt="not fount" width={"55%"} src={URL.createObjectURL(selectedImage)} style={{marginTop:15,marginBottom:20}}/>
                <br />
                    <button onClick={()=>setSelectedImage(null)}>Remove</button>
                </div>
            )}
            </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} style={{padding: 20}}>
          <Button
              type="submit"
              width='50%'
              variant="contained"
              className={classes.submit}
              onClick = {()=>submitValue()}
            >
              {t('submit')}
            </Button>
          </Grid>
            {/* <AlertDialog open={settingsdata.error.flag && clicked} onClose={handleClose}>{t('update_failed')}</AlertDialog> */}
        </CardContent>
        </Box>

      
      </div>
  );
}