import React,{ useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import {
  Typography,
  MenuItem,
  Select,
} from '@material-ui/core';
import { colors } from '../components/Theme/WebTheme';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  },
  submit:{
    width: '100%',
    backgroundColor:'#379ab2',
    marginTop: 20
  },
  button: {
    backgroundColor: colors.Rigzie1,
    color: '#fff',
    fontWeight:'bolder',
    '&:hover': {
      backgroundColor: '#0b696a',
      color: '#fff',
  },
  notchedOutline: {
    borderColor: colors.Rigzie1,
    width: '95%',
  }
}}));

export default function EditAdmin() {

  const location = useLocation();
  const getId = location.state.detail;

  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const {
    editUser, 
    fetchUsersOnce
  } = api;
  const dispatch = useDispatch();
  const [approvedType, setApprovedType] = useState(getId.approved);

  useEffect(()=>{
    dispatch(fetchUsersOnce());
  },[dispatch,fetchUsersOnce]);

  let history = useHistory();

  const [data, setData] = useState({
    firstName: getId.firstName,
    lastName: getId.lastName,
    email: getId.email,
    mobile: getId.mobile,
    approved: getId.approved
  });

  const handleApprovedTypeSelect = (event) => {
    setApprovedType(event.target.value); 
  };

  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const emailValid = re.test(email)
    return emailValid;
  }

  const submitValue = () => {
    if(data.firstName && data.firstName.length > 1 && data.lastName && data.lastName.length > 1){
      if(data.mobile){
        if(validateEmail(data.email)){
          let arr = {
            ...getId,
            firstName: data.firstName,
            lastName: data.lastName,
            mobile: data.mobile,
            email: data.email,
            approved: approvedType,
          };
          dispatch(editUser(getId.id, arr));
          dispatch(fetchUsersOnce());
          alert('User Successfully Updated');
        } else {
          alert(t('valid_email_check'));
        }
      } else {
        alert(t('mobile_no_blank_error'));
      }
    } else {
      alert(t('name_blank_error'));
    }
  }

  return (
    <div>
      <div className={classes.heading}>
        <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
          {t('edit_admin')}
        </Typography>
      </div>
      <Button
        type="back"
          width='3%'
          variant="contained"
          className={classes.button}
          startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
          onClick={() => history.goBack()}
      >
        {t('go_back')}
      </Button>
      <Box boxShadow={6}  m={2} p={2} style={{ borderRadius: 20, border: '2px solid #109496', width: '60%', height: '90%' }}>
        <CardContent>
          <Grid container style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }} >
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: 20}}>
              <Typography component="h1" variant="h6">
                {t('first_name')}
              </Typography>
              <TextField
                className={isRTL === "rtl" ? classes.rootRtl : null}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                margin="normal"
                required
                fullWidth
                id="firstName"
                label={t('first_name')}
                name="firstName"
                autoComplete="firstName"
                onChange={handleTextChange}
                defaultValue={data.firstName}
              />
              <Typography component="h1" variant="h6">
                {t('last_name')}
              </Typography>
              <TextField
                className={isRTL === "rtl" ? classes.rootRtl : null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                label={t('last_name')}
                name="lastName"
                autoComplete="lastName"
                onChange={handleTextChange}
                defaultValue={data.lastName}
              />
               <Select
              id="booking-type-native"
              value={approvedType}
              onChange={handleApprovedTypeSelect}
              className={classes.input}
              style={{textAlign:isRTL==='rtl'? 'right':'left', marginTop: 15}}
              variant="outlined"
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem  key={"Approved"} value={true} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                {t('Approved')}
              </MenuItem>
              <MenuItem  key={"Pending"} value={false} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                {t('Pending')}
              </MenuItem>
            </Select> 
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ padding: 20}}>
              <Typography component="h1" variant="h6">
                {t('mobile')}
              </Typography>
              <TextField
                className={isRTL === "rtl" ? classes.rootRtl : null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="mobile"
                label={t('mobile')}
                name="mobile"
                disabled={getId.mobile === ' ' ? false : true}
                autoComplete="mobile"
                onChange={handleTextChange}
                defaultValue={data.mobile}
              />
              <Typography component="h1" variant="h6">
                {t('email')}
              </Typography>
              <TextField
                className={isRTL === "rtl" ? classes.rootRtl : null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('email')}
                name="email"
                disabled={getId.email === ' ' ? false : true}
                autoComplete="email"
                onChange={handleTextChange}
                defaultValue={data.email}

              />
              <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={() => submitValue()}
            >
              {t('save')}
            </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </div>
  );
}