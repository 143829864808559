import {
  FETCH_CAR_TYPES,
  FETCH_CAR_TYPES_SUCCESS,
  FETCH_CAR_TYPES_FAILED,
  EDIT_CAR_TYPE
} from "../store/types";
import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { onValue, set ,remove, push } from "firebase/database";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const fetchCarTypes = () => (dispatch) => {

  const {
    carTypesRef
  } = firebase;

  dispatch({
    type: FETCH_CAR_TYPES,
    payload: null
  });
  onValue(carTypesRef, snapshot => {
    if (snapshot.val()) {
      let data = snapshot.val();
      const arr = Object.keys(data).map(i => {
        data[i].id = i;
        return data[i]
      });
      dispatch({
        type: FETCH_CAR_TYPES_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_CAR_TYPES_FAILED,
        payload: store.getState().languagedata.defaultLanguage.no_cars
      });
    }
  });
};

export const editCarType = (cartype, method) => async (dispatch) => {
  const {
    carTypesRef, 
    carTypesEditRef,
    carDocsRef
  } = firebase;
  dispatch({
    type: EDIT_CAR_TYPE,
    payload: { method, cartype }
  });

  if (method === 'Add') {

    let editedCar = {...cartype};

    let timestamp = new Date().toISOString();
    await uploadBytesResumable(carDocsRef(timestamp), cartype.image);
    editedCar.image = await getDownloadURL(carDocsRef(timestamp))

    push(carTypesRef, editedCar);

  } else if (method === 'Delete') {
     remove(carTypesEditRef(cartype.id));
  } else {
    let editedCar = {...cartype};

    if(cartype.newImage){
      let timestamp = new Date().toISOString();
      await uploadBytesResumable(carDocsRef(timestamp), cartype.newImage);
      cartype.image = await getDownloadURL(carDocsRef(timestamp))
    }

    delete editedCar.id;
    set(carTypesEditRef(cartype.id), cartype);
  }
}