import React, { useState, useEffect, useRef } from 'react';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AlertDialog from '../components/AlertDialog';
import { api } from 'common';
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import { colors } from '../components/Theme/WebTheme';
//import CancelIcon from '@material-ui/icons/Cancel';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
const useStyles = makeStyles(theme => ({

  paper: {
    marginTop: theme.spacing(2),
    flexDirection: 'column',
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#019b9d'
  },
  submit2: {
    marginLeft:75,
    top:-40,
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#019b9d'
  },
  reset:{
    margin: theme.spacing(3, 0, 2),
    marginLeft: 10
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width:'50%'
  },
  content: {
    flex: '1 0 auto',
  },
  cd:{
    display:'flex'
  },
  img:{
    width:'50%',
  },
  notchedOutline:{
    borderColor:colors.Rigzie1
  },
  heading:{
    marginBottom:10
  },
  icon: {
    backgroundColor: 'red',
    width: 30,
    height: 30
  }
}));

const MyProfile = () => {

  const { t } = useTranslation();
  const {
    updateProfile,
    updateWebProfileImage,
    fetchUsersOnce,
    updateProfileWithEmail
  } = api;
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });

  const [data, setData] = useState('');
  
  const fileInputRef=useRef();
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setData({
        firstName: !auth.info.profile.firstName || auth.info.profile.firstName === ' ' ? '' : auth.info.profile.firstName,
        lastName: !auth.info.profile.lastName || auth.info.profile.lastName === ' ' ? '' : auth.info.profile.lastName,
        email: !auth.info.profile.email || auth.info.profile.email === ' ' ? '' : auth.info.profile.email,
        mobile: !auth.info.profile.mobile || auth.info.profile.mobile === ' ' ? '' : auth.info.profile.mobile,
        loginType:auth.info.profile.loginType?'social':'mobile',
        usertype: auth.info.profile.usertype,
        uid: auth.info.uid,
        profile_image: auth.info.profile.profile_image ?  auth.info.profile.profile_image : ''
      });
    }
  }, [auth.info]);

  const updateData = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    //if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(data.email)) {
      e.preventDefault();
      if(data.email !== auth.info.profile.email ){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(re.test(data.email)){
          data['uid'] = auth.info.uid;
          dispatch(updateProfileWithEmail(data));
        } else {
          setCommonAlert({ open: true, msg: t('proper_email') })
        }
      } else{
        dispatch(updateProfile(auth.info, {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          mobile: data.mobile
        }));
        if(selectedImage){
          dispatch(updateWebProfileImage(auth.info, selectedImage));
          dispatch(fetchUsersOnce());
        }
      }
      setTimeout(()=>{     
        setCommonAlert({ open: true, msg: t('profile_updated') })
        setSelectedImage(null);
      },10000);
    // } else {
    //   setCommonAlert({ open: true, msg: t('proper_email') })
    // }
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const profileImageChange = async (e)=>{
    setSelectedImage( e.target.files[0]);
  }

  return (
    auth.loading ? <CircularLoading /> :
 
   
        <div className={classes.paper}>
          <div className={classes.heading}>
        <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
        {t('edit_profile')}
            </Typography>
            </div>
          <Box className={classes.form} 
          //onSubmit={()=>handleSubmit()}  
           boxShadow={6}
           m={2}
           p={2} style={{borderRadius:20,border: '2px solid #109496',width:'60%'}}>
      <CardContent>
        
          <div className={classes.cd}> 
            <div className={classes.details}>
              <CardContent className={classes.content}>
              <TextField
                  InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
              
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstName"
                label={t('firstname')}
                name="firstName"
                autoComplete="firstName"
                onChange={updateData}
                value={data.firstName}
                autoFocus
              />
            
              <TextField
                  InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                label={t('lastname')}
                name="lastName"
                autoComplete="lastName"
                onChange={updateData}
                value={data.lastName}
              />
        
              <TextField
                  InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('email')}
                name="email"
                autoComplete="email"
                onChange={updateData}
                value={data.email}
                disabled={data.loginType==='email'?true:false}
              />
            
              <TextField
                  InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="mobile"
                label={t('phone')}
                name="mobile"
                autoComplete="mobile"
                onChange={updateData}
                value={data.mobile}
                disabled={data.loginType==='email'?true:false}
              />
        
              <TextField
                  InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                id="usertype"
                label={t('usertype')}
                name="usertype"
                value={data.usertype}
                disabled={true}
              />
                 </CardContent>
            </div>
            
            <CardContent className={classes.img}>

              {selectedImage ?
                <div  onClick={()=>fileInputRef.current.click()} style={{display:'flex',justifyContent:'center'}}>
                  <img src = {URL.createObjectURL(selectedImage)} alt='Profile'
                  style={{margin:30, width:200, height:200, borderRadius: 150}}/>
                  <CameraAltIcon  style={{ fontSize: 35,color: 'gray', height:  35, width:  160, marginTop: 195 , marginLeft: -210, overflow:'hidden' }} />
                </div>
                :
                data.profile_image ?
                  <div  onClick={()=>fileInputRef.current.click()} style={{display:'flex',justifyContent:'center'}}>
                    <img src = {data.profile_image} alt='Profile'
                    style={{margin:30, width:200, height:200, borderRadius: 150}}/>
                     <CameraAltIcon style={{ fontSize: 35,color: 'gray', height:  35, width:  160, marginTop: 195 , marginLeft: -210, overflow:'hidden' }} />
                  </div>
                :
                  <div  onClick={()=>fileInputRef.current.click()} style={{display:'flex',justifyContent:'center'}}>
                    <img src = {require("../assets/img/a.png").default} alt='Profile'
                    style={{margin:30, width:200, height:200, borderRadius: 150}}/>
                     <CameraAltIcon style={{ fontSize: 35,color: 'gray', height:  35, width:  160, marginTop: 195 , marginLeft: -210, overflow:'hidden' }} />
                  </div>
              } 
              <input onChange={(event)=>profileImageChange(event)} multiple={false} ref={fileInputRef} type='file' hidden/>
              <div style={{marginTop: 20, display:'flex',justifyContent:'center'}}>
              <Button
                type="submit"
                width='45%'
                variant="contained"
                onClick={handleSubmit}
                className={classes.submit}
              >
                Save
              </Button>
              <Button
                type="submit"
                width='45%'
                variant="contained"
                onClick={()=>setSelectedImage(null)}
                className={classes.reset}
              >
                Reset
              </Button>
              </div>
            </CardContent> 

          </div>
            {/* <Button
              type="submit"
              width='45%'
              variant="contained"
              onClick={()=>handleSubmit()}
              className={classes.submit}
            >
              Save
            </Button>
            <Button
              type="submit"
              width='45%'
              variant="contained"
              onClick={()=>setSelectedImage(null)}
              className={classes.reset}
            >
              Reset
            </Button> */}
        </CardContent>
        </Box>
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
        </div>
        
   
  );
};

export default MyProfile;