import {
  FETCH_BOOKINGS,
  FETCH_BOOKINGS_SUCCESS,
  FETCH_BOOKINGS_FAILED,
  FETCH_OFFLOADS,
  FETCH_OFFLOADS_SUCCESS,
  FETCH_OFFLOADS_FAILED,
  UPDATE_BOOKING,
  CANCEL_BOOKING
} from "../store/types";
import { fetchBookingLocations } from '../actions/locationactions';
import { RequestPushMsg } from '../other/NotificationFunctions';
import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { get, onValue, update, remove, push } from "firebase/database";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const fetchOffloads = (uid) => (dispatch) => {
  const {
    offloadListRef,
  } = firebase;

  dispatch({
    type: FETCH_OFFLOADS,
    payload: null,
  });
  onValue(offloadListRef(uid), (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const bookings = Object.keys(data)
        .map((i) => {
          data[i].id = i;
          data[i].pickupAddress = data[i].pickup.add;
          data[i].dropAddress = data[i].drop.add;
          data[i].discount = data[i].discount_amount
            ? data[i].discount_amount
            : 0;
          data[i].cashPaymentAmount = data[i].cashPaymentAmount
            ? data[i].cashPaymentAmount
            : 0;
          data[i].cardPaymentAmount = data[i].cardPaymentAmount
            ? data[i].cardPaymentAmount
            : 0;
          return data[i];
        });
      dispatch({
        type: FETCH_OFFLOADS_SUCCESS,
        payload: bookings.reverse(),
      });
    } else {
      dispatch({
        type: FETCH_OFFLOADS_FAILED,
        payload: store.getState().languagedata.defaultLanguage.no_bookings,
      });
    }
  });
};

export const fetchBookings = (uid, role) => (dispatch) => {

  const {
    bookingListRef,
  } = firebase;

  dispatch({
    type: FETCH_BOOKINGS,
    payload: null,
  });
  onValue(bookingListRef(uid, role), (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const active = [];
      let tracked = null;
      const bookings = Object.keys(data)
        .map((i) => {
          data[i].id = i;
          data[i].pickupAddress = data[i].pickup.add;
          data[i].dropAddress = data[i].drop.add;
          data[i].discount = data[i].discount_amount
            ? data[i].discount_amount
            : 0;
          data[i].cashPaymentAmount = data[i].cashPaymentAmount
            ? data[i].cashPaymentAmount
            : 0;
          data[i].cardPaymentAmount = data[i].cardPaymentAmount
            ? data[i].cardPaymentAmount
            : 0;
          return data[i];
        });
      for (let i = 0; i < bookings.length; i++) {
        if (['PAYMENT_PENDING','NEW', 'ACCEPTED', 'ARRIVED', 'STARTED', 'REACHED', 'PENDING', 'PAID'].indexOf(bookings[i].status) != -1) {
          active.push(bookings[i]);
        }
        if ((['ACCEPTED', 'ARRIVED', 'STARTED'].indexOf(bookings[i].status) != -1) && role == 'driver') {
          tracked = bookings[i];
          fetchBookingLocations(tracked.id)(dispatch)(firebase);
        }
      }
      dispatch({
        type: FETCH_BOOKINGS_SUCCESS,
        payload: {
          bookings: bookings.reverse(),
          active: active,
          tracked: tracked
        },
      });
      if (tracked) {
        dispatch({
          type: FETCH_BOOKINGS_SUCCESS,
          payload: null
        });
      }
    } else {
      dispatch({
        type: FETCH_BOOKINGS_FAILED,
        payload: store.getState().languagedata.defaultLanguage.no_bookings,
      });
    }
  });
};

export const updateBooking = (booking) => async (dispatch) => {

  const {
    auth,
    trackingRef,
    singleBookingRef,
    singleUserRef,
    walletBalRef,
    walletHistoryRef,
    bookingMeterImage,
    adminReportRef
  } = firebase;

  dispatch({
    type: UPDATE_BOOKING,
    payload: booking,
  });
  
  if (booking.status == 'PAYMENT_PENDING') {
    update(singleBookingRef(booking.id),booking);
  }
  if (booking.status == 'NEW') {
    update(singleBookingRef(booking.id),booking);
  }
  if (booking.status == 'ARRIVED') {
    let dt = new Date();
    booking.driver_arrive_time = dt.getTime().toString();
    update(singleBookingRef(booking.id),booking);
    RequestPushMsg(
      booking.customer_token,
      {
          title: store.getState().languagedata.defaultLanguage.notification_title,
          msg: store.getState().languagedata.defaultLanguage.driver_near,
          screen: 'BookedCab',
          params: { bookingId: booking.id }
      })(firebase);
  }
  if (booking.status == 'STARTED') {
    let dt = new Date();
    let localString = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    let timeString = dt.getTime();
    booking.trip_start_time = localString;
    booking.startTime = timeString;
    update(singleBookingRef(booking.id),booking);

    onValue(singleUserRef(booking.driver), snapshot => {
      let profile = snapshot.val();
      push(trackingRef(booking.id),{
        at: new Date().getTime(),
        status: 'STARTED',
        lat: profile.location.lat,
        lng: profile.location.lng
      });
    },{onlyOnce:true})

    RequestPushMsg(
      booking.customer_token,
      {
          title: store.getState().languagedata.defaultLanguage.notification_title,
          msg: store.getState().languagedata.defaultLanguage.driver_journey_msg + booking.id,
          screen: 'BookedCab',
          params: { bookingId: booking.id }
      })(firebase);
  }
  if (booking.status == 'REACHED') {

    const driverProfile = await get(singleUserRef(booking.driver));
    const driverLocation = driverProfile.val().location;

    push(trackingRef(booking.id),{
      at: new Date().getTime(),
      status: 'REACHED',
      lat: driverLocation.lat,
      lng: driverLocation.lng
    });

    let latlng = driverLocation.lat + ',' + driverLocation.lng;
    let address = await fetchAddressfromCoords(latlng)(firebase);
      

    if(booking.offLoad || booking.booking_type_admin){
      address = booking.drop.add;
    }

    onValue(child(singleUserRef(booking.customer),'savedAddresses'),savedAdd => {
        if(savedAdd.val()){
          let addresses = savedAdd.val();
          let didNotMatch = true; 
          for (let key in addresses) {
            let entry = addresses[key];
            if(GetDistance(entry.lat,entry.lng, (booking.offLoad || booking.booking_type_admin)? booking.drop.lat : driverLocation.lat, (booking.offLoad || booking.booking_type_admin)? booking.drop.lng : driverLocation.lng) < 0.1){
                didNotMatch = false;
                let count = entry.count? entry.count + 1 : 1
                update(child(singleUserRef(booking.customer),'savedAddresses/' + key),{count: count})
                break;
            }
          }
          if(didNotMatch){
            push(child(singleUserRef(booking.customer),'savedAddresses'),{
              description: address,
              lat: ( booking.offLoad || booking.booking_type_admin)? booking.drop.lat : driverLocation.lat,
              lng: ( booking.offLoad || booking.booking_type_admin)? booking.drop.lng : driverLocation.lng,
              count:1
            });
          }  
        }else{
          push(child(singleUserRef(booking.customer),'savedAddresses'),{
            description: address,
            lat: (booking.offLoad || booking.booking_type_admin)? booking.drop.lat : driverLocation.lat,
            lng: (booking.offLoad || booking.booking_type_admin)? booking.drop.lng : driverLocation.lng,
            count:1
          });
        }
    }, {onlyOnce: true});

    const end_time = new Date();
    const diff = (end_time.getTime() - parseFloat(booking.startTime)) / 1000;
    const totalTimeTaken = Math.abs(Math.round(diff));
    booking.trip_end_time = end_time.getHours() + ":" + end_time.getMinutes() + ":" + end_time.getSeconds();
    booking.endTime = end_time.getTime();
    booking.total_trip_time = totalTimeTaken;

    if(booking.offLoad && booking.booking_type_admin && booking.fleetadmin){
      console.log('3offLoad', booking.offLoad);
    } else {
      let cars = store.getState().cartypes.cars;
      let rates = {};
      for (var i = 0; i < cars.length; i++) {
        if (cars[i].name == booking.carType) {
          rates = cars[i];
        }
      }
      const trackingSnap = await get(orderByKey(trackingRef(booking.id)));
      const trackingVal = trackingSnap.val();
      const res = await GetTripDistance(trackingVal)(firebase);
      const settings = store.getState().settingsdata.settings;
      const distance = settings.convert_to_mile? (res.distance / 1.609344) : res.distance;
      const { grandTotal, convenience_fees} = FareCalculator(distance, totalTimeTaken, rates, null, settings.decimal);
      booking.drop = { 
        add: address,
        lat: driverLocation.lat,
        lng: driverLocation.lng
      };
      booking.dropAddress = address;
      booking.distance = parseFloat(distance).toFixed(settings.decimal);
      booking.coords = res.coords;
      if((booking.offload_payment_type && booking.offload_payment_type != 'meter') || !booking.offload_payment_type){
        console.log('here111');
        if(booking.corporateAdmin_share){
          booking.trip_cost = parseFloat(grandTotal + parseFloat(booking.corporateAdmin_share)).toFixed(settings.decimal);
          booking.convenience_fees = convenience_fees;
          booking.driver_share = parseFloat(grandTotal - convenience_fees).toFixed(settings.decimal);
        }
        else if(booking.fleetadmin_share){
          booking.trip_cost = parseFloat(grandTotal + parseFloat(booking.fleetadmin_share)).toFixed(settings.decimal);
          booking.convenience_fees = convenience_fees;
          booking.driver_share = parseFloat(grandTotal - convenience_fees).toFixed(settings.decimal);
        } else{
          booking.trip_cost = grandTotal;
          booking.convenience_fees = convenience_fees;
          booking.driver_share = parseFloat(grandTotal - convenience_fees).toFixed(settings.decimal);
        }
      }
    }
    update(singleBookingRef(booking.id),booking);
    RequestPushMsg(
      booking.customer_token,
      {
          title: store.getState().languagedata.defaultLanguage.notification_title,
          msg: store.getState().languagedata.defaultLanguage.driver_completed_ride,
          screen: 'BookedCab',
          params: { bookingId: booking.id }
      })(firebase);
  }
  if (booking.status == 'PENDING') {
    update(singleUserRef(booking.driver),{ queue: false });
    if(booking.meterImage){
      await uploadBytesResumable(bookingMeterImage(booking.id), booking.meterImage);
      booking.meterImage = await getDownloadURL(bookingMeterImage(booking.id))
    }
    update(singleBookingRef(booking.id),booking);
  }
  if (booking.status == 'PAID') {
    const settings = store.getState().settingsdata.settings;
    update(singleBookingRef(booking.id),booking);
    if(booking.driver == auth.currentUser.uid && (booking.prepaid || booking.payment_mode == 'cash' || booking.payment_mode == 'wallet')){
      update(singleUserRef(booking.driver),{ queue: false });
    }

    if(booking.paid && (booking.booking_type_admin && booking.booking_type_admin == true) || (booking.fleetadmin && booking.fleetadmin.length > 1 ) || (booking.corporateAdminId && booking.corporateAdminId.length > 1)){
      update(singleUserRef(booking.driver),{ queue: false });
    }

    if(booking.offLoad){
      if(booking.offload_payment_type == 'account'){
        onValue(singleUserRef(booking.offLoader), snapshot => {
          let walletBalance = parseFloat(snapshot.val().walletBalance);
          walletBalance = walletBalance + parseFloat(booking.trip_cost);
          walletBalRef(booking.offLoader).set(parseFloat(walletBalance.toFixed(settings.decimal)));
    
          let details = {
            type: 'Debit',
            amount: parseFloat(booking.trip_cost).toFixed(settings.decimal),
            date: new Date().toString(),
            txRef: booking.id
          }
          push(walletHistoryRef(booking.offLoader),details);
        },{ onlyOnce: true});
      } 
      //else {
        onValue(singleUserRef(booking.offLoader), snapshot => {
          let walletBalance = parseFloat(snapshot.val().walletBalance);
          walletBalance = walletBalance + parseFloat(booking.offLoaderFee);
          walletBalRef(booking.offLoader).set(parseFloat(walletBalance.toFixed(settings.decimal)));
    
          let details = {
            type: 'Credit',
            amount: parseFloat(booking.offLoaderFee).toFixed(settings.decimal),
            date: new Date().toString(),
            txRef: booking.id
          }
          push(walletHistoryRef(booking.offLoader),details);
        },{onlyOnce: true});
      //}    
    }

    if(booking.offLoad && booking.offload_payment_type == 'account'){
      onValue(singleUserRef(booking.driver), snapshot => {
        let walletBalance = parseFloat(snapshot.val().walletBalance);
        walletBalance = walletBalance + parseFloat(booking.driver_share);

        walletBalRef(booking.driver).set(parseFloat(walletBalance.toFixed(settings.decimal)));

        let details = {
          type: 'Credit',
          amount: parseFloat(booking.driver_share).toFixed(settings.decimal),
          date: new Date().toString(),
          txRef: booking.id
        }
        push(walletHistoryRef(booking.driver),details);
      
      },{onlyOnce: true});

      RequestPushMsg(
        booking.offloader_token,
        {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg: store.getState().languagedata.defaultLanguage.success_payment_offload,
            screen: 'BookedCab',
            params: { bookingId: booking.id }
        })(firebase);

      RequestPushMsg(
        booking.driver_token,
        {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg: store.getState().languagedata.defaultLanguage.success_payment,
            screen: 'BookedCab',
            params: { bookingId: booking.id }
        })(firebase);
    } else {
      onValue(singleUserRef(booking.driver), snapshot => {
        let walletBalance = parseFloat(snapshot.val().walletBalance);
        walletBalance = walletBalance + parseFloat(booking.driver_share);
        if(parseFloat(booking.cashPaymentAmount)>0){
          walletBalance = walletBalance - parseFloat(booking.cashPaymentAmount);
        }
        walletBalRef(booking.driver).set(parseFloat(walletBalance.toFixed(settings.decimal)));

        let details = {
          type: 'Credit',
          amount: parseFloat(booking.driver_share).toFixed(settings.decimal),
          date: new Date().toString(),
          txRef: booking.id
        }
        push(walletHistoryRef(booking.driver),details);
        
        if(parseFloat(booking.cashPaymentAmount)>0){
          let details = {
            type: 'Debit',
            amount: booking.cashPaymentAmount,
            date: new Date().toString(),
            txRef: booking.id
          }
          push(walletHistoryRef(booking.driver),details);
        }  
      },{onlyOnce: true});

      if(booking.corporateAdminId){
        onValue(singleUserRef(booking.corporateAdminId), snapshot => {
          let walletBalance = parseFloat(snapshot.val().walletBalance);
          walletBalance = walletBalance + parseFloat(booking.corporateAdmin_share);
          walletBalRef(booking.corporateAdminId).set(parseFloat(walletBalance.toFixed(settings.decimal)));

          let details = {
            type: 'Credit',
            amount: parseFloat(booking.corporateAdmin_share).toFixed(settings.decimal),
            date: new Date().toString(),
            txRef: booking.id
          }
          push(walletHistoryRef(booking.corporateAdminId),details);
        }, {onlyOnce: true});

        if(booking.paid ){
          onValue(singleUserRef(booking.corporateAdminId), snapshot => {
            let walletBalance = parseFloat(snapshot.val().walletBalance);
            walletBalance = walletBalance - parseFloat(booking.trip_cost);
            walletBalRef(booking.corporateAdminId).set(parseFloat(walletBalance.toFixed(settings.decimal)));
  
            let details = {
              type: 'Debit',
              amount: parseFloat(booking.trip_cost).toFixed(settings.decimal),
              date: new Date().toString(),
              txRef: booking.id
            }
            push(walletHistoryRef(booking.corporateAdminId),details);
          },{onlyOnce: true});
        }
      }
      
      if(booking.fleetadmin && booking.fleetadmin.length > 1 ){
        onValue(singleUserRef(booking.fleetadmin), snapshot => {
          let walletBalance = parseFloat(snapshot.val().walletBalance);
          walletBalance = walletBalance + parseFloat(booking.fleetadmin_share);
          walletBalRef(booking.fleetadmin).set(parseFloat(walletBalance.toFixed(settings.decimal)));

          let details = {
            type: 'Credit',
            amount: parseFloat(booking.fleetadmin_share).toFixed(settings.decimal),
            date: new Date().toString(),
            txRef: booking.id
          }
          push(walletHistoryRef(booking.fleetadmin),details);
        }, {onlyOnce: true});
        if(booking.paid ){
          onValue(singleUserRef(booking.fleetadmin), snapshot => {
            let walletBalance = parseFloat(snapshot.val().walletBalance);
            walletBalance = walletBalance - parseFloat(booking.trip_cost);
            walletBalRef(booking.fleetadmin).set(parseFloat(walletBalance.toFixed(settings.decimal)));
  
            let details = {
              type: 'Debit',
              amount: parseFloat(booking.trip_cost).toFixed(settings.decimal),
              date: new Date().toString(),
              txRef: booking.id
            }
            push(walletHistoryRef(booking.fleetadmin),details);
          },{onlyOnce: true});
        }
      }

      RequestPushMsg(
      booking.customer_token,
      {
          title: store.getState().languagedata.defaultLanguage.notification_title,
          msg: store.getState().languagedata.defaultLanguage.success_payment,
          screen: 'BookedCab',
          params: { bookingId: booking.id }
      })(firebase);
      RequestPushMsg(
        booking.driver_token,
        {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg: store.getState().languagedata.defaultLanguage.success_payment,
            screen: 'BookedCab',
            params: { bookingId: booking.id }
        })(firebase);
    }
    onValue(adminReportRef, snapshot => {
      let allDetails = snapshot.val();
      let lastAmount = 0;
      if(allDetails && Object.keys(allDetails).length > 0){
        var keys = Object.values(allDetails);
        var last = keys[keys.length-1];
        lastAmount = parseFloat(last.updateBalance);
      }  
      push(adminReportRef,{
        date : booking.bookingDate,
        uid: booking.id,
        adminAmount : booking.convenience_fees > 0 ?parseFloat(booking.convenience_fees):0,
        driverAmount : booking.driver_share > 0 ? parseFloat(booking.driver_share):0,
        fleetAmount: booking.fleetadmin_share > 0 ? parseFloat(booking.fleetadmin_share):0,
        corporateAmount: booking.corporateAdmin_share > 0 ? parseFloat(booking.corporateAdmin_share):0,
        type:'credit',
        craditAmount : parseFloat(booking.trip_cost),
        debitAmount : 0,
        updateBalance: parseFloat(lastAmount + parseFloat(booking.trip_cost)).toFixed(settings.decimal)
      });
    },{onlyOnce: true});
  }
  if (booking.status == 'COMPLETE') {
    update(singleBookingRef(booking.id),booking);
    if (booking.rating) {
      RequestPushMsg(
        booking.driver_token,
        {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg:  store.getState().languagedata.defaultLanguage.received_rating.toString().replace("X", booking.rating.toString()),
            screen: 'BookedCab',
            params: { bookingId: booking.id }
        })(firebase);
      onValue(singleUserRef(booking.driver), snapshot => {
        let profile = snapshot.val();
        let ratings = {};
        if (profile && profile.ratings) {
          ratings = profile.ratings
          let details = ratings.details;
          let sum = 0;
          for (let i = 0; i < details.length; i++) {
            sum = sum + parseFloat(details[i].rate);
          }
          sum = sum + booking.rating;
          ratings.userrating = parseFloat(sum / (details.length + 1)).toFixed(1);
          ratings.details.push({
            user: booking.customer,
            rate: booking.rating,
            customar_name : booking.customer_name,
            customar_image: booking.customer_image ? booking.customer_image : ""
          });
        } else {
          ratings.userrating = booking.rating;
          ratings.details = [];
          ratings.details.push({
            user: booking.customer,
            rate: booking.rating,
            customar_name : booking.customer_name,
            customar_image: booking.customer_image ? booking.customer_image : ""
          });
        }
        update(singleUserRef(booking.driver),{ratings: ratings});
      },{onlyOnce:true});
    }
  }
};

export const cancelBooking = (data) => (dispatch) => {
  const {
    singleBookingRef,
    singleUserRef,
    requestedDriversRef
  } = firebase;

  dispatch({
    type: CANCEL_BOOKING,
    payload: data,
  });

  update(singleBookingRef(data.booking.id),{
    status: 'CANCELLED',
    reason: data.reason,
    cancelledBy: data.cancelledBy
  }).then(() => {
    if (data.booking.driver && (data.booking.status === 'ACCEPTED' || data.booking.status === 'ARRIVED')) {
      update(singleUserRef(data.booking.driver),{ queue: false });
      RequestPushMsg(
        data.booking.driver_token,
        {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg:  store.getState().languagedata.defaultLanguage.booking_cancelled + data.booking.id,
            screen: 'BookedCab',
            params: { bookingId: data.booking.id }
        })(firebase);
      RequestPushMsg(
        data.booking.customer_token,
        {
            title: store.getState().languagedata.defaultLanguage.notification_title,
            msg:  store.getState().languagedata.defaultLanguage.booking_cancelled + data.booking.id,
            screen: 'BookedCab',
            params: { bookingId: data.booking.id }
        })(firebase);
    }
    if (data.booking.status === 'NEW') {
      remove(requestedDriversRef(data.booking.id));
    }
  });
};

export const updateBookingImage = (booking, imageType, imageBlob) => (dispatch) => {
  const   {
    singleBookingRef,
    bookingImageRef
  } = firebase;
  
  uploadBytesResumable(bookingImageRef(booking.id,imageType), imageBlob).then(() => {
    imageBlob.close()
    return getDownloadURL(bookingImageRef(booking.id,imageType))
  }).then((url) => {
    if(imageType == 'pickup_image'){
      booking.pickup_image = url;
    }
    if(imageType == 'deliver_image'){
      booking.deliver_image = url;
    }
    update(singleBookingRef(booking.id),booking);
    dispatch({
      type: UPDATE_BOOKING,
      payload: booking,
    });
  })
};

export const deleteBooking = (booking) => (dispatch) => {
  const   {
    singleBookingRef
  } = firebase;

  dispatch({
    type: EDIT_BOOKINGS,
    payload: booking,
  });
  
  remove(singleBookingRef(booking.id));
};
