import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import { colors } from '../components/Theme/WebTheme';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  Typography 
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  },
  submit:{
    backgroundColor:'#379ab2'
  },
  submit1:{
    backgroundColor:'White',
    color:'#4c63b8'
    },
    notchedOutline:{
    borderColor:colors.Rigzie1,
    width:'95%',
  },
  button: {
    backgroundColor: colors.Rigzie1,
    color: '#fff',
    fontWeight:'bolder',
    '&:hover': {
      backgroundColor: '#0b696a',
      color: '#fff',
  },
}}));

export default function AddAdmin() {

const [data, setData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
});

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const emailValid = re.test(email)
  return emailValid;
}

const submitValue = () => {
  if (data.firstName && data.lastName) {
    if (data.mobile) {
      if (validateEmail(data.email)) {
        let arr = {
            firstName: data.firstName,
            lastName: data.lastName,
            mobile: data.mobile,
            email: data.email,
        };

        checkUserExists(arr).then((res) => {
            if (res.users && res.users.length > 0) {
              alert(t('user_exists'));
            }
            else if(res.error){
              alert(t('email_or_mobile_issue'));
            }
            else{
                arr.createdByAdmin = true;
                arr.usertype = 'admin';
                arr.createdAt = new Date().toISOString();
                arr.referralId= data.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
                arr.approved = true;
              dispatch(addUser(arr));
              alert('User Successfully Created');
              setData({
                    firstName: '',
                    lastName: '',
                    mobile: '',
                    email: '',
                });
            }
        });
      } else {
        alert(t('valid_email_check'));
      }
    } else {
      alert(t('mobile_no_blank_error'));
    }
  } else {
    alert(t('name_blank_error'));
  }
}


const { t,i18n } = useTranslation();
const isRTL = i18n.dir();
const classes = useStyles();
const {
    addUser,
    checkUserExists
} = api;
const history = useHistory();
const dispatch = useDispatch();


const handleTextChange = (e) => {
  setData({ ...data, [e.target.name]: e.target.value });
};

const goBack=()=>{
    history.goBack();
}

  return (
    <div>
    <div className={classes.heading}>
    <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
    {t('add_admin')}
          </Typography>
          </div>
          <Button
                type="back"
                 width='3%'
                 variant="contained"
                 className={classes.button}
                 startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
                 onClick={()=>goBack()}
               >
                  {t('go_back')}
               </Button>
               <Box  boxShadow={6}
       
       m={2}
       p={2} style={{borderRadius:20,border: '2px solid #109496',width:'60%'}}>
     <CardContent>

     <Grid container style={{direction:isRTL ==='rtl'?'rtl':'ltr'}} >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
            className={isRTL ==="rtl"? classes.rootRtl:null}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              margin="normal"
              required
              fullWidth
              id="firstName"
               label={'First Name'}
               name="firstName"
              autoComplete="firstName"
              onChange={handleTextChange}
              defaultValue={data.firstName}
            />
            <TextField
              className={isRTL ==="rtl"? classes.rootRtl:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="lastName"
              label={'Last Name'}
              name="lastName"
              autoComplete="lastName"
              onChange={handleTextChange}
              defaultValue={data.lastName}
            />
            <TextField
              className={isRTL ==="rtl"? classes.rootRtl:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="mobile"
              label={'Mobile'}
              name="mobile"
              autoComplete="mobile"
              onChange={handleTextChange}
              defaultValue={data.mobile}
            />
            <TextField
              className={isRTL ==="rtl"? classes.rootRtl:null}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={'Email'}
              name="email"
              autoComplete="email"
              onChange={handleTextChange}
              defaultValue={data.email}
            />
   </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
              type="submit"
              width='45%'
              variant="contained"
              className={classes.button}
              onClick = {()=>submitValue()}
            >
              {t('submit')}
            </Button>
          </Grid>
        </Grid>
        {/* <AlertDialog open={settingsdata.error.flag && clicked} onClose={handleClose}>{t('update_failed')}</AlertDialog> */}
       </CardContent>
       </Box>
      </div>
  );
}