import React,{ useEffect } from 'react';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { api } from 'common';
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import moment from 'moment/min/moment-with-locales';

const {Howl} = require('howler');

function AuthLoading(props) {
  
    const { t } = useTranslation();
    const {
        fetchUser,
        fetchCarTypes,
        fetchSettings, 
        fetchBookings,
        fetchCancelReasons,
        fetchPromos,
        fetchDriverEarnings,
        fetchUsers,
        fetchNotifications,
        fetchEarningsReport,
        signOut,
        fetchWithdraws,
        fetchPaymentMethods,
        fetchLanguages,
        fetchSubscription,
        updateBooking,
        fetchMonthlyWithdraws,
        fetchCorporateEarnings,
        fetchFleetadminEarnings,
        fetchAdminReports
    } = api;
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const languagedata = useSelector(state => state.languagedata); 
    const settingsdata = useSelector(state => state.settingsdata);
    const bookinglistdata = useSelector(state => state.bookinglistdata);

    const audioClips = "https://firebasestorage.googleapis.com/v0/b/rigzie-cf338.appspot.com/o/sound%2Ftokyo_station.mp3?alt=media&token=c237ab4c-55a2-4c50-9e44-121a482d5442"

    const SoundPlay = (src) => {
        const sound = new Howl({
        src,
        html5: true,
        })
        sound.play();
    }

    useEffect(() => {
        if(auth.info){
            if(auth.info.profile){
                let role = auth.info.profile.usertype;
                if(bookinglistdata && bookinglistdata.bookings && role === 'admin' ){
                    for (let i = 0; i < bookinglistdata.bookings.length ; i++) {
                        if((bookinglistdata.bookings[i]).booking_type_admin === false){
                            if ((bookinglistdata.bookings[i]).status === "NEW" && !(bookinglistdata.bookings[i]).notification ) {
                                SoundPlay(audioClips); 
                                alert(t('new_booking_request'));
                                if(!((bookinglistdata.bookings[i]).notification) ) {
                                    let booking = bookinglistdata.bookings[i];
                                    booking.notification = true;
                                    dispatch(updateBooking(booking));
                                }
                            }
                        }
                    }
                }
            }
        }
    },[auth.info, dispatch, t, updateBooking ,bookinglistdata, bookinglistdata.bookings])

    useEffect(()=>{
        dispatch(fetchLanguages());   
        dispatch(fetchCarTypes());
        dispatch(fetchSettings());
    },[dispatch,fetchLanguages,fetchCarTypes,fetchSettings]);

    useEffect(()=>{
        if(languagedata.langlist){
            for (const value of Object.values(languagedata.langlist)) {
                if(value.default === true){
                    i18n.addResourceBundle(value.langLocale, 'translations', value.keyValuePairs);
                    i18n.changeLanguage(value.langLocale);
                    moment.locale(value.dateLocale);
                }
            }
            dispatch(fetchUser());
        }
    },[languagedata,dispatch,fetchUser])

    useEffect(()=>{
        if(settingsdata.settings){
            document.title = settingsdata.settings.appName
        }
    },[settingsdata.settings])

    useEffect(()=>{
        if(auth.info){
            if(auth.info.profile){
                let role = auth.info.profile.usertype;
                if(role === 'rider'){
                    dispatch(fetchBookings(auth.info.uid,role));
                    dispatch(fetchPaymentMethods());
                    dispatch(fetchCancelReasons());
                    dispatch(fetchUsers());
                }
                else if(role === 'driver'){
                    dispatch(fetchBookings(auth.info.uid,role));
                }
                else if(role === 'admin'){
                    dispatch(fetchUsers());
                    dispatch(fetchBookings(auth.info.uid,role));
                    dispatch(fetchPromos());
                    dispatch(fetchSubscription());
                    dispatch(fetchDriverEarnings(auth.info.uid,role));
                    dispatch(fetchNotifications());
                    dispatch(fetchEarningsReport());
                    dispatch(fetchCancelReasons());
                    dispatch(fetchWithdraws());
                    dispatch(fetchPaymentMethods());
                    dispatch(fetchMonthlyWithdraws());
                    dispatch(fetchAdminReports())
                }
                else if(role === 'fleetadmin'){
                    dispatch(fetchUsers());
                    dispatch(fetchBookings(auth.info.uid,role));
                    dispatch(fetchFleetadminEarnings(auth.info.uid,role));
                    dispatch(fetchDriverEarnings(auth.info.uid,role));
                }
                else if(role === 'corporateAdmin'){
                    dispatch(fetchUsers());
                    dispatch(fetchBookings(auth.info.uid,role));
                    dispatch(fetchCorporateEarnings(auth.info.uid,role));
                }
                else{
                    alert(t('not_valid_user_type'));
                    dispatch(signOut());
                }
            }else{
                alert(t('user_issue_contact_admin'));
                dispatch(signOut());
            }
        }
    },[auth.info,dispatch,fetchBookings,fetchCancelReasons,fetchDriverEarnings,fetchEarningsReport,fetchNotifications,fetchPromos,fetchUsers,fetchWithdraws,signOut,fetchPaymentMethods,fetchSubscription,fetchAdminReports,fetchFleetadminEarnings,fetchMonthlyWithdraws,fetchCorporateEarnings,t]);

    return (
        auth.loading || !languagedata.langlist? <CircularLoading/>: props.children
    )
}

export default AuthLoading;