import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  Typography,
  Modal
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { colors } from '../components/Theme/WebTheme';
import { useSelector, useDispatch } from 'react-redux';
import { api } from 'common';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import IconButton from '@material-ui/core/IconButton';
import ReactToPrint from "react-to-print";
import PrintDoc from '../components/PrintDoc.js';
import camera from '../assets/img/camera.png';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 20
  },
  submit: {
    backgroundColor: '#379ab2',
    width: '25%',
    marginRight: 20
  },
  remove: {
    backgroundColor: '#ff6666',
    width: '25%',
    marginRight: 20
  },
  submit1: {
    backgroundColor: 'White',
    color: '#4c63b8'
  },
  notchedOutline: {
    borderColor: colors.Rigzie1,
    width: '95%',
  },
  txt: {
    textAlign: 'center',
    color: colors.Rigzie1,
    fontWeight: 'bold',
    marginBottom: 15
  },
  txt1: {
    color: 'black',
    fontWeight: 'bold',
    margin: 20
  },
  box: {
    borderRadius: 10,
    border: '1px solid #444444'
  },
  printPaper: {
    width: 800,
    height: 720,
    backgroundColor: theme.palette.background.paper,
    margin: 50,
    marginLeft:250,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
  },
  buttonStyle: {
    height: 20
  }
}));

export default function AddMultiDoc() {
  const location = useLocation();
  const data1 = location.state.detail;
  //const data = location.state.detail;
  const document = location.state.document;
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const auth = useSelector(state => state.auth);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const history = useHistory()
  const [data2, setdata2] = useState([]);

  const {
    updateLicenseImage,
    fetchUsersOnce
  } = api;
  const dispatch = useDispatch();

    useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  useEffect(() => {
    if (staticusers && data1) {
      setdata2(staticusers.filter(user => user.usertype === 'driver' && user.id === data1[0].id));
    } else {
      setdata2([]);
    }
  }, [staticusers, data1]);

  const [state, setState] = useState({
    insurance1 : null,
    insurance2 : null,
    insurance3 : null,

    mechanicReceipts1: null,
    mechanicReceipts2: null,
    mechanicReceipts3: null,

    policeCheck1: null,
    policeCheck2: null,
    policeCheck3: null,

    carRegistration1: null,
    carRegistration2: null,

    drivingRecord1: null,
    drivingRecord2: null,
    drivingRecord3: null,
    drivingRecord4: null,
    drivingRecord5: null,
    drivingRecord6: null,

    identityCard1: null,
    identityCard2: null,
  });  

  const goBack = () => {
    history.goBack();
  }

  const submitValue = async () => {
    if (auth && auth.info) {
      if( state.mechanicReceipts1 || state.mechanicReceipts2 || state.mechanicReceipts3 || state.policeCheck1 || state.policeCheck2 || state.policeCheck3 || state.insurance1 || state.insurance2 
          || state.insurance3 || state.carRegistration1 || state.carRegistration2 || state.drivingRecord1 || state.drivingRecord2 || state.drivingRecord3 || state.drivingRecord4 || state.drivingRecord5
          || state.drivingRecord6 || state.identityCard1 || state.identityCard2){
        if (state.mechanicReceipts1) {
          await dispatch(updateLicenseImage(data2[0], state.mechanicReceipts1, 'mechanicReceipts1', false));
          setState({
            ...state,
            mechanicReceipts1: null
          });
          alert(t('update_data'))
        }
        else if (state.mechanicReceipts2) {    
          await dispatch(updateLicenseImage(data2[0], state.mechanicReceipts2, 'mechanicReceipts2', false));
          setState({
            ...state,
            mechanicReceipts2: null
          });
          alert(t('update_data'))
        }
        else if (state.mechanicReceipts3) {  
          await dispatch(updateLicenseImage(data2[0], state.mechanicReceipts3, 'mechanicReceipts3', false));
          setState({
            ...state,
            mechanicReceipts3: null
          });
          alert(t('update_data'))
        }
        else if (state.policeCheck1) {
          await dispatch(updateLicenseImage(data2[0], state.policeCheck1, 'policeCheck1', false));
          setState({
            ...state,
            policeCheck1: null
          });
          alert(t('update_data'))
        }
        else if (state.policeCheck2) {    
          await dispatch(updateLicenseImage(data2[0], state.policeCheck2, 'policeCheck2', false));
          setState({
            ...state,
            policeCheck2: null
          });
          alert(t('update_data'))
        }
        else if (state.policeCheck3) {  
          await dispatch(updateLicenseImage(data2[0], state.policeCheck3, 'policeCheck3', false));
          setState({
            ...state,
            policeCheck3: null
          });
          alert(t('update_data'))
        }
        else if (state.insurance1) {
          await dispatch(updateLicenseImage(data2[0], state.insurance1, 'insurance1', false));
          setState({
            ...state,
            insurance1: null
          });
          alert(t('update_data'))
        }
        else if (state.insurance2) {    
          await dispatch(updateLicenseImage(data2[0], state.insurance2, 'insurance2', false));
          setState({
            ...state,
            insurance2: null
          });
          alert(t('update_data'))
        }
        else if (state.insurance3) {  
          await dispatch(updateLicenseImage(data2[0], state.insurance3, 'insurance3', false));
          setState({
            ...state,
            insurance3: null
          });
          alert(t('update_data'))
        }
        else if (state.carRegistration1) {  
          await dispatch(updateLicenseImage(data2[0], state.carRegistration1, 'carRegistration1', false));
          setState({
            ...state,
            carRegistration1: null
          });
          alert(t('update_data'))
        }
        else if (state.carRegistration2) {  
          await dispatch(updateLicenseImage(data2[0], state.carRegistration2, 'carRegistration2', false));
          setState({
            ...state,
            carRegistration2: null
          });
          alert(t('update_data'))
        }
        else if (state.drivingRecord1) {  
          await dispatch(updateLicenseImage(data2[0], state.drivingRecord1, 'drivingRecord1', false));
          setState({
            ...state,
            drivingRecord1: null
          });
          alert(t('update_data'))
        }
        else if (state.drivingRecord2) {  
          await dispatch(updateLicenseImage(data2[0], state.drivingRecord2, 'drivingRecord2', false));
          setState({
            ...state,
            drivingRecord2: null
          });
          alert(t('update_data'))
        }
        else if (state.drivingRecord3) {  
          await dispatch(updateLicenseImage(data2[0], state.drivingRecord3, 'drivingRecord3', false));
          setState({
            ...state,
            drivingRecord3: null
          });
          alert(t('update_data'))
        }
        else if (state.drivingRecord4) {  
          await dispatch(updateLicenseImage(data2[0], state.drivingRecord4, 'drivingRecord4', false));
          setState({
            ...state,
            drivingRecord4: null
          });
          alert(t('update_data'))
        }
        else if (state.drivingRecord5) {  
          await dispatch(updateLicenseImage(data2[0], state.drivingRecord5, 'drivingRecord5', false));
          setState({
            ...state,
            drivingRecord5: null
          });
          alert(t('update_data'))
        }
        else if (state.drivingRecord6) {  
          await dispatch(updateLicenseImage(data2[0], state.drivingRecord6, 'drivingRecord6', false));
          setState({
            ...state,
            drivingRecord6: null
          });
          alert(t('update_data'))
        }
        else if (state.identityCard1) {  
          await dispatch(updateLicenseImage(data2[0], state.identityCard1, 'identityCard1', false));
          setState({
            ...state,
            identityCard1: null
          });
          alert(t('update_data'))
        }
        else if (state.identityCard2) {  
          await dispatch(updateLicenseImage(data2[0], state.identityCard2, 'identityCard2', false));
          setState({
            ...state,
            identityCard2: null
          });
          alert(t('update_data'))
        }
        dispatch(fetchUsersOnce());
      }
    } 
  }

  const [printModel, setPrintModel] = useState(false);
  const [printData, setPrintData] = useState();

  const handlePrintModelColse = (e) => {
    e.preventDefault();
    setPrintModel(false);
  };
  const componentRef = useRef();
  const rootRef = useRef(null);

  return (
    <div>
      <div className={classes.heading}>
        <Typography component="h1" variant="h5" style={{ marginTop: '8px', fontWeight: 'bolder', color: colors.Rigzie1, textDecoration: 'underline' }}>
          {t('documet') + ' ' + (document ? document : null) }
        </Typography>
      </div>
      <Button
        type="back"
        width='3%'
        variant="contained"
        className={classes.submit1}
        startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
        onClick={() => goBack()}
      >
        {t('go_back')}
      </Button>
      <Box boxShadow={6} m={2} style={{ borderRadius: 20, width: '100%', height: '90%' }}>
        <Grid container style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
          {document ===  t('mechanic_receipts') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('mechanic_receipts1')}
              </Typography>
              {state.mechanicReceipts1 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.mechanicReceipts1)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.carRegistration })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.mechanicReceipts1 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.mechanicReceipts1 ?
                    <div>
                      <img alt='mechanicReceipts1' src={data2[0].document.mechanicReceipts1} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.mechanicReceipts1);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.mechanicReceipts1 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, mechanicReceipts1: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }

          {document ===  t('mechanic_receipts') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('mechanic_receipts2')}
              </Typography>
              {state.mechanicReceipts2 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.mechanicReceipts2)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.drivingRecord })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.mechanicReceipts2 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.mechanicReceipts2 ?
                    <div>
                      <img alt='mechanicReceipts2' src={data2[0].document.mechanicReceipts2} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.mechanicReceipts2);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >
                    </div>
                    :
                    <div>
                      {!state.mechanicReceipts2 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, mechanicReceipts2: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
              </Box>
            </Grid>
            : null }

            {document ===  t('mechanic_receipts') ?
            <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('mechanic_receipts3')}
              </Typography>
              {state.mechanicReceipts3 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.mechanicReceipts3)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.identityCard })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.mechanicReceipts3 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.mechanicReceipts3 ?
                    <div>
                      <img alt='mechanicReceipts3' src={data2[0].document.mechanicReceipts3} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.mechanicReceipts3);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >
                    </div>
                    :
                    <div>
                      {!state.mechanicReceipts3 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, mechanicReceipts3: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }

          {/* police check */}

          {document ===  t('police_check') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('police_check1')}
              </Typography>
              {state.policeCheck1 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.policeCheck1)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.policeCheck1 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.policeCheck1 ?
                    <div>
                      <img alt='policeCheck1' src={data2[0].document.policeCheck1} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.policeCheck1);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.policeCheck1 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, policeCheck1: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }

          {document ===  t('police_check') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('police_check2')}
              </Typography>
              {state.policeCheck2 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.policeCheck2)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.policeCheck2 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.policeCheck2 ?
                    <div>
                      <img alt='policeCheck2' src={data2[0].document.policeCheck2} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.policeCheck2);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.policeCheck2 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, policeCheck2: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }

          {document ===  t('police_check') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('police_check3')}
              </Typography>
              {state.policeCheck3 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.policeCheck3)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.policeCheck3 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.policeCheck3 ?
                    <div>
                      <img alt='policeCheck3' src={data2[0].document.policeCheck3} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.policeCheck3);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.policeCheck3 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, policeCheck3: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }

          {/* Vehicle Insurance */}

          {document ===  t('vehicle_insurance') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('vehicle_insurance1')}
              </Typography>
              {state.insurance1 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.insurance1)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.insurance1 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.insurance1 ?
                    <div>
                      <img alt='insurance1' src={data2[0].document.insurance1} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.insurance1);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.insurance1 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, insurance1: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }
           {document ===  t('vehicle_insurance') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('vehicle_insurance2')}
              </Typography>
              {state.insurance2 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.insurance2)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.insurance2 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.insurance2 ?
                    <div>
                      <img alt='insurance2' src={data2[0].document.insurance2} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.insurance2);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.insurance2 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, insurance2: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }
           {document ===  t('vehicle_insurance') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('vehicle_insurance3')}
              </Typography>
              {state.insurance3 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.insurance3)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.insurance3 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.insurance3 ?
                    <div>
                      <img alt='insurance3' src={data2[0].document.insurance3} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.insurance3);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.insurance3 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, insurance3: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }

          {/* Vehicle Registration */}

          {document ===  t('vehicle_registration') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('vehicle_registration1')}
              </Typography>
              {state.carRegistration1 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.carRegistration1)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.carRegistration })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.carRegistration1 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.carRegistration1 ?
                    <div>
                      <img alt='carRegistration1' src={data2[0].document.carRegistration1} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.carRegistration1);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.carRegistration1 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, carRegistration1: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }

          {document ===  t('vehicle_registration') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('vehicle_registration2')}
              </Typography>
              {state.carRegistration2 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.carRegistration2)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.carRegistration })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.carRegistration2 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.carRegistration2 ?
                    <div>
                      <img alt='carRegistration2' src={data2[0].document.carRegistration2} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.carRegistration2);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.carRegistration2 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, carRegistration2: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }

          {/* identity_card */}

          {document ===  t('identity_card') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('identity_card1')}
              </Typography>
              {state.identityCard1 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.identityCard1)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.carRegistration })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.identityCard1 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.identityCard1 ?
                    <div>
                      <img alt='identityCard1' src={data2[0].document.identityCard1} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.identityCard1);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.identityCard1 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, identityCard1: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }
          {document ===  t('identity_card') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('identity_card2')}
              </Typography>
              {state.identityCard2 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.identityCard2)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.carRegistration })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.identityCard2 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.identityCard2 ?
                    <div>
                      <img alt='identityCard2' src={data2[0].document.identityCard2} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.identityCard2);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.identityCard2 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, identityCard2: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }

          {/* Driving Record */}

          {document ===  t('driving_record') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('driving_record1')}
              </Typography>
              {state.drivingRecord1 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.drivingRecord1)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.carRegistration })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.drivingRecord1 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.drivingRecord1 ?
                    <div>
                      <img alt='drivingRecord1' src={data2[0].document.drivingRecord1} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.drivingRecord1);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.drivingRecord1 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, drivingRecord1: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('driving_record4')}
              </Typography>
              {state.drivingRecord4 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.drivingRecord4)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.carRegistration })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.drivingRecord4 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.drivingRecord4 ?
                    <div>
                      <img alt='drivingRecord4' src={data2[0].document.drivingRecord4} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.drivingRecord4);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.drivingRecord4 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, drivingRecord4: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }

          {document ===  t('driving_record') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('driving_record2')}
              </Typography>
              {state.drivingRecord2 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.drivingRecord2)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.carRegistration })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.drivingRecord2 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.drivingRecord2 ?
                    <div>
                      <img alt='drivingRecord2' src={data2[0].document.drivingRecord2} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.drivingRecord2);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.drivingRecord2 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, drivingRecord2: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('driving_record5')}
              </Typography>
              {state.drivingRecord5 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.drivingRecord5)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.carRegistration })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.drivingRecord5 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.drivingRecord5 ?
                    <div>
                      <img alt='drivingRecord5' src={data2[0].document.drivingRecord5} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.drivingRecord5);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.drivingRecord5 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, drivingRecord5: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }

          {document ===  t('driving_record') ?
          <Grid item xs={4}>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('driving_record3')}
              </Typography>
              {state.drivingRecord3 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.drivingRecord3)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.carRegistration })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.drivingRecord3 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.drivingRecord3 ?
                    <div>
                      <img alt='drivingRecord3' src={data2[0].document.drivingRecord3} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.drivingRecord3);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.drivingRecord3 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, drivingRecord3: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
            <Box boxShadow={6}
              m={2}
              p={2} className={classes.box}>
              <Typography className={classes.txt}>
                {t('driving_record6')}
              </Typography>
              {state.drivingRecord6 ?
                <div>
                  <img alt="not fount" src={URL.createObjectURL(state.drivingRecord6)} style={{ height: 220, marginLeft: 20, width: '85%', marginTop: 10 }} />
                  <br />
                  {/* <button onClick={() => setState({ ...state.carRegistration })}>Remove</button> */}
                  <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state.drivingRecord6 })} >
                    {t('remove')}
                  </Button>

                  <Button type="submit" fullWidth variant="contained" className={classes.submit} onClick={submitValue} >
                    {t('submit')}
                  </Button>
                </div>
                :
                <div>
                  {data2[0] && data2[0].document && data2[0].document.drivingRecord6 ?
                    <div>
                      <img alt='drivingRecord6' src={data2[0].document.drivingRecord6} style={{ width: '100%', height: 230, borderRadius: 10 }} />
                      <IconButton
                        onClick={() => {
                          setPrintModel(true);
                          setPrintData(data2[0].document.drivingRecord6);
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        <LocalPrintshopIcon />
                      </IconButton >

                    </div>
                    :
                    <div>
                      {!state.drivingRecord6 ?
                        <div style={{ width: '100%', height: 250, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={camera} style={{ width: '30%', height: '60%', borderRadius: 10, color: 'red' }} alt="nice"/>
                        </div>
                        : null}
                    </div>
                  }
                  <div style={{ marginLeft: 10, display: 'flex' }}>
                    <Typography>
                      {t('upload_document')}</Typography>
                    <input
                      type="file"
                      name={t('image')}
                      style={{ marginLeft: 10 }}
                      onChange={(event) => {
                        setState({ ...state, drivingRecord6: event.target.files[0] });
                      }}
                    />
                  </div>
                </div>
              }
            </Box>
          </Grid>
          : null }

        </Grid>
      </Box>

      {printData ?
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={printModel}
          className={classes.modal}
          onClose={handlePrintModelColse}
          container={() => rootRef.current}
        >
          <Grid container spacing={1} className={classes.printPaper}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <PrintDoc ref={componentRef}
                image={printData}
              />
              <ReactToPrint
                content={() => componentRef.current}
                trigger={() => <Button variant="contained" color="success" style={{marginLeft:260}}>{'Print'}</Button>}
              />
              <Button onClick={handlePrintModelColse} variant="contained" style={{ marginLeft: 20 }}>{t('cancel')}</Button>
            </Grid>
          </Grid>
        </Modal>
        : null}

    </div>
  );
}