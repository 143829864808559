import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../components/Theme/WebTheme';
import Button from "components/CustomButtons/Button.js";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  Typography,
  MenuItem,
  Select, 
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  },
  submit:{
    backgroundColor:'#379ab2',
    width:'100%'
  },
  input:{
    width:'100%',
    marginTop:15
  },
  submit1:{
    backgroundColor:'White',
    color:'#4c63b8'
  },
  notchedOutline:{
    borderColor:colors.Rigzie1,
    width:'100%',
  }
}));

export default function AddPromo() {

const [data, setData] = useState({
        max_promo_discount_value: '',
        min_order: '',
        promo_description: '',
        promo_discount_type: '',
        promo_discount_value: '',
        promo_name: '',
        promo_validity: ''
});


const { t,i18n } = useTranslation();
const isRTL = i18n.dir();
const classes = useStyles();
const {
  editPromo
} = api;
const history = useHistory();
const dispatch = useDispatch();

const handleTextChange = (e) => {
  setData({ ...data, [e.target.name]: e.target.value });
};

const goBack=()=>{
  history.goBack();
}

const [promoType, setPromoType] = useState('Select Type');

const handlePromoTypeSelect = (event) => {
    setPromoType(event.target.value); 
}

const submitValue = () => {
  if( data.max_promo_discount_value && data.min_order && data.promo_description && data.promo_discount_value && data.promo_name && data.promo_validity && data.promo_usage_limit){
    if( promoType === 'Select Type'){
        alert('Please Select Promo Type');
    }
    else {
      let arr = {
      id:'',
      max_promo_discount_value: data.max_promo_discount_value,
      min_order: data.min_order,
      promo_description: data.promo_description,
      promo_discount_type: promoType,
      promo_discount_value: data.promo_discount_value,
      promo_name: data.promo_name,
      promo_validity: data.promo_validity,
      promo_usage_limit: data.promo_usage_limit,
      user_avail: 0
      };
      dispatch(editPromo(arr, 'Add'));
      setTimeout(() => {
        alert('New Promo Successfuly Upload.');
        setData({
          max_promo_discount_value: '',
          min_order: '',
          promo_description: '',
          promo_discount_type: '',
          promo_discount_value: '',
          promo_name: '',
          promo_validity: ''
        });
      }, 300);
    }
  } else {
    alert('Fill All Data Properly');
  }
}

  return (
    // data.loading ? <CircularLoading /> :
    <div>
      <div className={classes.heading}>
        <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
          {'Add New Promo'}
          </Typography>
      </div>

      <Button
        type="back"
        width='3%'
        variant="contained"
        className={classes.submit1}
        startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
        onClick={()=>goBack()}
      >
        {t('go_back')}
      </Button>
      <Box  boxShadow={6} m={2} p={2} style={{borderRadius:20,border: '2px solid #109496',width:'60%'}}>
        <CardContent>
          <Grid container style={{direction:isRTL ==='rtl'?'rtl':'ltr'}} >
            <Grid item xs={12} sm={12} md={6} lg={6} style={{padding: 10}}>
              <TextField
              className={isRTL ==="rtl"? classes.rootRtl:null}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                margin="normal"
                required
                fullWidth
                id="promo_name"
                label={ t('promo_name')}
                name="promo_name"
                autoComplete="promo_name"
                onChange={handleTextChange}
                defaultValue={data.promo_name}
              />
              <TextField
                className={isRTL ==="rtl"? classes.rootRtl:null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="promo_description"
                label={t('description')}
                name="promo_description"
                autoComplete="promo_description"
                onChange={handleTextChange}
                defaultValue={data.promo_description}
              />
              <TextField
                type="number"
                className={isRTL ==="rtl"? classes.rootRtl:null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="promo_discount_value"
                label={t('promo_discount_value')}
                name="promo_discount_value"
                autoComplete="promo_discount_value"
                onChange={handleTextChange}
                defaultValue={data.promo_discount_value}
              />
              <TextField
                type="number"
                className={isRTL ==="rtl"? classes.rootRtl:null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="max_promo_discount_value"
                label={t('max_limit')}
                name="max_promo_discount_value"
                autoComplete="max_promo_discount_value"
                onChange={handleTextChange}
                defaultValue={data.max_promo_discount_value}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} style={{padding: 10}}>
              <TextField
                type="number"
                className={isRTL ==="rtl"? classes.rootRtl_1:null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                id="min_order"
                label={t('min_limit')}
                name="min_order"
                autoComplete="min_order"
                onChange={handleTextChange}
                defaultValue={data.min_order}
              />
              <TextField
                className={isRTL ==="rtl"? classes.rootRtl_1:null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                id="promo_validity"
                label={t('end_date') + ' *DD/MM/YYYY' } 
                name="promo_validity"
                autoComplete="promo_validity"
                onChange={handleTextChange}
                defaultValue={data.promo_validity}
              />
              <TextField
                type="number"
                className={isRTL ==="rtl"? classes.rootRtl_1:null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                id="promo_usage_limit"
                label={t('promo_usage')}
                name="promo_usage_limit"
                autoComplete="promo_usage_limit"
                onChange={handleTextChange}
                defaultValue={data.promo_usage_limit}
              />
              <Select
                id="booking-type-native"
                value={promoType}
                onChange={handlePromoTypeSelect}
                className={classes.input}
                style={{textAlign:isRTL==='rtl'? 'right':'left'}}
                variant="outlined"
                fullWidth
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem  key={'Select Type'} value={'Select Type'} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                  {'Select Type'}
                </MenuItem>
                <MenuItem  key={"Percentage"} value={"percentage"} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                  {"Percentage"}
                </MenuItem>
                <MenuItem  key={"Flat"} value={"flat"} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                  {'Flat'}
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6} style={{padding: 10}}>
              <Button
                type="submit"
                variant="contained"
                className={classes.submit}
                onClick = {submitValue}
              >
                {t('submit')}
              </Button>
            </Grid>
          </Grid>
        {/* <AlertDialog open={settingsdata.error.flag && clicked} onClose={handleClose}>{t('update_failed')}</AlertDialog> */}
        </CardContent>
      </Box>
    </div>
  );
}