import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { api } from 'common';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Button from "components/CustomButtons/Button.js";
import { useTranslation } from "react-i18next";
import { Typography, Modal, Grid } from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import { colors } from '../components/Theme/WebTheme';
import DeleteIcon from '@material-ui/icons/Delete';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 780,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  heading:{
    marginBottom:10
  },
submit1:{
    color:'white',
    backgroundColor:colors.Rigzie1,
  },
  submit:{
    backgroundColor:'red',
    width:35,
    height:30,
    color:'white',
    borderRadius:5, 
  },
  submit2:{
    color:'white',
    backgroundColor:colors.Rigzie1,
    width:35,
    height:30,
    borderRadius:5,
  },
  car:{
    height:'100%',
    width:'50%',
    backgroundColor:'#dedede',
    // flexDirection:'row',
    marginTop:15,
    borderRadius:20,
    marginRight:25,
    alignItems:'center',
    position:'relative',
  },
  imgView:{
    alignSelf:'center',
    alignItems:'center',
    flexDirection:'row',
    margin:10,
  },
  txt:{
    padding:10,
    marginBottom:15
  },
  buton:{
    width:'100%',
    flexDirection:'row', 
    display:'flex',
    marginLeft:'70%',
  },
  deletepaper: {
    width: 590,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #009CA3',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10
  },
}));

export default function Subscription() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const history = useHistory();
  const {
    editSubscription
  } = api;
  const [data, setData] = useState([]);
  const subscriptiondata = useSelector(state => state.subscriptiondata);
  const dispatch = useDispatch();
  const classes = useStyles();
  

  useEffect(() => {
    if (subscriptiondata.subscriptions) {
        //console.log(subscriptiondata.subscriptions);
        setData(subscriptiondata.subscriptions);
    } else {
      setData([]);
    }
  }, [subscriptiondata.subscriptions]);

  // let allView = [];
  // for(let i=0; i<data.length; i++){
  //   allView.push(
  //     <div className={classes.car}>
  //       <div className={classes.imgView}>
  //         <Typography className={classes.txt1}>
  //           {data[i].subscription_name}
  //         </Typography>
  //         <div className={classes.buton} >
  //           <div onClick={()=>editcar(data[i], data[i].id)} className={classes.submit2} style={{marginRight:10,}}>
  //             <EditIcon icon={EditIcon} style={{margin:4}} />
  //           </div>
  //           <div className={classes.submit} onClick={()=>deleteItem(data[i], data[i].id)} style={{marginRight:10,}}>
  //             <DeleteIcon icon={DeleteIcon} style={{margin:5}}/>
  //           </div>
  //         </div>
  //       </div>
  //     <div>
  //       <Box  boxShadow={6} style={{borderRadius:20,backgroundColor:'white',flex:1}}>
  //         <CardContent>
  //           <Typography className={classes.txt}> {t('subscription_value') +":  "+ data[i].subscription_value} </Typography>
  //           <Typography className={classes.txt}> {t('extra_info') +":  "+ data[i].subscription_description} </Typography>
  //           <Typography className={classes.txt}> {t('subscription_days') +":  "+ data[i].subscription_days} </Typography>
  //         </CardContent>
  //       </Box> 
  //     </div>
  //   </div>  
  //   )
  // }

  // const deleteItem=(rowData, id)=>{
  //   rowData.id = id;
  //   alert(t('delete'))
  //   dispatch(editSubscription(rowData, 'Delete'));
  // }

  const editcar=(rowData, id)=>{
    history.push({
      pathname: '/editsubscription',
      state: { 
        detail: rowData ,
        id : id
      }
    });
  }

  const addcar=()=>{
    history.push({
      pathname: '/addsubscription'
    });
  }
  
  const [deleteModel, setDeleteModel] = useState(false);
  const [subcriptionDelete, setSubcriptioneDelete] = useState([]);

  const deleteSubcriptiont=(rowData)=>{
    setDeleteModel(true);
    setSubcriptioneDelete(rowData)
  }

  const closeDeleteModel=()=>{
    setDeleteModel(false);
  }

  const deleteSubcriptionData = () => {
    dispatch(editSubscription(subcriptionDelete, 'Delete'));
    setDeleteModel(false);
  }

  return (
    <div style={{overflow: 'auto'}}>
      <div className={classes.heading}>
        <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
          {t('subscription')}
        </Typography>
      </div>
      <div>
        <Box boxShadow={3} p={1} style={{width:'100%',}}>
          <Button
            type="back"
            width='3%'
            variant="contained"
            className={classes.submit1}
            onClick={()=>addcar()}>
              {t('add_subscription')}
          </Button>
        </Box>
      </div>
      <div style={{display:'flex',width:'100%',padding:10}}>
        {/* {
          allView
        } */}
        {data.map((prop, key)=>{
          return (
            <div className={classes.car} key={key}>
              <div className={classes.imgView}>
                <Typography className={classes.txt1}>
                  {prop.subscription_name}
                </Typography>
                <div className={classes.buton} >
                  <div onClick={()=>editcar(prop, prop.id)} className={classes.submit2} style={{marginRight:10,}}>
                    <EditIcon icon={EditIcon} style={{margin:4}} />
                  </div>
                  <div className={classes.submit} onClick={()=>deleteSubcriptiont(prop)} style={{marginRight:10,}}>
                    <DeleteIcon icon={DeleteIcon} style={{margin:5}}/>
                  </div>
                </div>
              </div>
            <div>
              <Box  boxShadow={6} style={{borderRadius:20,backgroundColor:'white',flex:1}}>
                <CardContent>
                  <Typography className={classes.txt}> {t('subscription_value') +":  "+ prop.subscription_value} </Typography>
                  <Typography className={classes.txt}> {t('extra_info') +":  "+ prop.subscription_description} </Typography>
                  <Typography className={classes.txt}> {t('subscription_days') +":  "+ prop.subscription_days} </Typography>
                </CardContent>
              </Box> 
            </div>
          </div> 
        );})} 
      </div>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={deleteModel}
        className={classes.modal}
      >
        <div className={classes.deletepaper}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Typography component="h1" variant="h5" className={classes.title} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                {t('delete_booking_message')}{t('cartype')} ?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', marginLeft: isRTL === 'rtl' ? '65%' : 0 }}>
              <Button onClick={closeDeleteModel} variant="contained" color="primary">
                {t('cancel')}
              </Button>
              <Button onClick={deleteSubcriptionData} variant="contained" color="primary" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }}>
                {t('yes')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
