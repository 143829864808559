import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Typography
} from '@material-ui/core';
import DashboardCard from '../components/DashboardCard';
import Map from '../components/Map';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Chart from 'react-apexcharts';
import { colors } from '../components/Theme/WebTheme';

const useStyles = makeStyles((theme) => ({
    heading: {
        width: '100%',
        display:'flex',  
    },
    heading7:{
        width: '37%',
        padding:10
    },
    heading1: {
        width: '63%',
        padding:10,
    },
    heading2: {
        width: '70%',
        padding: 5
    },
    heading3: {
        width: '30%',
        padding: 5,
        
    },
    heading4: {
        display: 'flex',
    },
    heading5: {
        display: 'flex',
        width: '100%',
    },
    heading6: {
        width: '15%',
        padding: 5
    },
    carVew: {
        display: 'flex',
     

    },
}));
const Dashboard = () => {
    const [mylocation, setMylocation] = useState(null);
    const [locations, setLocations] = useState([]);
    const [dailygross, setDailygross] = useState(0);
    const [monthlygross, setMonthlygross] = useState(0);
    const [totalgross, setTotalgross] = useState(0);

    const [settings, setSettings] = useState({});
    const [activeCount, setActiveCount] = useState(0);
    const [driverCount, setDriverCount] = useState(0);
    const [bspapprovedCount, setBspapprovedCount] = useState(0);
    const [pandingDriverCount, setPandingDriverCount] = useState(0);
    //const [todayTotalOffloadBook, setTodayTotalOffloadBook] = useState(0);
    //const [totalOffloadBook, setTotalOffloadBook] = useState(0);
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const usersdata = useSelector(state => state.usersdata);
    const bookinglistdata = useSelector(state => state.bookinglistdata);
    const settingsdata = useSelector(state => state.settingsdata);
    const auth = useSelector(state => state.auth);
    const cars = useSelector(state => state.cartypes.cars);
    const classes = useStyles();

    const [allCars, setAllCars] = useState([]);
    const [allCompleteCount, setAllCompleteCount] = useState([]);
    const [allCancelCount, setAllCancelCount] = useState([]);  

    useEffect(() => {
        if (mylocation === null) {
            setMylocation({
                lat: 33.865143,
                lng: 151.209900
            });
        }
    }, [mylocation]);

    useEffect(() => {
        if (settingsdata.settings) {
            setSettings(settingsdata.settings);
        }
    }, [settingsdata.settings]);

    useEffect(() => {
        let arr = [];
        let today = new Date();
        if (bookinglistdata.bookings) {
            for (let i = 0; i < cars.length; i++) {
                let count = 0;
                for (let j = 0; j < bookinglistdata.bookings.length; j++) {
                    const { tripdate } = bookinglistdata.bookings[j];
                    let tDate = new Date(tripdate);
                    if ((tDate.getDate() === today.getDate()) && cars[i].name === bookinglistdata.bookings[j].carType && (bookinglistdata.bookings[j].status === 'PAID' || bookinglistdata.bookings[j].status === 'COMPLETE')) {
                        count = count + 1;
                    }
                }
                arr.push({
                    name: cars[i].name,
                    image: cars[i].image,
                    bookingCount: count
                });
            }
        } else {
            for (let i = 0; i < cars.length; i++) {
                let count = 0;
                arr.push({
                    name: cars[i].name,
                    image: cars[i].image,
                    bookingCount: count
                });
            }
        }
        setAllCars(arr);
    }, [cars, bookinglistdata.bookings]);

    useEffect(() => {
        let allCompleteCount =[];
        let allCancelCount =[];
        let months  = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        if (bookinglistdata.bookings) {
            for (let i = 0; i < months.length; i++) {
                let completeCount = 0;
                let cancelCount = 0;
                for (let j = 0; j < bookinglistdata.bookings.length; j++) {
                    const { tripdate } = bookinglistdata.bookings[j];
                    let tDate = new Date(tripdate);
                    if ( months[i] === months[tDate.getMonth()] && (bookinglistdata.bookings[j].status === 'PAID' || bookinglistdata.bookings[j].status === 'COMPLETE')) {
                        completeCount = completeCount + 1;
                    }
                    if ( months[i] === months[tDate.getMonth()] &&  bookinglistdata.bookings[j].status === 'CANCELLED') {
                        cancelCount = cancelCount + 1;
                    }
                }
                allCompleteCount.push(completeCount);
                allCancelCount.push( cancelCount);
            }
        }
        setAllCompleteCount(allCompleteCount);
        setAllCancelCount(allCancelCount);
    }, [bookinglistdata.bookings]);


    useEffect(() => {
        if (usersdata.users) {
            let driverCount = 0;
            let activeCount = 0;
            let pandingDriverCount = 0;
            let bspapprovedCount = 0;
            for (let i = 0; i < usersdata.users.length; i++) {
                if (usersdata.users[i].usertype === 'driver') {
                    driverCount = driverCount + 1;
                }
                if (usersdata.users[i].driverActiveStatus === true) {
                    activeCount = activeCount + 1;
                }
                if (usersdata.users[i].approved === false) {
                    pandingDriverCount = pandingDriverCount + 1;
                }
                if (usersdata.users[i].bspapproved === true) {
                    bspapprovedCount =  bspapprovedCount + 1;
                }
            }
            setActiveCount(activeCount);
            setDriverCount(driverCount);
            setPandingDriverCount(pandingDriverCount);
            setBspapprovedCount(bspapprovedCount);
        } else {
            setActiveCount(0)
            setDriverCount(0)
            setPandingDriverCount(0)
            setBspapprovedCount(0)
        }
    }, [usersdata.users]);

    useEffect(() => {
        if (bookinglistdata.bookings) {
            let today = new Date();
            let convenniencefees = 0;
            let totconvenienceTrans = 0;
            let todayConvenience = 0;
            let todayTotalOffloadBook = 0;
            let totalOffloadBook = 0;
            for (let i = 0; i < bookinglistdata.bookings.length; i++) {
                if (bookinglistdata.bookings[i].status === 'PAID' || bookinglistdata.bookings[i].status === 'COMPLETE') {
                    const { tripdate, convenience_fees } = bookinglistdata.bookings[i];
                    let tDate = new Date(tripdate);
                    if (convenience_fees > 0) {

                        if (tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()) {
                            convenniencefees = convenniencefees + parseFloat(convenience_fees);
                        }
                        if (tDate.getDate() === today.getDate() && tDate.getMonth() === today.getMonth()) {
                            todayConvenience = todayConvenience + parseFloat(convenience_fees);
                        }
                        totconvenienceTrans = totconvenienceTrans + parseFloat(convenience_fees);
                    }

                    if (bookinglistdata.bookings[i].offLoad === true) {
                        totalOffloadBook = totalOffloadBook + 1;
                    }

                    if ((tDate.getDate() === today.getDate()) && (bookinglistdata.bookings[i].offLoad === true)) {
                        todayTotalOffloadBook = todayTotalOffloadBook + 1;
                    }
                }
            }
            setDailygross(parseFloat(todayConvenience).toFixed(settings.decimal));
            setMonthlygross(parseFloat(convenniencefees).toFixed(settings.decimal));
            setTotalgross(parseFloat(totconvenienceTrans).toFixed(settings.decimal));
            //setTodayTotalOffloadBook(todayTotalOffloadBook);
            //setTotalOffloadBook(totalOffloadBook);
        }
    }, [bookinglistdata.bookings, settings]);

    useEffect(() => {
        if (usersdata.users) {
            const drivers = usersdata.users.filter(user => user.usertype === 'driver' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin') || auth.info.profile.usertype === 'admin'));
            let locs = [];
            for (let i = 0; i < drivers.length; i++) {
                if (drivers[i].approved && drivers[i].driverActiveStatus && drivers[i].location) {
                    let carImage;
                    for (let j = 0; j < cars.length; j++) {
                        if (cars[j].name === drivers[i].carType) {
                            carImage = cars[j].image;
                        }
                    }
                    locs.push({
                        id: i,
                        lat: drivers[i].location.lat,
                        lng: drivers[i].location.lng,
                        drivername: drivers[i].firstName + ' ' + drivers[i].lastName,
                        carnumber: drivers[i].vehicleNumber,
                        cartype: drivers[i].carType,
                        carImage: carImage
                    });
                }
            }
            setLocations(locs);
        }
    }, [usersdata.users, auth.info.profile, auth.info.uid, cars]);

    useEffect(() => {
        if (bookinglistdata.bookings) {
            let today = new Date();
            let convenniencefees = 0;
            let totconvenienceTrans = 0;
            let todayConvenience = 0;
            for (let i = 0; i < bookinglistdata.bookings.length; i++) {
                if (bookinglistdata.bookings[i].status === 'PAID' || bookinglistdata.bookings[i].status === 'COMPLETE') {
                    const { tripdate, convenience_fees } = bookinglistdata.bookings[i];
                    let tDate = new Date(tripdate);
                    if (convenience_fees > 0) {

                        if (tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()) {
                            convenniencefees = convenniencefees + parseFloat(convenience_fees);
                        }
                        if (tDate.getDate() === today.getDate() && tDate.getMonth() === today.getMonth()) {
                            todayConvenience = todayConvenience + parseFloat(convenience_fees);
                        }
                        totconvenienceTrans = totconvenienceTrans + parseFloat(convenience_fees);
                    }
                }
            }
            setDailygross(parseFloat(todayConvenience).toFixed(settings.decimal));
            setMonthlygross(parseFloat(convenniencefees).toFixed(settings.decimal));
            setTotalgross(parseFloat(totconvenienceTrans).toFixed(settings.decimal));
        }
    }, [bookinglistdata.bookings, settings]);

    let carView = [];
    for (let i = 0; i < allCars.length; i++) {
        carView.push(
            <div>
                <Box style={{ borderRadius: 8, width: '85%' }}
                    boxShadow={3}
                    m={2}>
                    <CardContent>
                        <div className={classes.carVew}>
                            <div >
                                <Typography>
                                    {allCars[i].name}: {allCars[i].bookingCount}
                                </Typography>
                                <img alt='Car' src={allCars[i].image} style={{ width: 70, height: 60, }} />
                            </div>
                            {/* <div>
                                <Typography style={{ marginTop: 40, }}>{allCars[i].bookingCount}</Typography>
                            </div> */}

                        </div>
                    </CardContent>
                </Box>
            </div>
        )
    }

    const paiChartOptions = {
        labels: [t('approved_driver'), t('pending_driver')],
        colors:["#109496","#27254c"],
        plotOptions: {
            pie: {
                expandOnclick: true,
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: {driverCount},
                            showAlways: true,
                        }
                    }
                }
            }
        }
    };
    const paiChart = [(driverCount - pandingDriverCount),pandingDriverCount];

    const barChartOptions = {
          labels: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
          colors:["#109496","#f28e8e"],
          options: {
              maintainAspectRatio:false,
              scales:{
                  yAxes:[
                      {
                          ticks:{
                              beginAtZero:true,
                          },
                      },
                  ],
              },
            chart: {
              height: 350,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
          },   
    };

    //Completed Bookings and Cancelled Bookings

    const barChart =[{
        name: t('completed_bookings'),
        data: allCompleteCount
    }, {
        name: t('cancelled_bookings'),
        data: allCancelCount
    }];

    return (
        bookinglistdata.loading || usersdata.loading ? <CircularLoading /> :
        <div>
            <div className={classes.heading5}>
                <div className={classes.heading2}>
                <Box style={{ borderRadius: 15, overflow: 'hidden', width: '100%',maxHeight:218,height:218 }}
                    boxShadow={5}
                    m={2}>
                    <Typography variant="h6" style={{ margin: "10px 10px 0 15px", textAlign: isRTL === 'rtl' ? 'right' : 'left', color: colors.Rigzie1 }}>{t('gross_earning')}</Typography>
                    <CardContent >
                        <Grid item xs={12} >
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    {settings.swipe_symbol === false ?
                                        <DashboardCard title={t('today_text')} image={require("../assets/img/money1.jpg").default}>{settings.symbol + ' ' + dailygross}</DashboardCard>
                                        :
                                        <DashboardCard title={t('today_text')} image={require("../assets/img/money1.jpg").default}>{dailygross + ' ' + settings.symbol}</DashboardCard>
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    {settings.swipe_symbol === false ?
                                        <DashboardCard title={t('monthly')} image={require("../assets/img/money2.jpg").default}>{settings.symbol + ' ' + monthlygross}</DashboardCard>
                                        :
                                        <DashboardCard title={t('monthly')} image={require("../assets/img/money2.jpg").default}>{monthlygross + ' ' + settings.symbol}</DashboardCard>
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    {settings.swipe_symbol === false ?
                                        <DashboardCard title={t('total')} image={require("../assets/img/money3.jpg").default}>{settings.symbol + ' ' + totalgross}</DashboardCard>
                                        :
                                        <DashboardCard title={t('total')} image={require("../assets/img/money3.jpg").default}>{totalgross + ' ' + settings.symbol}</DashboardCard>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>

                    </CardContent>
                </Box>
                </div>
                <div className={classes.heading3}>
                    <Box style={{ borderRadius: 15, overflowX:'scroll', width: '100%',maxHeight:218,height:218, overflowY: 'hidden' }} boxShadow={5} m={2}>
                        <Typography variant="h6" style={{ margin: "10px 10px 0 15px", textAlign: isRTL === 'rtl' ? 'right' : 'left', color: colors.Rigzie1 }}>{t('today_ride')}</Typography>
                        <div className={classes.heading4}>
                            {
                                carView
                            }
                        </div>
                    </Box>
                </div>
                <div className={classes.heading6}>
                    <Box style={{ borderRadius: 15, overflowY:'scroll', overflowX: 'hidden', width: '100%',maxHeight:218, height:218 }} boxShadow={5} m={2}>
                        <Typography variant="h6" style={{ margin: "10px 10px 0 15px", textAlign: isRTL === 'rtl' ? 'right' : 'left', color: colors.Rigzie1 }}>{t('drivers')}</Typography>
                        <CardContent>
                            <div style={{ display: 'flex', padding: 5}}>
                                <Typography>{t('total_drivers')}: {driverCount}</Typography>
                                {/* <Typography style={{ marginLeft: 10 }}>{driverCount}</Typography> */}
                            </div>
                            <div style={{ display: 'flex', padding: 5}}>
                                <Typography>{t('active_driver')}: {activeCount}</Typography>
                                {/* <Typography style={{ marginLeft: 10 }}>{activeCount}</Typography> */}
                            </div>
                            <div style={{ display: 'flex', padding: 5}}>
                                <Typography>{t('offloader')}: {bspapprovedCount}</Typography>
                                {/* <Typography style={{ marginLeft: 10 }}></Typography> */}
                            </div>
                        </CardContent>
                    </Box>
                </div>
               
            </div>
            <div className={classes.heading}>
                <div className={classes.heading1}>
                    {mylocation ?
                        <Box style={{ borderRadius: 20, overflow: 'hidden', width: '100%', backgroundColor: colors.Rigzie1}} boxShadow={6} m={2}>
                            <Typography variant="h6" style={{ margin: "10px 10px 0 15px", textAlign: isRTL === 'rtl' ? 'right' : 'left', backgroundColor: colors.Rigzie1, color: 'white' }}>{t('real_time_driver_section_text')}</Typography>
                            <Map mapcenter={mylocation} locations={locations}
                                loadingElement={<div style={{ height: `370px` }} />}
                                containerElement={<div style={{ height: `370px` }} />}
                                mapElement={<div style={{ height: `370px` }} />}
                            />
                        </Box>
                        :
                        <Typography variant="h6" style={{ margin: "20px 0 0 15px", color: '#FF0000' }}>{t('allow_location')}</Typography>
                    }
                </div>
                <div className={classes.heading7}>
                    <Box style={{ borderRadius: 20, overflow: 'hidden', width: '100%',height:407,maxHeight:407}} boxShadow={6} m={2}>
                        <Typography variant="h6" style={{ margin: "10px 10px 0 15px", textAlign: isRTL === 'rtl' ? 'right' : 'left', color: colors.Rigzie1 }}>{t('driver_approved')}</Typography>
                        <div style={{height:'80%',alignContent:'center',marginTop:60}}>
                        {paiChart[0] === 0 && paiChart[1] === 0 ?
                            <Typography variant="h6" style={{ margin: "10px 10px 0 15px", textAlign: 'center', color: colors.Rigzie1, marginTop: 150 }}>{t('add_new_driver')}</Typography>
                            :
                            <Chart
                                options={paiChartOptions}
                                series={paiChart}
                                type="donut"
                                width={380}
                                height={400}
                            />
                        }
                        </div>
                    </Box>
                </div>
            </div>
            <Box style={{ borderRadius: 20, overflow: 'hidden', width: '100%',height:407}} boxShadow={6} m={2}>
                <Typography variant="h6" style={{ margin: "10px 10px 0 15px", textAlign: isRTL === 'rtl' ? 'right' : 'left', color: colors.Rigzie1 }}>{t('trip_status')}</Typography>
                {allCompleteCount === 0 && allCancelCount ===0 ?
                   
                    <Typography variant="h6" style={{ margin: "10px 10px 0 15px", textAlign: 'center', color: colors.Rigzie1, marginTop: 150 }}>{t('no_bookings')}</Typography>
                   :
                   <CardContent style={{padding:10}}>
                   <Chart
                       options={barChartOptions}
                       series={barChart}
                       type="area"
                       width="100%"
                       height={300}
                   />
                    </CardContent>
                }
            </Box>
        </div>
    )
}

export default Dashboard;