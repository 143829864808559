import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  Typography,
  MenuItem,
  Select,
} from '@material-ui/core';
import { colors } from '../components/Theme/WebTheme';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 20
  },
  submit: {
    backgroundColor: '#379ab2',
    width: '95%',
    marginTop: 50
  },
  submit1: {
    backgroundColor: 'White',
    color: '#4c63b8'
  },
  notchedOutline: {
    borderColor: colors.Rigzie1,
    width: '95%',
  },
  carphotoRtl: {
    height: '16px',
    marginLeft: '10px'
  },
  carphoto: {
    height: '18px',
    marginRight: '10px'
  },
  input: {
    width: '95%',
    marginTop: 15
  }
}));

export default function EditDriver() {

  const location = useLocation();
  const getId = location.state.detail;
  const [data, setData] = useState({
    firstName: getId.firstName,
    lastName: getId.lastName,
    mobile: getId.mobile,
    email: getId.email,
    licenceno: getId.licenceno ? getId.licenceno : '',
    vehicleNumber: getId.vehicleNumber,
    vehicleModel: getId.vehicleModel,
    vehicleMake: getId.vehicleMake,
    carType: getId.carType,
    bankCode: getId.bankCode ? getId.bankCode : '',
    bankName: getId.bankAccount ? getId.bankAccount : '',
    bankAccount: getId.bankAccount ? getId.bankAccount : '',
    other_info: getId.other_info ? getId.other_info : '',
    approved: getId.approved,
    bspapproved: getId.bspapproved ? getId.bspapproved : false ,
    queue: getId.queue ? getId.queue : false
  });


  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const cartypes = useSelector(state => state.cartypes.cars);
  const {
    editUser
  } = api;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const goBack = () => {
    history.goBack();
  }

  const [carTypeSelect, setCarTypeSelect] = useState(getId.carType);
  const [carCapacity, setCarCapacity] = useState(null);

  const handleCarTypeSelect = (event) => {
    setCarTypeSelect(event.target.value);
    for (let i = 0; i < cartypes.length; i++) {
      if(cartypes[i].name === event.target.value){
        setCarCapacity(cartypes[i].capacity);
      }
    }
  }

  const [approvedType, setApprovedType] = useState(getId.approved);

  const handleApprovedTypeSelect = (event) => {
    setApprovedType(event.target.value);
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const emailValid = re.test(email)
    return emailValid;
}

    const submitValue = () => {
      if(data.firstName && data.firstName.length > 1 && data.lastName && data.lastName.length > 1){
        if(data.mobile){
          if(validateEmail(data.email)){
            if (carTypeSelect !== t('select_car')){
              if( data.vehicleNumber && data.vehicleModel && data.vehicleMake){
                if(data.licenceno){
                  if(data.bankCode && data.bankName && data.bankAccount) {
                    let arr = {
                      ...getId,
                      firstName: data.firstName,
                      lastName: data.lastName,
                      mobile: data.mobile,
                      email: data.email,
                      licenceno: data.licenceno,
                      vehicleNumber: data.vehicleNumber,
                      vehicleModel: data.vehicleModel,
                      vehicleMake: data.vehicleMake,
                      carType: carTypeSelect,
                      capacity: carCapacity ? carCapacity : getId.capacity,
                      bankCode: data.bankCode,
                      bankName: data.bankName,
                      bankAccount: data.bankAccount,
                      other_info: data.other_info,
                      approved: approvedType,
                      bspapproved: bspApprovedType,
                      queue: data.queue
                    };
                    if (getId.notifications) {
                      arr.notifications = getId.notifications;
                    }
                    if (getId.vehicles) {
                      arr.vehicles = getId.vehicles;
                    }
                    if (getId.walletHistory) {
                      arr.walletHistory = getId.walletHistory;
                    }
                    if (getId.ratings) {
                      arr.ratings = getId.ratings;
                    }
                    if (getId.document) {
                      arr.document = getId.document;
                    }
                    dispatch(editUser(getId.id, arr));
                    // if (selectedImage) {
                    //   dispatch(updateLicenseImage(getId, selectedImage))
                    // }
                  alert('User Update Successfuly');
                  }else{
                    alert(t('proper_input_bank_details'));
                  }
                }else{
                  alert(t('licence_blank_error'));
                }
              }else{
                alert(t('proper_input_vehicle_info'));
              }
            }else {
              alert(t('car_type_blank_error'));
            }
          }else{
            alert(t('valid_email_check'));
          }
        }else{
          alert(t('mobile_no_blank_error'));
        }
      }else{
        alert(t('name_blank_error'));
      }
    }

    const [bspApprovedType, setBspApprovedType] = useState(data.bspapproved);

    const handleBspApprovedTypeSelect = (event) => {
      setBspApprovedType(event.target.value);
    };

    return (
      // data.loading ? <CircularLoading /> :
      <div>
        <div className={classes.heading}>
          <Typography component="h1" variant="h5" style={{ marginTop: '8px', fontWeight: 'bolder', color: colors.Rigzie1, textDecoration: 'underline' }}>
            {t('edit_driver')}
          </Typography>
        </div>
        <Button
          type="back"
          width='3%'
          variant="contained"
          className={classes.submit1}
          startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
          onClick={() => goBack()}
        >
          {t('go_back')}
        </Button>
        <Box boxShadow={6}

          m={2}
          p={2} style={{ borderRadius: 20, border: '2px solid #109496', width: '70%' }}>
          <CardContent>

            <Grid container style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }} >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography component="h1" variant="h6">
                  {t('first_name')}
                </Typography>
                <TextField
                  className={isRTL === "rtl" ? classes.rootRtl : null}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label={t('first_name')}
                  name="firstName"
                  autoComplete="firstName"
                  onChange={handleTextChange}
                  defaultValue={data.firstName}
                />
                <Typography component="h1" variant="h6">
                  {t('last_name')}
                </Typography>
                <TextField
                  className={isRTL === "rtl" ? classes.rootRtl : null}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  label={t('last_name')}
                  name="lastName"
                  autoComplete="lastName"
                  onChange={handleTextChange}
                  defaultValue={data.lastName}
                />
                <Typography component="h1" variant="h6">
                  {t('mobile')}
                </Typography>
                <TextField
                  className={isRTL === "rtl" ? classes.rootRtl : null}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="mobile"
                  label={t('mobile')}
                  name="mobile"
                  autoComplete="mobile"
                  onChange={handleTextChange}
                  defaultValue={data.mobile}
                />
                <Typography component="h1" variant="h6">
                  {t('email')}
                </Typography>
                <TextField
                  className={isRTL === "rtl" ? classes.rootRtl : null}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t('email')}
                  name="email"
                  autoComplete="email"
                  onChange={handleTextChange}
                  defaultValue={data.email}
                />
                <Typography component="h1" variant="h6">
                  {t('car_type')}
                </Typography>
                <Select
                  id="booking-type-native"
                  value={carTypeSelect}
                  onChange={handleCarTypeSelect}
                  className={classes.input}
                  style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
                  variant="outlined"
                  fullWidth
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={t('select_car')} key={t('select_car')} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                    {t('select_car')}
                  </MenuItem>
                  {
                    cartypes.map((car) =>
                      <MenuItem key={car.name} value={car.name} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                        <img src={car.image} className={isRTL === 'rtl' ? classes.carphotoRtl : classes.carphoto} alt="car types" />{car.name}
                      </MenuItem>
                    )
                  }
                </Select>
                <Typography component="h1" variant="h6" style={{marginTop:10}}>
                  {t('vehicle_model_name')}
                </Typography>
                <TextField
                  className={isRTL === "rtl" ? classes.rootRtl_1 : null}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="vehicleMake"
                  label={t('vehicle_model_name')}
                  name="vehicleMake"
                  autoComplete="vehicleMake"
                  onChange={handleTextChange}
                  defaultValue={data.vehicleMake}
                />
                <Typography component="h1" variant="h6">
                  {t('vehicle_name')}
                </Typography>
                <TextField
                  className={isRTL === "rtl" ? classes.rootRtl_1 : null}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="vehicleModel"
                  label={t('vehicle_name')}
                  name="vehicleModel"
                  autoComplete="vehicleModel"
                  onChange={handleTextChange}
                  defaultValue={data.vehicleModel}
                />
                <Typography component="h1" variant="h6">
                  {t('vehicle_reg_no')}
                </Typography>
                <TextField
                  className={isRTL === "rtl" ? classes.rootRtl_1 : null}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="vehicleNumber"
                  label={t('vehicle_reg_no')}
                  name="vehicleNumber"
                  autoComplete="vehicleNumber"
                  onChange={handleTextChange}
                  defaultValue={data.vehicleNumber}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography component="h1" variant="h6">
                  {t('licence_no')}
                </Typography>
                <TextField
                  className={isRTL === "rtl" ? classes.rootRtl_1 : null}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="licenceno"
                  label={t('licence_no')}
                  name="licenceno"
                  autoComplete="licenceno"
                  onChange={handleTextChange}
                  defaultValue={data.licenceno}
                />
                <Typography component="h1" variant="h6">
                  {t('approved_status')}
                </Typography>
                <Select
                  id="booking-type-native"
                  value={approvedType}
                  onChange={handleApprovedTypeSelect}
                  className={classes.input}
                  style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}
                  variant="outlined"
                  fullWidth
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem key={"Approved"} value={true} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                    {t('Approved')}
                  </MenuItem>
                  <MenuItem key={"Pending"} value={false} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                    {t('Pending')}
                  </MenuItem>
                </Select>
                <Typography component="h1" variant="h6" style={{marginTop:10}}>
             {t('bsp_approved')}
            </Typography>
            <Select
              id="booking-type-native"
              value={bspApprovedType}
              onChange={handleBspApprovedTypeSelect}
              className={classes.input}
              style={{textAlign:isRTL==='rtl'? 'right':'left'}}
              variant="outlined"
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem  key={"Approved"} value={true} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                {t('approved')}
              </MenuItem>
              <MenuItem  key={"Pending"} value={false} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
                {t('pending')}
              </MenuItem>
            </Select>
                <Typography component="h1" variant="h6" style={{ marginTop: 10 }}>
                  {t('bankName')}
                </Typography>
                <TextField
                  className={isRTL === "rtl" ? classes.rootRtl_1 : null}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="bankName"
                  label={t('bankName')}
                  name="bankName"
                  autoComplete="bankName"
                  onChange={handleTextChange}
                  defaultValue={data.bankName}
                />
                <Typography component="h1" variant="h6">
                  {t('bankCode')}
                </Typography>
                <TextField
                  className={isRTL === "rtl" ? classes.rootRtl_1 : null}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="bankCode"
                  label={t('bankCode')}
                  name="bankCode"
                  autoComplete="bankCode"
                  onChange={handleTextChange}
                  defaultValue={data.bankCode}
                />
                <Typography component="h1" variant="h6">
                  {t('bankAccount')}
                </Typography>
                <TextField
                  className={isRTL === "rtl" ? classes.rootRtl_1 : null}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="bankAccount"
                  label={t('bankAccount')}
                  name="bankAccount"
                  autoComplete="bankAccount"
                  onChange={handleTextChange}
                  defaultValue={data.bankAccount}
                />
                <Typography component="h1" variant="h6">
                  {t('other_info')}
                </Typography>
                <TextField
                  className={isRTL === "rtl" ? classes.rootRtl_1 : null}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="other_info"
                  label={t('other_info')}
                  name="other_info"
                  autoComplete="other_info"
                  onChange={handleTextChange}
                  defaultValue={data.other_info}
                />

                <Button
                  type="submit"
                  width='45%'
                  variant="contained"
                  className={classes.submit}
                  onClick={() => submitValue()}
                >
                  {t('submit')}
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
               
              </Grid>
            </Grid>
            {/* <AlertDialog open={settingsdata.error.flag && clicked} onClose={handleClose}>{t('update_failed')}</AlertDialog> */}
          </CardContent>
        </Box>
      </div>
    );
  }