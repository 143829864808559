import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { colors } from '../components/Theme/WebTheme';
const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 20
  },
  submit: {
    backgroundColor: '#379ab2'
  },
  submit1: {
    backgroundColor: 'White',
    color: '#4c63b8'
  },
  notchedOutline: {
    borderColor: colors.Rigzie1,
    width: '95%',
  },
}));

export default function EditRider() {

  const location = useLocation();
  const getId = location.state.detail;;

  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const {
    editUser
  } = api;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const [data, setData] = useState({
    firstName: getId.firstName,
    lastName: getId.lastName,
    email: getId.email,
    mobile: getId.mobile
  });

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const emailValid = re.test(email)
    return emailValid;
}
  
  //console.log(data);
  const submitValue = () => {
    if(data.firstName && data.firstName.length > 1 && data.lastName && data.lastName.length > 1){
      if(validateEmail(data.email)){
        let arr = {
          ...getId,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
        };
        dispatch((editUser(getId.id, arr)));
        alert('User Update Successfuly');
      }else{
        alert(t('valid_email_check'));
      }
  }else{
    alert(t('name_blank_error'));
  }
  }
  //console.log(data)

  const goBack = () => {
    history.goBack();
  }

  return (
    // data.loading ? <CircularLoading /> :
    <div>
      <div className={classes.heading}>
        <Typography component="h1" variant="h5" style={{ marginTop: '8px', fontWeight: 'bolder', color: colors.Rigzie1, textDecoration: 'underline' }}>
          {t('edit_rider')}
        </Typography>
      </div>
      <Button
        type="back"
        width='3%'
        variant="contained"
        className={classes.submit1}
        startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
        onClick={() => goBack()}
      >
        {t('go_back')}
      </Button>
      <Box boxShadow={6}
        m={2}
        p={2} style={{ borderRadius: 20, border: '2px solid #109496', width: '60%', height: '90%' }}>
        <CardContent>
          <Grid container style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }} >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography component="h1" variant="h6">
                {t('first_name')}
              </Typography>
              <TextField
                className={isRTL === "rtl" ? classes.rootRtl : null}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                margin="normal"
                required
                fullWidth
                id="firstName"
                label={t('first_name')}
                name="firstName"
                autoComplete="firstName"
                onChange={handleTextChange}
                defaultValue={data.firstName}
              />
              <Typography component="h1" variant="h6">
                {t('last_name')}
              </Typography>
              <TextField
                className={isRTL === "rtl" ? classes.rootRtl : null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                label={t('last_name')}
                name="lastName"
                autoComplete="lastName"
                onChange={handleTextChange}
                defaultValue={data.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography component="h1" variant="h6">
                {t('mobile')}
              </Typography>
              <TextField
                className={isRTL === "rtl" ? classes.rootRtl : null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="mobile"
                label={t('mobile')}
                name="mobile"
                disabled={getId.mobile === ' ' ? false : true}
                autoComplete="mobile"
                onChange={handleTextChange}
                defaultValue={data.mobile}
              />
              <Typography component="h1" variant="h6">
                {t('email')}
              </Typography>
              <TextField
                className={isRTL === "rtl" ? classes.rootRtl : null}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('email')}
                name="email"
                disabled={getId.email === ' ' ? false : true}
                autoComplete="email"
                onChange={handleTextChange}
                defaultValue={data.email}

              />
            </Grid>
            <Button
              type="submit"
              width='45%'
              variant="contained"
              className={classes.submit}
              onClick={() => submitValue()}
            >
              {t('save')}
            </Button>
          </Grid>
          {/* <AlertDialog open={settingsdata.error.flag && clicked} onClose={handleClose}>{t('update_failed')}</AlertDialog> */}
        </CardContent>
      </Box>
    </div>
  );
}