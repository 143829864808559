import React,{ useState, useEffect,useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from "components/CustomButtons/Button.js";
import { colors } from '../components/Theme/WebTheme';
import {
  Typography, 
  Modal,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  deletepaper: {
    width: 590,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #009CA3',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10
  },
  heading:{
   marginBottom:20,
  height:'10%'
  },
  submit3:{
    backgroundColor:'#019b9d',
    width:'100%',
    borderRadius:7,
    marginTop:5,
    padding:3
  },
  submit4:{
    backgroundColor:'red',
    width:'100%',
    borderRadius:7,
    marginTop:5,
    padding:3
  },
  button: {
    backgroundColor: colors.Rigzie1,
    color: '#fff',
    fontWeight:'bolder',
    marginLeft:15,
    '&:hover': {
      backgroundColor: '#0b696a',
      color: '#fff',
  }
}
}));
export default function Users() {
  const history = useHistory();

  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const {
    deleteUser,
    fetchUsersOnce
  } = api;
  const [data, setData] = useState([]);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const classes = useStyles();
  useEffect(()=>{
    dispatch(fetchUsersOnce());
},[dispatch,fetchUsersOnce]);

  useEffect(()=>{
    if(staticusers){
      setData(staticusers.filter(user => user.usertype ==='admin'));
    }else{
      setData([]);
    }
    loaded.current = true;
  },[staticusers]);

  const columns = [
    { title: t('first_name'), field: 'firstName', initialEditValue: ''},
    { title: t('last_name'), field: 'lastName', initialEditValue: ''},
    { title: t('email'), field: 'email', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ?rowData.email : "Hidden for Demo",},
    { title: t('mobile'), field: 'mobile', editable: 'onAdd', render: rowData => settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo", },
    { title: t('account_approval_status'),  field: 'approved', type:'boolean', initialEditValue: true,}
  ];
  const addNew=()=>{
    history.push({
      pathname: '/addadmin'
    });
  }

  const [deleteModel, setDeleteModel] = useState(false);
  const [adminDelete, setAdminDelete] = useState([]);

  const deleteSingleAdmin=(rowData)=>{
    setDeleteModel(true);
    setAdminDelete(rowData)
  }

  const closeDeleteModel=()=>{
    setDeleteModel(false);
  }

  const deleteAdminData = () => {
    dispatch(deleteUser(adminDelete.id));
    setDeleteModel(false);
    dispatch(fetchUsersOnce());
  }

  return (
    !loaded.current? <CircularLoading/>:
    <div>
    <div className={classes.heading}>
    <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
    {t('alladmin')}  
    <Button
        type="submit"
        variant="contained"
        className={classes.button}
        onClick={()=>addNew()}
      >
        {t("add")}
      </Button>
    </Typography>
    </div>
    <MaterialTable
      title={t('alladmin')}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr',border:'2px solid #808080'}}
      data={data}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        sorting: true,
        actionsColumnIndex: -1,
        headerStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          border:'2px solid #808080',
        },
        cellStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          borderRight:'1px solid #808080',
          backgroundColor:'#f5f5f5',
          
        },
      }}
      localization={{body:{
        addTooltip: (t('add')),
        deleteTooltip: (t('delete')),
        editTooltip: (t('edit')),
        emptyDataSourceMessage: (
          (t('blank_message'))
      ),
      editRow: { 
        deleteText: (t('delete_message')),
        cancelTooltip: (t('cancel')),
        saveTooltip: (t('save')) 
          },
        },
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('actions')) 
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        labelRowsSelect: (t('rows')),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
      }}
      actions={[
        rowData  => ({
          icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
       <div  className={classes.submit3}>
              <EditIcon icon={EditIcon} style={{marginLeft:2,marginTop:5,color:'white'}} />
            </div>
          </div>,
           onClick: () => {
              history.push({
                pathname: '/editadmin',
                state: { 
                  detail: rowData
                }
              });
            }
        }),
        rowData => ({
          icon: () => <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
           <div className={classes.submit4}>
              <DeleteIcon icon={DeleteIcon} style={{marginLeft:2,marginTop:5,color:'white'}}/>
            </div>
          </div>,
         onClick: () => {
          settings.AllowCriticalEditsAdmin?
            deleteSingleAdmin(rowData)
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(t('demo_mode'));
            }, 600);
              })
            }            
          })
      ]}
    />
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={deleteModel}
        className={classes.modal}
      >
        <div className={classes.deletepaper}>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <Typography component="h1" variant="h5" className={classes.title} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                {t('delete_booking_message')}{t('admin')} ?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', marginLeft: isRTL === 'rtl' ? '65%' : 0 }}>
              <Button onClick={closeDeleteModel} variant="contained" color="primary">
                {t('cancel')}
              </Button>
              <Button onClick={deleteAdminData} variant="contained" color="primary" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }}>
                {t('yes')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}
