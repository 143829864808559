import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
} from '@material-ui/core';
import { colors } from '../components/Theme/WebTheme';
const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  }
}));
export default function CancellationReasons() {

  const { t,i18n } = useTranslation();
  const classes = useStyles();
  const isRTL = i18n.dir();
  const {
    editCancellationReason
  } = api;
  const columns = [
    {title: t('no'), render:(rowData)=>rowData.tableData.id+1},
    { title: t('reasoning'), field: 'label',render: rowData => <span>{rowData.label}</span>}
  ];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const cancelreasondata = useSelector(state => state.cancelreasondata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (cancelreasondata.complex) {
      setData(cancelreasondata.complex);
    }else{
      setData([]);
    }
  }, [cancelreasondata.complex]);

  return (
    // cancelreasondata.loading ? <CircularLoading /> :
    <div>
    <div className={classes.heading}>
    <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
    {t('cancellation_reasons')}
          </Typography>
          </div>
      <MaterialTable
        title={t('reasons_list')}
        style={{border:'2px solid #109496',width:'45%'}}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          pageSize: 10,
          cellStyle:{
            textAlign:isRTL=== 'rtl' ?'right':'left',
            backgroundColor:'#f5f5f5'
        },
          headerStyle:{
            textAlign:isRTL=== 'rtl' ?'right':'left',
            border:'2px solid #808080',}
        
        }}
        localization={{body:{
          addTooltip: (t('add')),
          deleteTooltip: (t('delete')),
          editTooltip: (t('edit')),
          emptyDataSourceMessage: (
            (t('blank_message'))
        ),
        editRow: { 
          deleteText: (t('delete_message')),
          cancelTooltip: (t('cancel')),
          saveTooltip: (t('save')) 
            }, 
          },
          toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
          },
          pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            labelRowsSelect: (t('rows')),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
          },
        }}
        editable={settings.AllowCriticalEditsAdmin ? {
            onRowAdd: newData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                newData.value = tblData.length
                tblData.push(newData);
                dispatch(editCancellationReason(tblData, "Add"));
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData[tblData.indexOf(oldData)] = newData;
                dispatch(editCancellationReason(tblData, "Update"));
              }, 600);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                tblData.splice(tblData.indexOf(oldData), 1);
                for(let i=0;i<tblData.length;i++){
                  tblData[i].value = i;
                }
                dispatch(editCancellationReason(tblData, "Delete"));
              }, 600);
            }),
        } : null}
      />
      </div>
  );
}
