import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
//import { useSelector } from "react-redux";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function PrivacyPolicy(props) {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const classes = useStyles();
    //const settings = useSelector(state => state.settingsdata.settings);
    const { ...rest } = props;
    return (
        <div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/header-back.jpg").default} />
            <div className={classNames(classes.main, classes.mainRaised)}>

                <div className={classes.container}>
                    <br />
                    <h2 style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', position: "relative", marginTop: "30px", minHeight: "32px", color: "#383838", textDecoration: "none" }}>{t('privacy_policy')}</h2>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>RIGZIE PTY LTD (ABN: 0287199957) ("RIGZIE PTY LTD”) is subject to the Australian Privacy Principles pursuant to the Privacy Act 1988 as amended by the Privacy Amendment (Enhancing Privacy Protection) Act 2012. Our Privacy Statement describes how RIGZIE Pty Ltd protects the privacy of any of your personal information in accordance with the Australian Privacy Principles.</p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}><strong>Personal Information</strong></p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>“Personal Information” is information or an opinion, whether true or not, and whether recorded in a material form or not, about an identified individual or an individual who is reasonably identifiable.</p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}><strong>What Personal Information do we collect and hold?</strong></p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>The types of Personal Information we may collect about an individual will depend upon the nature of our interaction with them. Personal Information that we collect may include (but is not limited to) the following:</p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ol type="A" style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                                Name
                            </li>
                            <li>
                                Age and/or date of birth
                            </li>
                            <li>
                                Contact details including telephone numbers (landline and/or mobile)
                            </li>
                            <li>
                                Current and previous address (including postal address)
                            </li>
                            <li>
                                Email address
                            </li>
                            <li>
                                Gender
                            </li>
                            <li>
                                Identification information such as driving license details, including driving license number
                            </li>
                            <li>
                                Certification information such as taxi registration number, taxi plate number, and taxi operator certification details
                            </li>
                            <li>
                                Trip details (including start and end location, and fare) collected by our products such as payment terminals
                            </li>
                            <li>
                                Payment details, such as your credit card, debit card, or bank account details
                            </li>
                            <li>
                                Medicare details including individual reference number
                            </li>
                            <li>
                                Occupation and employment details including employment status and any previous work experience
                            </li>
                            <li>
                                Information from or in connection with your resume or job application if you apply for a position with us
                            </li>
                            <li>
                                Emergency contact details
                            </li>
                            <li>
                                Geographical location
                            </li>
                            <li>
                                Australian Business Number (ABN)
                            </li>
                            <li>
                                Credit history
                            </li>
                            <li>
                                Trade references, credit references, or reports from a third party
                            </li>
                            <li>
                                Credit information
                            </li>
                            <li>
                                Criminal record as well as other disciplinary action and history
                            </li>
                            <li>
                                Passport and visa detail
                            </li>
                            <li>
                                Photographs and/or images of you from camera footage, and
                            </li>
                            <li>
                                Information from social media accounts and profiles.
                            </li>
                        </ol>
                    </p>
                    <p className={classes.description} style={{ fontWeight: 'bold', textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <strong>We collect and record Personal Information about individuals such as:</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ol type="A" style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                                Our customers, potential customers, and their representatives
                            </li>
                            <li>
                                Our investors and shareholders
                            </li>
                            <li>
                                Our suppliers and potential suppliers and their representatives, directors, partners, proprietors, and shareholders
                            </li>
                            <li>
                                Contractors and subcontractors and their representatives in relation to providing goods and services to us
                            </li>
                            <li>
                                Drivers and operators of vehicles, in relation to information about the trips taken when logged into our products such as dispatch equipment and payment terminals
                            </li>
                            <li>
                                Other individuals who may use our products and services (for example, individuals who have digital passes provided to them by their employer or someone they know)
                            </li>
                            <li>
                                Our employees past and present, including applicants, and
                            </li>
                            <li>
                                Any other person who comes into contact with Rigzie.
                            </li>
                        </ol>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        We are authorized to collect tax file numbers (TFNs) by the Income Tax Assessment Act 1936 (Cth). It is not compulsory for you to provide your TFN for our products and services, but if you do not, taxation law may require additional deductions to be made from amounts payable to you.
                    </p>
                    <p className={classes.description} style={{ fontWeight: 'bold', textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <strong>How and when do we collect Personal Information?</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ul style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                                We collect your Personal Information to allow us to conduct our business and organizational functions, to provide, market, and sell our products and services and for the specified purposes set out in paragraph 6. In some circumstances, the collection of Personal Information may be required by law.
                            </li>
                            <li>
                                We may collect your Personal Information in the course of providing you with goods or services, or:
                            </li>
                            <ol type="A" style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                                <li>
                                    When you make a booking (online, by Email, through our mobile application, or by calling our contact center)
                                </li>
                                <li>
                                    When a third party purchases products or services for you to use
                                </li>
                                <li>
                                    When you provide us, or you offer or apply to supply us, with goods or services
                                </li>
                                <li>
                                    When you provide information to us in any way (including by completing a form, disclosing information over the phone, via email, fax, or post, or providing us a business card)
                                </li>
                                <li>
                                    When you are driving a vehicle and logged into one of our payment terminals, we receive information about the trips taken (including fare details)
                                </li>
                                <li>
                                    When you request information about us, our products or our services
                                </li>
                                <li>
                                    When you provide feedback to us
                                </li>
                                <li>
                                    When you visit or fill in a form on our Website
                                </li>
                                <li>
                                    When you register for or use an account or subscription package on our Website or through our mobile applications, including for the purposes of enabling us to contact you in relation to your account
                                </li>
                                <li>
                                    When you visit premises from which we operate
                                </li>
                                <li>
                                    When you otherwise seek to set up an agreement with us (e.g. plate owner management agreement, vehicle lease, short-term loan, and insurance loan)
                                </li>
                                <li>
                                    Where we prepare insurance and insurance premium funding paperwork for you
                                </li>
                                <li>
                                    When you submit a job application to us
                                </li>
                                <li>
                                    When you as a passenger make a lost property request to us, for the purposes of processing the relevant request
                                </li>
                                <li>
                                    When you otherwise contact us by telephone, fax, email, social media, post, or in-person
                                </li>
                                <li>
                                    Where we are otherwise required or authorized by law to do so.
                                </li>
                            </ol>
                            <li>
                                Generally, when providing our products and services, dealing with our personnel, or obtaining goods and services from our service providers, suppliers, or contractors, we collect Personal Information directly from the relevant individual where reasonable and practicable.
                            </li>
                            <li>
                                We may also collect Personal Information about you from third parties and other sources such as:
                            </li>
                            <ol type="A" style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                                <li>
                                    Our share registry service provider
                                </li>
                                <li>
                                    If you are a taxi driver, from your taxi operator
                                </li>
                                <li>
                                    Publicly available sources of information
                                </li>
                                <li>
                                    Related entities, companies, and businesses of Rigzie
                                </li>
                                <li>
                                    If required by regulatory bodies
                                </li>
                                <li>
                                    Government departments and information providers, such as the Department of Immigration and Citizenship (Vevo check) and Service NSW (Driving records)
                                </li>
                                <li>
                                    Other commercial credit providers, or
                                </li>
                                <li>
                                    Credit reporting bodies who provide information about commercial creditworthiness,but we will generally only collect your Personal Information in this way if it is unreasonable or impracticable to collect this information directly from you or if we are otherwise permitted to do so.
                                </li>
                            </ol>
                        </ul>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', fontWeight: 'bold' }}>
                        <strong>Collection</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        RIGZIE PTY LTD will only collect personal information which is necessary for the operation of its business.RIGZIE PTY LTD will only collect such information by lawful and fair means and not in an unreasonably intrusive way.
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        At or before the time (or, if that is not practicable, as soon as practicable after) RIGZIE PTY LTD collects personal information about an individual from the individual, RIGZIE PTY LTD will take reasonable steps to ensure that the individual is aware of:
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ul style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                                The identity of the organisation and how to contact it; and
                            </li>
                            The fact that he or she is able to gain access to the information; and
                            <li>
                                The purposes for which the information is collected; and
                            </li>
                            <li>
                                The organisations (or the types of organisations) to which RIGZIE PTY LTD usually discloses information of that kind; and
                            </li>
                            <li>
                                Any law that requires the particular information to be collected; and
                            </li>
                            <li>
                                The main consequences (if any) for the individual if all or part of the information is not provided.
                            </li>
                        </ul>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        If it is reasonable and practicable to do so, RIGZIE PTY LTD will only collect personal information about an individual from that individual. If RIGZIE PTY LTD collects personal information about an individual from someone else, it will take reasonable steps to ensure that the individual is or has been made aware of the matters listed above except to the extent that making the individual aware of the matters would pose a serious threat to the life or health of any individual.
                    </p>
                    <p className={classes.description} style={{ fontWeight: 'bold', textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <strong>Information collected via our Website and applications</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        Personal information may be collected by us and by our third-party service providers who assist us in operating our applications or our websites including but not limited to those at:
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>

                        <a href="https://rigzie-cf338.web.app"
                            target="_blank" rel="noopener noreferrer">https://rigzie-cf338.web.app</a>

                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>

                        <a href="https://www.wavcabs.com.au"
                            target="_blank" rel="noopener noreferrer">https://www.wavcabs.com.au</a>

                    </p>

                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>

                        <a href="https://www.wavmaxicabs.com.au"
                            target="_blank" rel="noopener noreferrer">https://www.wavmaxicabs.com.au</a>

                    </p>

                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>

                        <a href="https://www.taxisydneyairport.com.au"
                            target="_blank" rel="noopener noreferrer">https://www.taxisydneyairport.com.au</a>

                    </p>

                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>

                        <a href="https://www.wavcab.com.au"
                            target="_blank" rel="noopener noreferrer">https://www.wavcab.com.au</a>

                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>

                        <a href="https://www.justtaxi.com.au"
                            target="_blank" rel="noopener noreferrer">https://www.justtaxi.com.au</a>

                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>

                        <a href="https://www.wavsydney.com.au"
                            target="_blank" rel="noopener noreferrer">https://www.wavsydney.com.au</a>

                    </p>

                    <p className={classes.description} style={{ fontWeight: 'bold', textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <strong>Purpose Of Information</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        RIGZIE PTY LTD will collect and use personal information to provide services to you and to administer those services. RIGZIE PTY LTD may also use information to develop new products and services, attend to your enquiries, seek feedback, or tell you about other products or services offered by RIGZIE PTY LTD and its related companies. RIGZIE PTY LTD may also seek personal information for legal reasons such as compliance with legislation (for example Anti-Money Laundering legislation).
                    </p>
                    <p className={classes.description} style={{ fontWeight: 'bold', textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <strong>Use And Disclosure</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        RIGZIE PTY LTD will not use or disclose personal information about an individual for a purpose (the secondary purpose) other than the primary purpose of collection unless:
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        Both of the following apply:
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ul style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                                The secondary purpose is related to the primary purpose of collection and, if the personal information is sensitive information, directly related to the primary purpose of the collection
                            </li>
                            The individual would reasonably expect RIGZIE PTY LTD to use or disclose the information for the secondary purpose
                            <li>
                                The individual has consented to the use or disclosure
                            </li>
                            <li>
                                If the information is not sensitive information and the use of the information is for the secondary purpose of direct marketing
                            </li>
                            <li>
                                It is impractical for RIGZIE PTY LTD to seek the individual’s consent before that particular use
                            </li>
                            <li>
                                RIGZIE PTY LTD will not charge the individual for giving effect to a request by the individual to RIGZIE PTY LTD not to receive direct marketing communication
                            </li>
                            <li>
                                The individual has not made a request to RIGZIE PTY LTD not to receive direct marketing communications
                            </li>
                            <li>
                                In each direct marketing communication with the individual, RIGZIE PTY LTD draws to the individual’s attention or prominently displays a notice, that he or she may express a wish not to receive any further direct marketing communications
                            </li>
                            <li>
                                Each written direct marketing communication by RIGZIE PTY LTD with the individual (up to and including the communication that involves the use) sets out RIGZIE PTY LTD’s business address and telephone number and, if the communication with the individual is made by fax, telex, or other electronic means, a number or address which RIGZIE PTY LTD can be directly contacted electronically
                            </li>
                            <li>
                                If the information is health information and the use or disclosure is necessary for research, or the compilation or analysis of statistics, relevant to public health or public safety
                            </li>
                            <li>
                                It is impractical for RIGZIE PTY LTD to seek the individual’s consent before the use or disclosure
                            </li>
                            <li>
                                The use or disclosure is conducted in accordance with guidelines approved by the Commissioner under section 95A for the purposes of this subparagraph
                            </li>
                            <li>
                                In the case of disclosure – RIGZIE PTY LTD reasonably believes that the recipient of the health information will not disclose the health information or personal information derived from the health information
                            </li>
                        </ul>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        RIGZIE PTY LTD reasonably believes that the use or disclosure is necessary to lessen or prevent
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ul style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                                A serious and imminent threat to an individual’s life, health, or safety
                            </li>
                            <li>
                                A serious threat to public health or public safety
                            </li>
                        </ul>
                    </p>

                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        RIGZIE PTY LTD has reason to suspect that unlawful activity has been, is being or may be engaged in, and uses or discloses the personal information as a necessary part of its investigation of the matter or in reporting its concerns to relevant persons or authorities.
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        The use or disclosure is required or authorised by or under law; or
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        RIGZIE PTY LTD reasonably believes that the use or disclosure is reasonably necessary for one or more of the following by or on behalf of an enforcement body.
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ul style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                                The prevention, detection, investigation, prosecution or punishment of criminal offences, breaches of a law imposing a penalty or sanction or breaches of a prescribed law.
                            </li>
                            <li>
                                The enforcement of laws relating to the confiscation of the proceeds of crime.
                            </li>
                            <li>
                                The protection of the public revenue.
                            </li>
                            <li>
                                The prevention, detection, investigation or remedying of seriously improper conduct or prescribed conduct.
                            </li>
                            <li>
                                The preparation for, or conduct of, proceedings before any court or tribunal, or implementation of the orders of a court or tribunal.
                            </li>
                            <li>
                                If RIGZIE PTY LTD uses or discloses personal information under paragraph (h), it must make a written note of the use or disclosure. The above operates in relation to personal information that RIGZIE PTY LTD has collected from a related body corporate as if RIGZIE PTY LTD’s primary purpose of collection of the information were the primary purpose for which the related body corporate collected the information.
                            </li>
                        </ul>
                    </p>
                    <p className={classes.description} style={{ fontWeight: 'bold', textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <strong>Kind Of Information</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        RIGZIE PTY LTD will only seek personal information relevant to its business relationship with you. When you apply for RIGZIE PTY LTD, RIGZIE PTY LTD may request information that identifies you and information about your financial position and history. In most cases and where possible personal information about an individual will be collected directly from that individual. The most common way RIGZIE PTY LTD collects personal information is from RIGZIE PTY LTD application forms.
                    </p>
                    <p className={classes.description} style={{ fontWeight: 'bold', textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <strong>Data Quality</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        RIGZIE PTY LTD will take reasonable steps to make sure that the personal information it collects uses or discloses is accurate, complete and up-to-date.
                    </p>
                    <p className={classes.description} style={{ fontWeight: 'bold', textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <strong>Data Security</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        RIGZIE PTY LTD will take reasonable steps to protect the personal information it holds from misuse and loss and from unauthorised access, modification or disclosure.
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        RIGZIE PTY LTD will take reasonable steps to destroy or permanently de-identify personal information if it is no longer needed for any purpose for which the information may be used or disclosed under Australian Privacy Principles.
                    </p>
                    <p className={classes.description} style={{ fontWeight: 'bold', textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <strong>Openness</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        On request by a person, RIGZIE PTY LTD will take reasonable steps to let the person know, generally, what sort of personal information it holds, for what purposes, and how it collects, holds, uses and discloses that information.
                    </p>
                    <p className={classes.description} style={{ fontWeight: 'bold', textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <strong>Access And Correction</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ol type="1" style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                                If RIGZIE PTY LTD holds personal information about an individual, it will provide the individual with access to the information on request by the individual, except to the extent that:
                            </li>
                            <li>
                                In the case of personal information other than health information-providing access would pose a serious and imminent threat to the life or health of any individual;
                            </li>
                            <li>
                                In the case of health information – providing access would pose a serious threat to the life or health of any individual; providing access would have an unreasonable impact upon the privacy of other individuals;
                            </li>
                            <li>
                                The request for access is frivolous or vexatious; the information relates to existing or anticipated legal proceedings between RIGZIE PTY LTD and the individual, and the information would not be accessible by the process of discovery in those proceedings;
                                providing access would reveal the intentions of RIGZIE PTY LTD in relation to negotiations with the individual in such a way as to prejudice those negotiations;
                            </li>
                            <li>
                                Providing access would be unlawful;
                                denying access is required or authorized by or under the law;
                                providing access would be likely to prejudice an investigation of possible unlawful activity;
                            </li>
                            <li>
                                Providing access would be likely to prejudice:
                                the prevention, detection, investigation, prosecution, or punishment of criminal offenses, breaches of a law imposing a penalty or sanction or breaches of a prescribed law;
                                the enforcement of laws relating to the confiscation of the proceeds of crime;
                                the protection of the public revenue;
                                the prevention, detection, investigation, or remedying of seriously improper conduct or prescribed conduct;
                                the preparation for, or conduct of, proceedings before any court or tribunal, or implementation of its orders; or
                                by or on behalf of an enforcement body; or
                                an enforcement body performing a lawful security function asks RIGZIE PTY LTD not to provide access to the information on the basis that providing access would be likely to cause damage to the security of Australia.
                            </li>
                            <li>
                                However, where providing access would reveal evaluative information generated within RIGZIE PTY LTD in connection with a commercially sensitive decision-making process, RIGZIE PTY LTD may give the individual an explanation for the commercially sensitive decision rather than direct access to the information.
                            </li>
                            <li>
                                If RIGZIE PTY LTD is not required to provide the individual with access to the information because of one or more of the paragraphs above, RIGZIE PTY LTD must, if reasonable, consider whether the use of mutually agreed intermediaries would allow sufficient access to meet the needs of both parties.
                            </li>
                            <li>
                                If RIGZIE PTY LTD charges for providing access to personal information, those charges:
                            </li>
                            <li>
                                Must not be excessive; and
                            </li>
                            <li>
                                Must not apply to lodging a request for access.
                            </li>
                            <li>
                                If RIGZIE PTY LTD holds personal information about an individual and the individual is able to establish that the information is not accurate, complete, and up-to-date, RIGZIE PTY LTD will take reasonable steps to correct the information so that it is accurate, complete, and up-to-date. If the individual and RIGZIE PTY LTD disagree about whether the information is accurate, complete, and up-to-date, and the individual asks RIGZIE PTY LTD to associate with the information a statement claiming that the information is not accurate, complete, or up-to-date, RIGZIE PTY LTD will take reasonable steps to do so. RIGZIE PTY LTD will provide reasons for denial of access or a refusal to correct personal information.
                            </li>
                        </ol>
                    </p>
                    <p className={classes.description} style={{ fontWeight:'bold',textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                       <strong>Identifiers</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ol type="1" style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                            RIGZIE PTY LTD will not adopt as its own identifier of an individual an identifier of the individual that has been assigned by:
                            </li>
                            <li>
                            An agency; or
                            </li>
                            <li>
                            An agent of an agency acting in its capacity as agent; or  
                            </li>
                            <li>
                            A contracted service provider for the Commonwealth contract acting in its capacity as a contracted service provider for that contract.  
                            </li>
                            <li>
                            However, the above does not apply to the adoption by a prescribed organization of a prescribed identifier in prescribed circumstances. RIGZIE PTY LTD will not use or disclose an identifier
                            </li>
                            <li>
                            Assigned to an individual by an agency, or by an agent or contracted service provider mentioned above, unless:  
                            </li>
                            <li>
                            The use or disclosure is necessary for the organization to fulfill its obligations to the agency; or    
                            </li>
                            <li>
                            One or more paragraphs under heading 3, numbers e) to h) (inclusive) apply to the use or disclosure; or  
                            </li>
                            <li>
                            The use or disclosure is by a prescribed organization of a prescribed identifier in prescribed circumstances.
                            </li>
                        </ol>
                    </p>
                    <p className={classes.description} style={{ fontWeight:'bold',textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                    <strong>Anonymity</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ol type="1" style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                            Wherever it is lawful and practicable, individuals will have the option of not identifying themselves when entering into transactions with RIGZIE PTY LTD.
                            </li>
                        </ol>
                    </p>
                    <p className={classes.description} style={{ fontWeight:'bold',textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                    <strong>Transborder Data Flows</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ol type="1" style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                            RIGZIE PTY LTD is not likely to disclose personal information to overseas recipients. RIGZIE PTY LTD may transfer personal information about an individual to someone (other than to RIGZIE PTY LTD or the individual) who is in a foreign country only if:
                            </li>
                            <li>
                            RIGZIE PTY LTD reasonably believes that the recipient of the information is subject to a law, binding scheme or contract which effectively upholds principles for fair handling of the information that are substantially similar to the Australian Privacy Principles; or
                            </li>
                            <li>
                            The individual consents to the transfer; or 
                                </li>
                                <li>
                                The transfer is necessary for the performance of a contract between the individual and RIGZIE PTY LTD, or for the implementation of pre-contractual measures taken in response to the individual’s request; or
                                </li>
                                <li>
                                The transfer is necessary for the conclusion or performance of a contract concluded in the interest of the individual between RIGZIE PTY LTD and a third party; or  
                                </li>
                                <li>
                                All of the following apply:
                                </li>
                                <li>
                                The transfer is for the benefit of the individual;
                                </li>
                                <li>
                                It is impracticable to obtain the consent of the individual to that transfer;
                                </li>
                                <li>
                                If it were practicable to obtain such consent, the individual would be likely to give it; or
                                </li>
                                <li>
                                RIGZIE PTY LTD has taken reasonable steps to ensure that the information which it has transferred will not be held, used or disclosed by the recipient of the information inconsistently with the Australian Privacy Principles.
                                </li>
                        </ol>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                    In this clause: “identifier” includes a number assigned by an organization to an individual to identify uniquely the individual for the purposes of the organization’s operations. However, an individual’s name or ABN is not an “identifier”.
                    </p>
                    <p className={classes.description} style={{ fontWeight:'bold',textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                       <strong>Sensitive Information</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                    RIGZIE PTY LTD will not collect sensitive information about an individual unless:
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ul style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                            The individual has consented; or
                            </li>
                            <li>
                            The collection is required by law; or
                            </li>
                        </ul>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                    The collection is necessary to prevent or lessen a serious and imminent threat to the life or health of any individual, where the individual whom the information concerns:
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                        <ul style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                            <li>
                            Is physically or legally incapable of giving consent to the collection; or
                            </li>
                            <li>
                            Physically cannot communicate consent to the collection.
                            </li>
                        </ul>
                    </p>
                    <p className={classes.description} style={{ fontWeight:'bold',textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                       <strong>Complaints</strong>
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                    If you believe that RIGZIE PTY LTD has not complied with its obligations concerning your personal information, you should contact RIGZIE PTY LTD and lodge a complaint. RIGZIE PTY LTD will investigate your complaint and attempt to resolve any breach that may have occurred in relation to the collection, use or destruction of your personal information held by RIGZIE PTY LTD in accordance with the Privacy Act 1988 and related legislation.
                    </p>
                    <p className={classes.description} style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
                    If you are not satisfied with the outcome of the investigation you can lodge a privacy complaint externally by contacting the Office of the Australian Information Commissioner on 1300 363 992.
                    </p>
                    <br />
                    <br />
                </div>
            </div>
            <Footer />
        </div>
    );
}
