export const SMSFunction = (data, type) => (firebase) => {
    const {
        config
    } = firebase;

    let body = type === true?{
        "messages": [
            {
            "content": "HI, Your booking Id is - " + data.id + ", " +
            "Your Pickup lOcation is - " + data.pickup.add + ", " +
            "Your Drop lOcation is - " + data.drop.add + ", " +
            "Booking Status - " + data.status + ", " +
            "Trip Cost - " + data.trip_cost,
            "destination_number" : data.customer_contact,
            "format": "SMS",
            "source_number": "+61459331800"
            }
        ]
    }
    : {
        "messages": [
            {
            "content": "HI, Your wallet balance is update sucessfully. Your account balance is : " + data. walletBalance,
            "destination_number" : data.mobile,
            "format": "SMS",
            "source_number": "+61459331800"
            }
        ]
    };
    
    fetch(`https://${config.projectId}.web.app/smsFunctions`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json'
        },
        body: JSON.stringify(body)
    })
    .then((response) => {
        console.log('SMS send.')
    })
    .catch((error) => {
        console.log(error)
    });
}