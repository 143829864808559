import {
    FETCH_ADMIN_REPORT,
    FETCH_ADMIN_REPORT_SUCCESS,
    FETCH_ADMIN_REPORT_FAILED,
    EDIT_ADMIN_REPORT
} from "../store/types";
import { firebase } from '../config/configureFirebase';
import { onValue, push } from "firebase/database";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
  
export const fetchAdminReports = () => (dispatch) => {

  const {
      adminReportRef
  } = firebase;

  dispatch({
    type: FETCH_ADMIN_REPORT,
    payload: null
  });
  onValue(adminReportRef, snapshot => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const arr = Object.keys(data).map(i => {
        data[i].id = i
        return data[i]
      });
      dispatch({
        type: FETCH_ADMIN_REPORT_SUCCESS,
        payload: arr.reverse()
      });
    } else {
      dispatch({
        type: FETCH_ADMIN_REPORT_FAILED,
        payload: "No transaction available."
      });
    }
  });
};
  
export const adminWithdraw = (itemData) => async (dispatch) => {
  const {
    adminReportRef,
    adminWithdrawrDocsRef
  } = firebase;

  onValue(adminReportRef, async snapshot => {
    let allDetails = snapshot.val();
    let lastAmount = 0;
    if (itemData.adminWithdrawImage) {
      let timestamp = new Date().toISOString();
      await uploadBytesResumable(adminWithdrawrDocsRef(timestamp), itemData.adminWithdrawImage);
      itemData.adminWithdrawImage = await getDownloadURL(adminWithdrawrDocsRef(timestamp));
    }

    if(allDetails && Object.keys(allDetails).length > 0){
      var keys = Object.values(allDetails);
      var last = keys[keys.length-1];
      lastAmount = parseFloat(last.updateBalance);
    }  
    push(adminReportRef,{
      date: new Date().toString(),
      uid: itemData.id,
      adminAmount: parseFloat(itemData.amount),
      driverAmount: 0,
      fleetAmount: 0,
      corporateAmount: 0,
      type:'debit',
      craditAmount: 0,
      withdrawImage:  itemData.adminWithdrawImage,
      debitAmount: parseFloat(itemData.amount),
      updateBalance: parseFloat(lastAmount - parseFloat(itemData.amount)).toFixed(2)
    });
  },{onllyOnce: true});
  
  dispatch({
    type: EDIT_ADMIN_REPORT,
    payload: itemData
  });
}
  