import React,{ useState,useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { api } from 'common';
import { useSelector, useDispatch} from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Modal,
  Grid
} from '@material-ui/core';
import { colors } from '../components/Theme/WebTheme';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';


const useStyles = makeStyles((theme) => ({
  heading:{
    marginBottom:20
  },
  submit: {
    backgroundColor: colors.Rigzie1,
    color: 'white'
  },
  submit1: {
    backgroundColor: 'White',
    color: '#4c63b8',
    marginBottom:10
  },
  submit2: {
    backgroundColor: 'gray',
    color: '#ffffff',
    marginBottom:10
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius:15,
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export default function WithdrawList() {
  let history = useHistory();
  const location = useLocation();
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const earning = location.state.detail;

  const {
    editWithdraw
  } = api;
  const dispatch = useDispatch();
  const rootRef = useRef(null);

  const goBack = () => {
    history.goBack();
  }
  const [data, setData] = useState([]);
  const columns =  [
    {title: t('no'), render:(rowData)=>rowData.tableData.id+1},
    { title: 'ID', field: 'id',editable: 'never'},
    { title: t('driver_name'),field: 'name',editable: 'never'},
    { title: t('requestDate'), field: 'date',editable: 'never'},
    { title: t('amount'), field: 'amount',editable: 'never'},
    { title: t('processed'), field: 'processed', type: 'boolean',editable: 'never'},  
    { title: t('processDate'), field: 'procesDate',editable: 'never'}, 
    { title: t('receiptImage'),  field: 'receiptImage',render: rowData =>  <button onClick={()=>onClick(rowData)}><img alt='License' src={rowData.receiptImage} style={{width: 100}}/></button>},
    //{ title: t('lisence_image'),  field: 'licenseImage',render: rowData => rowData.licenseImage? <button onClick={()=>{onClick(rowData)}}><img alt='License' src={rowData.licenseImage} style={{width: 100}}/></button>:null,},
    // { title: t('bankName'), field: 'bankName', hidden: settings.bank_fields===false? true: false,editable: 'never'},
    // { title: t('bankCode'), field: 'bankCode' , hidden: settings.bank_fields===false? true: false,editable: 'never'},
    // { title: t('bankAccount'), field: 'bankAccount', hidden: settings.bank_fields===false? true: false,editable: 'never'}
  ];
  const withdrawdata = useSelector(state => state.withdrawdata);
  useEffect(()=>{
    if(withdrawdata.withdraws){
        setData(withdrawdata.withdraws);
    }else{
      setData([]);
    }
  },[withdrawdata.withdraws]);
  const [newData, setNewData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(()=>{
    if(data){
      let arr = [];
      //var monthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      for(let i=0; i<data.length; i++){
        let bdt = new Date(data[i].procesDate);
        var elm = document.createElement('input');
        elm.type = 'week';
        elm.valueAsDate = bdt;
        var week = elm.value.split('W').pop();
        if(week === earning.weekNumber && bdt.getFullYear() === earning.year && data[i].usertype === 'driver'){
          arr.push(data[i]);
        }
      }
      setNewData(arr);
    }
  },[data, earning.weekNumber, earning.year]);

  const drivers = () => {
    if(data){
      let arr = [];
      //var monthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      for(let i=0; i<data.length; i++){
        let bdt = new Date(data[i].procesDate);
        var elm = document.createElement('input');
        elm.type = 'week';
        elm.valueAsDate = bdt;
        var week = elm.value.split('W').pop();
        if(week === earning.weekNumber && bdt.getFullYear() === earning.year && data[i].usertype === 'driver'){
          arr.push(data[i]);
        }
      }
      setNewData(arr);
    }
  }

  const fleetadmins = () => {
    if(data){
      let arr = [];
      //var monthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      for(let i=0; i<data.length; i++){
        let bdt = new Date(data[i].procesDate);
        var elm = document.createElement('input');
        elm.type = 'week';
        elm.valueAsDate = bdt;
        var week = elm.value.split('W').pop();
        if(week === earning.weekNumber && bdt.getFullYear() === earning.year && data[i].usertype === 'fleetadmin'){
          arr.push(data[i]);
        }
      }
      setNewData(arr);
    }
  }

  const corporateAdmins = () => {
    if(data){
      let arr = [];
      //var monthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      for(let i=0; i<data.length; i++){
        let bdt = new Date(data[i].procesDate);
        var elm = document.createElement('input');
        elm.type = 'week';
        elm.valueAsDate = bdt;
        var week = elm.value.split('W').pop();
        if(week === earning.weekNumber && bdt.getFullYear() === earning.year && data[i].usertype === 'corporateAdmin'){
          arr.push(data[i]);
        }
      }
      setNewData(arr);
    }
  }

  const [profileModal, setProfileModal] =  useState(false);
  const [imageData, setImageData] =  useState(false);
  const [selectRowData, setSelectedRowData] = useState(null);

  const onClick = (rowData) => {
    setSelectedRowData(rowData);
    setImageData(rowData.receiptImage);
    setProfileModal(true);
  };

  const handleProfileModal = (e) => {
    setSelectedImage(null);
    setProfileModal(false);
  }

  const saveChanges = async(item) =>{
    if(selectedImage){
      item.withdraImage = selectedImage;
    }
    await dispatch(editWithdraw(item));
    setSelectedRowData(null);
    setProfileModal(false);
  }

  return (
    // earningreportsdata.loading? <CircularLoading/>:
    <div>
    <div className={classes.heading}>
    <Typography component="h1" variant="h5" style={{ marginTop: '8px',fontWeight:'bolder',color:colors.Rigzie1,textDecoration:'underline'}}>
    {t('earning_reports_details')}
          </Typography>
          </div>
          <Button
          type="back"
          width='3%'
          variant="contained"
          className={classes.submit1}
          startIcon={<KeyboardBackspaceIcon icon={KeyboardBackspaceIcon} />}
          onClick={() => goBack()}
        >
          {t('go_back')}
        </Button>

        <Button
          type="submit"
          width='45%'
          variant="contained"
          className={classes.submit2}
          style={{marginLeft: 20}}
          onClick = {() => drivers()}
        >
          {t('drivers')}
        </Button>

        <Button
          type="submit"
          width='45%'
          variant="contained"
          className={classes.submit2}
          style={{marginLeft: 15}}
          onClick = {() => fleetadmins()}
        >
          {t('fleetadmin')}
        </Button>

        <Button
          type="submit"
          width='45%'
          variant="contained"
          className={classes.submit2}
          style={{ marginLeft: 15}}
          onClick = {() => corporateAdmins()}
        >
          {t('corporateAdmin')}
        </Button>

    <MaterialTable
      title={t('reports')}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr',border:'1px solid #808080'}}
      columns={columns}
      data={newData}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        pageSize:20,
        actionsColumnIndex: -1,
        pageSizeOptions:[20,50,100],
        headerStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          border:'2px solid #808080',
        },
        cellStyle:{
          textAlign:isRTL=== 'rtl' ?'right':'center',
          borderRight:'1px solid #808080',
          backgroundColor:'#f5f5f5'
        },
      }}
      localization={{
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        pagination: {
          labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
          labelRowsSelect: (t('rows')),
          firstTooltip: (t('first_page_tooltip')),
          previousTooltip: (t('previous_page_tooltip')),
          nextTooltip: (t('next_page_tooltip')),
          lastTooltip: (t('last_page_tooltip'))
        },
      }}
      // actions={[       
      //   rowData => ({
      //     icon: 'check',
      //     tooltip: t('process_withdraw'),
      //     disabled: rowData.processed,
      //     onClick: (event, rowData) => {
      //       if(selectedImage){
      //         dispatch(completeWithdraw({...rowData, withdraImage:selectedImage}));
      //       }
      //     }         
      //   })
      // ]}
    />

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={profileModal}
        onClose={handleProfileModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid container spacing={1} className={classes.paper} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="h1" variant="h6">
                {t('car_image')}

              <input
                  type="file"
                  style={{marginLeft:10}}
                  name= {t('image')}
                  onChange={(event) => {
                    setSelectedImage(event.target.files[0]);
                  }}
              />
            </Typography>
         </Grid>
         {selectedImage != null ?
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <img alt="receiptImage" height={"200px"} src={URL.createObjectURL(selectedImage)} style={{marginTop:10}}/>
            <br />
          </Grid>
          :
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <img alt="receiptImage" height={"200px"} src={imageData} style={{marginTop:10}}/>
            <br />
          </Grid>
         }
          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
            <Button  onClick={()=>{window.open(imageData, '_blank')}} variant="contained" color="dangerColor">
              {t('view')}
            </Button>
            </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
            <Button onClick={handleProfileModal} variant="contained" color="dangerColor">
              {t('cancel')}
            </Button> 
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
            <Button  onClick={()=>saveChanges(selectRowData)} variant="contained" color="dangerColor">
              {t('change')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}