import {
    CONFIRM_BOOKING,
    CONFIRM_BOOKING_SUCCESS,
    CONFIRM_BOOKING_FAILED,
    CLEAR_BOOKING
} from "../store/types";
import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { push } from "firebase/database";

export const clearBooking = () => (dispatch) => {
    dispatch({
        type: CLEAR_BOOKING,
        payload: null,
    });
}

export const addBooking = (bookingData) => async (dispatch) => {

    const   {
        bookingRef
    } = firebase;

    dispatch({
        type: CONFIRM_BOOKING,
        payload: bookingData,
    });
    let pickUp = { lat: bookingData.pickup.coords.lat, lng: bookingData.pickup.coords.lng, add: bookingData.pickup.description };
    let drop = { lat: bookingData.drop.coords.lat, lng: bookingData.drop.coords.lng, add: bookingData.drop.description };
    let coords = [{latitude: pickUp.lat,longitude: pickUp.lng}];
    if(bookingData.drop.waypointsStr){
        bookingData.drop.waypointsStr.split("|").forEach((point)=>{
            let lat = point.split(',')[0];
            let lng = point.split(',')[1];
            coords.push({latitude: parseFloat(lat), longitude: parseFloat(lng)});
        });
    }
    coords.push({latitude: drop.lat,longitude: drop.lng});
    var otp;
    if(bookingData.settings.otp_secure)
        otp = Math.floor(Math.random() * 90000) + 10000;
    else{
        otp = false;
    }
    let today = new Date().getTime();

    const settings = store.getState().settingsdata.settings;

    var data = {
        carType: bookingData.carDetails.name,
        carImage: bookingData.carDetails.image,
        capacity: bookingData.carDetails.capacity,
        customer: bookingData.userDetails.uid,
        customer_email: bookingData.userDetails.profile.email,
        customer_name: bookingData.userDetails.profile.firstName + ' ' + bookingData.userDetails.profile.lastName,
        customer_contact: bookingData.userDetails.profile.mobile? bookingData.userDetails.profile.mobile: ' ',
        customer_token: bookingData.userDetails.profile.pushToken? bookingData.userDetails.profile.pushToken: ' ',
        customer_image: bookingData.userDetails.profile.profile_image ? bookingData.userDetails.profile.profile_image : "",
        drop: drop,
        pickup: pickUp,
        estimate: bookingData.estimate.estimateFare,
        estimateDistance: bookingData.estimate.estimateDistance,
        distance: bookingData.estimate.estimateDistance,
        estimateTime:bookingData.estimate.estimateTime,
        status: "NEW",
        bookLater:bookingData.bookLater,
        tripdate: bookingData.bookLater?bookingData.tripdate:today,
        bookingDate: today,
        otp: otp,
        booking_type_admin:bookingData.booking_type_admin,
        coords: coords,
        waypoints: bookingData.drop.waypoints? bookingData.drop.waypoints: null,
        trip_cost: parseFloat(bookingData.estimate.estimateFare).toFixed(settings.decimal),
        convenience_fees: parseFloat(bookingData.estimate.convenience_fees).toFixed(settings.decimal),
        driver_share: (parseFloat(bookingData.estimate.estimateFare) - parseFloat(bookingData.estimate.convenience_fees)).toFixed(settings.decimal),
        paymentPacket: bookingData.paymentPacket? bookingData.paymentPacket : null,
        driver_instruction: bookingData.driver_instruction ? bookingData.driver_instruction : "",
        ...bookingData.instructionData
    }


    push(bookingRef, data).then((res) => {
        var bookingKey = res.key;
        dispatch({
            type: CONFIRM_BOOKING_SUCCESS,
            payload: {
                booking_id:bookingKey,
                mainData:{
                    ...data,
                    id:bookingKey
                }
            }    
        });
    }).catch(error => {
        dispatch({
            type: CONFIRM_BOOKING_FAILED,
            payload: error.code + ": " + error.message,
        });
    });
};

