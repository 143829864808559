import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from 'material-table';
import {
  Typography,
  Button,
  Grid,
  Box
} from '@material-ui/core';
import moment from 'moment/min/moment-with-locales';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { colors } from '../components/Theme/WebTheme';
import TextField from '@material-ui/core/TextField';
import { api } from 'common';
import CardContent from '@material-ui/core/CardContent';
import DashboardCard from '../components/DashboardCard';
import AlertDialog from '../components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: 680,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  deletepaper: {
    width: 590,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #009CA3',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10
  },
  heading: {
    marginBottom: 20
  },
  submit: {
    backgroundColor: colors.Rigzie1,
    color: 'white'
  },
  submit2: {
    display: 'flex'
  },
  submit4:{
    display: 'flex'
  },
  submit5:{
    backgroundColor:'red',
    width:'100%',
    borderRadius:7,
    marginTop:5,
    padding:4
  },
  box: {
    borderRadius:3,
    //border: '1px solid #444444',
    height: 185,
    marginTop: -30
  },
  imageBox: {
    borderRadius:3,
    //border: '1px solid #444444',
    height: 150,
    marginTop: -12
  },
  remove: {
    backgroundColor: '#ff6666',
    width: '25%',
    marginRight: 20
  },
}));
const AdminReport = (props) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    adminWithdraw,
  } = api;
  const auth = useSelector(state => state.auth);
  const adminreportdata = useSelector(state => state.adminreportdata);
  const [netBalance, SetNetBalance] = useState(0);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [state, setState] = useState({
    withdraw_amount: '',
    adminWithdrawImage: null
  });

  const columns = [
    { title: t('no'), render: (rowData) => rowData.tableData.id + 1 },
    //{ title: t('id'), field: 'id'},
    { title: t('date'), field: 'date', render: rowData => rowData.date ? moment(rowData.date).format('lll') : null, },
    { title: t('admin_amount'), field: 'adminAmount' },
    { title: t('driver_amount'), field: 'driverAmount' },
    { title: t('fleetadmin_amount'), field: 'fleetAmount' },
    { title: t('corporste_amount'), field: 'corporateAmount' },
    { title: t('type'), field: 'type' },
    { title: t('withdrawImage'), field: 'withdrawImage', render: rowData => rowData && rowData.withdrawImage ? <img alt='withdrawImage' src={rowData.withdrawImage} style={{ width: 100, borderRadius: 10 }} /> : null, editable: 'never' },
    { title: t('credit_amount'), field: 'craditAmount' },
    { title: t('debit_amount'), field: 'debitAmount' },
    { title: t('update_balance'), field: 'updateBalance' },
  ];

  const handleTextChange = (e) => {
    setState({ ...state, withdraw_amount: e.target.value });
  };

  const withdraw = (e) => {
    if(state.withdraw_amount ){
      if(state.adminWithdrawImage){
        var data={
          id: auth && auth.info && auth.info.profile ? auth.info.uid : null,
          amount: state.withdraw_amount,
          adminWithdrawImage: state.adminWithdrawImage
        }    
        dispatch(adminWithdraw(data)); 
        e.preventDefault();
        setState({
          withdraw_amount: '',
          adminWithdrawImage: null
        })
      }else{
        setCommonAlert({ open: true, msg: t('no_withdraw_image_error') });
      }
    } else {
      setCommonAlert({ open: true, msg: t('no_withdraw_amount_error') });
    }
  }

  console.log(state);

  useEffect(() => {
    if (adminreportdata.reports) {
      setData(adminreportdata.reports);
    } else {
      setData([]);
    }
  }, [adminreportdata.reports]);

  useEffect(() => {
    if (data) {
      for (let j = 0; j < data.length; j++) {
        if(j === 0){
          SetNetBalance(data[j].updateBalance);
        }
      }
    }
  }, [data, SetNetBalance]);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };


  return (
      <div>
        <div className={classes.heading2}>
          <Box style={{ borderRadius: 15, overflow: 'hidden', width: '100%',maxHeight:218,height:218 }}
              boxShadow={5}
              m={2}>
              <Typography variant="h6" style={{ margin: "10px 10px 0 15px", textAlign: isRTL === 'rtl' ? 'right' : 'left', color: colors.Rigzie1 }}>{t('earning')}</Typography>
              <CardContent >
                <Grid item xs={12} >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      {settings.swipe_symbol === false ?
                        <DashboardCard title={t('total_income')} image={require("../assets/img/money1.jpg").default}>{settings.symbol + ' ' + netBalance? parseFloat(netBalance):0.00}</DashboardCard>
                      :
                        <DashboardCard title={t('total_income')} image={require("../assets/img/money1.jpg").default}>{netBalance? parseFloat(netBalance):0.00 + ' ' + settings.symbol}</DashboardCard>
                      }
                    </Grid>
                    <Grid item xs={8}>
                      <Box boxShadow={6} m={1} p={2} className={classes.box}>
                        <Grid container style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}>
                          <Grid item xs={6}>

                            <TextField
                              type="number"
                              className={classes.rootRt}
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="withdraw_amount"
                              label={t('withdrow_amount')}
                              name="withdraw_amount"
                              autoComplete="withdraw_amount"
                              onChange={handleTextChange}
                              defaultValue={state.withdraw_amount}
                            />
                            <Button
                              type="submit"
                              width='45%'
                              variant="contained"
                              className={classes.submit2}
                              style={{marginLeft: 20}}
                              onClick = {() => withdraw()}
                            >
                              {t('withdraw')}
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Box boxShadow={6} m={2} p={2} className={classes.imageBox}>
                              {state.adminWithdrawImage ?
                                <div>
                                <img alt="not fount" src={URL.createObjectURL(state.adminWithdrawImage)} style={{ height: 120, marginLeft: 10, width: '85%', marginTop: 2 }} />
                                <br />
                                <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state, adminWithdrawImage:null })} >
                                  {t('remove')}
                                </Button>
                              </div>
                              :
                              <div style={{ width: '100%', height: 100, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <input
                                    type="file"
                                    name={t('image')}
                                    style={{ marginLeft: 10 }}
                                    onChange={(event) => {
                                      setState({ ...state, adminWithdrawImage: event.target.files[0] });
                                    }}
                                  />
                              </div>
                              }
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    {/* <Grid item xs={4}>
                      <Box boxShadow={6} m={2} p={2} className={classes.box}>
                        {state.adminWithdrawImage ?
                          <div>
                          <img alt="not fount" src={URL.createObjectURL(state.adminWithdrawImage)} style={{ height: 120, marginLeft: 20, width: '85%', marginTop: 10 }} />
                          <br />
                          <Button type="submit" fullWidth variant="contained" className={classes.remove} onClick={() => setState({ ...state, adminWithdrawImage:null })} >
                            {t('remove')}
                          </Button>
                        </div>
                        :
                        <div style={{ width: '100%', height: 100, borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <input
                              type="file"
                              name={t('image')}
                              style={{ marginLeft: 10 }}
                              onChange={(event) => {
                                setState({ ...state, adminWithdrawImage: event.target.files[0] });
                              }}
                            />
                        </div>
                        }
                      </Box>
                    </Grid> */}

                  </Grid>
                </Grid>
              </CardContent>
          </Box>

        </div>
        <div className={classes.heading}>
          <Typography component="h1" variant="h5" style={{ marginTop: '8px', fontWeight: 'bolder', color: colors.Rigzie1, textDecoration: 'underline' }}>
            {t('transaction_report')}
          </Typography>
        </div>
        <MaterialTable
          title={t('report_list')}
          style={{ border: '1px solid #808080' }}
          columns={columns}
          data={data}
          options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100],
            cellStyle: {
              textAlign: isRTL === 'rtl' ? 'right' : 'center',
              borderRight: '1px solid #808080',
              backgroundColor: '#f5f5f5'
            },
            headerStyle: {
              textAlign: isRTL === 'rtl' ? 'right' : 'center',
              border: '2px solid #808080',
            }
          }}
          localization={{
            toolbar: {
              searchPlaceholder: (t('search')),
              exportTitle: (t('export')),
            },
            header: {
              actions: (t('details'))
            },
            pagination: {
              labelDisplayedRows: ('{from}-{to} ' + (t('of')) + ' {count}'),
              labelRowsSelect: (t('rows')),
              firstTooltip: (t('first_page_tooltip')),
              previousTooltip: (t('previous_page_tooltip')),
              nextTooltip: (t('next_page_tooltip')),
              lastTooltip: (t('last_page_tooltip'))
            },
          }}
        />

        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>

      </div>
  );
}

export default AdminReport;
